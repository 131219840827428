import components from './components'
import components_styles from './components_styles'
import hide from './hide'
import options from './options'
import pages from './pages'
import { Dark, Light } from './pallete'
import routes from './routes'

/**
 * @type {ThemeController}
 */
const Theme = {
  DarkPallete: Dark,
  LightPallete: Light,
  options: options,
  components: components,
  pages: pages,
  components_styles: components_styles,
  hide: hide,
  routes: routes,
  typography: {
    h1: {
      fontSize: '3rem',
      fontWeight: '200',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: '300',
    },
  },
}

export default Theme
