import { Divider, Paper, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { Box } from '@mui/system'
import AccesosDirectos from 'common/components/AccesosDirectos'
import NewsList from 'features/noticias/components/NewsList'
import VisorPreguntas from 'features/preguntas/pages/VisorPreguntas'

const Home = () => {
  return (
    <Box sx={{ pb: 0 }}>
      <Box sx={{}}>
        <Paper
          className="degradado content-paper"
          sx={{ display: 'flex', mb: 2, flexDirection: 'column' }}
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box>
              <Typography variant="h3" className="lighter">
                ¡Bienvenido
              </Typography>
              <Typography variant="h5" className="lighter">
                al portal del Empleado!
              </Typography>
            </Box>
            <AccesosDirectos sx={{ mt: 0 }} clean />
          </Box>
        </Paper>
        <Grid container spacing={2}>
          <Grid item size={{ md: 7, xs: 12 }}>
            <Paper className="content-paper" sx={{ p: 2 }}>
              <Typography variant="h4" className="bold" sx={{ mb: 3 }}>
                Noticias
              </Typography>
              <NewsList
                showFilter={false}
                noPaginate={true}
                pageSize={2}
                rowCount={1}
                allBanner={true}
                showPaginateButton={true}
              />
            </Paper>
          </Grid>
          <Grid item size={{ md: 5, xs: 12 }}>
            <Paper className="content-paper" sx={{ backgroundColor: 'background.warning' }}>
              <Typography variant="h4" className="bold" sx={{ color: 'warning.main', mb: 3 }}>
                Preguntas Frecuentes
              </Typography>
              <VisorPreguntas />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Home
