import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import { Link } from 'react-router-dom'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import IconsList from 'common/constants/icons_map'
import List from '@mui/material/List'
import { useMemo } from 'react'

const DropDownButton = ({ current_menu, handleClick, Icono, open, small }) => {
  const isOpen = useMemo(() => {
    return current_menu?.id === open
    const a = open ? 'asd' : 'asld'
  }, [current_menu?.id, open])
  const backgroundColor = useMemo(() => {
    return isOpen ? 'menu.hoverBackground' : 'menu.background'
  }, [isOpen])
  return (
    <>
      <ListItemButton
        sx={{
          p: '4px',
          pl: 2,
          pr: 1,
          borderTopLeftRadius: '15px',
          borderBottomLeftRadius: '15px',
          backgroundColor: backgroundColor,
          '&:hover;&:focus': {
            backgroundColor: backgroundColor,
          },
        }}
        id={current_menu.id}
        onClick={handleClick}
        className="menu-list-item"
      >
        <ListItemIcon>
          {Icono ? <Icono sx={{ color: 'background.menuContrast', opacity: '0.8' }} /> : null}
        </ListItemIcon>
        <ListItemText primary={current_menu.nombre} />
        {open === current_menu.id ? <ExpandLess sx={{ ml: 4 }} /> : <ExpandMore sx={{ ml: 4 }} />}
      </ListItemButton>
      <Collapse id={current_menu.id} in={open === current_menu.id} timeout="auto" unmountOnExit>
        <List
          sx={{
            backgroundColor: 'menu.hoverBackground',
            width: '90%',
            marginLeft: 'auto',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
            pb: 1,
          }}
          component="div"
          disablePadding
        >
          {current_menu.hijos.map((sub) => {
            if (sub.cod_padre !== current_menu.codigo || sub.visible !== 'S') return null
            const IconoHijo = sub?.icono ? IconsList[sub.icono] : null
            if (sub.programa) {
              return (
                <ListItemButton
                  key={`${current_menu.id}-${sub.id}`}
                  sx={{ p: '4px', pl: 2, pr: 1 }}
                  id={sub.id}
                  component={Link}
                  to={`/${sub.camino ? sub.camino : sub.programa}`}
                >
                  <ListItemIcon>
                    {IconoHijo ? <IconoHijo sx={{ color: 'background.menuContrast' }} /> : null}
                  </ListItemIcon>
                  <ListItemText primary={sub.nombre} />
                </ListItemButton>
              )
            }
            return null
          })}
        </List>
      </Collapse>
    </>
  )
}

export default DropDownButton
