import styled from '@emotion/styled'
import { CssBaseline } from '@mui/material'
import { esES } from '@mui/material/locale'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AutomationController from 'automations/AutomationController'
import ProvidersNest from 'common/components/ProvidersNest'
import SnackbarCloseButton from 'common/components/SnackbarCloseButton'
import RoutesController from 'features/routing/components/RoutesController'
import { MaterialDesignContent, SnackbarProvider } from 'notistack'
import { useEffect, useState } from 'react'
import ThemeManager from 'theming/ThemeManager'

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent': {
    fontSize: '1em',
  },
}))

function App() {
  const themeController = new ThemeManager()

  if (themeController.activeTheme.options.fullHideMenu)
    localStorage.setItem('NO_EXPAND_MENU', 'true')

  const [themePallete, setThemePallete] = useState(
    createTheme(
      {
        palette: themeController.activePallete(),
        components: themeController?.activeTheme?.components_styles ?? {},
        typography: themeController?.typography,
        cssVariables: true,
      },
      esES,
    ),
  )
  useEffect(() => {
    fetch('/assets/resources/tema.json')
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        const storedTheme = localStorage.getItem('theme') || 'light'

        setThemePallete(
          createTheme(
            {
              palette:
                storedTheme === 'light'
                  ? data.pallete.Light ?? themeController.activePallete()
                  : data.pallete.Dark ?? themeController.activePallete(),
              components: themeController?.activeTheme?.components_styles ?? {},
              typography: themeController?.typography,
              cssVariables: true,
            },
            esES,
          ),
        )
      })
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={themePallete}>
        <CssBaseline enableColorScheme={true}>
          <ProvidersNest>
            <SnackbarProvider
              action={(key) => <SnackbarCloseButton snackbarKey={key} />}
              autoHideDuration={2000}
              Components={{
                default: StyledMaterialDesignContent,
                info: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
                success: StyledMaterialDesignContent,
                warning: StyledMaterialDesignContent,
              }}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              maxSnack={3}
              SnackbarProps={{}}
            >
              <AutomationController />
              <RoutesController />
            </SnackbarProvider>
          </ProvidersNest>
        </CssBaseline>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
