import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import InfoIcon from '@mui/icons-material/Info'
import { Button, Skeleton } from '@mui/material'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid2'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CustomUserCard from 'features/users/components/CustomUserCard'
import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useFetchDates from '../hooks/useFetchDates'
import '../styles.scss'
import Calendar from './Calendar'

import { selectDatosSolicitud, setDatosSolicitud } from 'features/solicitudes/solicitudesSlice'

const CalendarioAnual = ({
  currentYear = new Date().getFullYear(),
  setCurrentYear = null,
  useUser = [null, () => {}],
  vacacionesPendientes = null,
  vacacionesTipoDias = null,
  allowSelection = false,
  fechasSolicitud = [],
}) => {
  const dispatch = useDispatch()

  const [user, setUser] = useUser
  const { response, loading, error } = useFetchDates({
    year: currentYear,
    user: user,
  })
  const [fechas, setFechas] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [requestError, setRequestError] = useState(null)

  const [pendientes, setPendientes] = useState(vacacionesPendientes)
  const [tipoDias, setTipoDias] = useState(vacacionesTipoDias)

  const [mostrarLeyenda, setMostrarLeyenda] = useState(false)

  const handleMostrarLeyenda = () => {
    setMostrarLeyenda(!mostrarLeyenda)
  }

  const datos = useSelector(selectDatosSolicitud)

  useEffect(() => {
    console.log({ response })

    for (const fecha of fechasSolicitud) {
      const formattedDate = fecha.split('T')[0]

      const date = new Date(formattedDate)
      response.unshift({
        color: '#fad101',
        name: 'EN SOLICITUD',
        situacion: 'S',
        tipo: 'S',
        startDate: date,
        endDate: date,
        rendering: 'background',
      })
    }

    setFechas(response)
  }, [response])

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  useEffect(() => {
    if (error)
      return setRequestError({
        type: 'error',
        text:
          error?.response?.status === 500 ? `Ha ocurrido un error en el servidor` : error.message,
      })
    setRequestError(null)
  }, [error])

  useEffect(() => {
    if (datos) {
      let _datos = { ...datos }
      for (const idpk in _datos) {
        if (Object.hasOwnProperty.call(_datos, idpk)) {
          _datos[idpk] = null
        }
      }
      for (const fecha of fechas) {
        if (fecha.tipo === 'seleccion') {
          for (const idpk in _datos) {
            if (Object.hasOwnProperty.call(_datos, idpk)) {
              if (!_datos[idpk]) {
                _datos[idpk] = fecha.startDate
                break
              }
            }
          }
        }
      }
      dispatch(setDatosSolicitud(_datos))
    }
  }, [fechas])

  const ahora = new Date()
  const minDate = new Date(ahora.getFullYear() - 1, 0, 1)
  const maxDate = new Date(ahora.getFullYear() + 1, 11, 31)

  const selectedRange = (rango) => {
    const _fechas = rango.calendar.getDataSource()
    const ahora = new Date()

    console.log({ pendientes, tipoDias })

    if (rango.startDate.getTime() <= rango.endDate.getTime()) {
      let nuevas_fechas = []

      for (
        let d = new Date(rango.startDate.getTime());
        d.getTime() <= rango.endDate.getTime();
        d.setDate(d.getDate() + 1)
      ) {
        if (d.getTime() < ahora.getTime()) {
          enqueueSnackbar(`No se pueden seleccionar fechas anteriores al día de hoy`)
          return
        }
        const _fecha = new Date(d.getTime())
        _fecha.setHours(1)

        // Si ya estaba seleccionada la fecha la quita
        const index = _fechas.findIndex(
          (f) => f.startDate.getTime() === _fecha.getTime() && f.tipo === 'seleccion',
        )
        if (index > -1) {
          _fechas.splice(index, 1)
          continue
        }

        // Comprueba el tipo de día que va a seleccionar
        const diaSeleccionado = _fechas.filter(
          (f) =>
            f.startDate.getFullYear() === _fecha.getFullYear() &&
            f.startDate.getMonth() === _fecha.getMonth() &&
            f.startDate.getDate() === _fecha.getDate() &&
            f.tipo !== 'seleccion',
        )[0]
        const tipoDiaSeleccionado = diaSeleccionado ? diaSeleccionado.tipo : 'L'
        if (tipoDias === 'X' && tipoDiaSeleccionado === 'F') continue
        if (tipoDias === 'L' && tipoDiaSeleccionado !== 'L') continue

        // No permite seleccionar más días de los que le quedan pendientes
        const seleccionados =
          _fechas.filter((f) => f.tipo === 'seleccion').length + nuevas_fechas.length
        if (pendientes - seleccionados <= 0) {
          enqueueSnackbar(`No le quedan más días disponibles`)
          break
        }

        nuevas_fechas.push({
          name: 'Selección',
          startDate: _fecha,
          endDate: _fecha,
          color: 'red',
          tipo: 'seleccion',
          seleccion: 'seleccion',
          rendering: 'background',
        })
      }
      const seleccion = [..._fechas, ...nuevas_fechas]
      setFechas(seleccion)
      rango.calendar.setDataSource(seleccion)
    }
  }

  const changeYear = (year) => {
    // setCurrentYear(year.currentYear)
  }

  const addTooltip = (element, events) => {
    let tooltip = ''
    if (events.length > 0) {
      events.forEach((event) => {
        tooltip += `${event.name}\n`
      })
      element.dataset.tooltip = tooltip
      element.setAttribute('tabindex', '0')
    }
  }

  const fechas_ds = fechas

  const renderDay = (elemento, fecha, evento) => {
    if (evento[0].rendering === 'background') {
      elemento.style.backgroundColor = evento[0].color
    }
    if (evento[0].rendering === 'border') {
      elemento.style.boxShadow = `${evento[0].color} 0px -4px 0px 0px inset`
    }
    if (evento[0].rendering === 'number') {
      elemento.style.color = evento[0].color
      elemento.style.fontWeight = 'bolder'
    }
    elemento.style.borderRadius = 0
  }

  return (
    <>
      <SnackbarProvider
        maxSnack={5}
        autoHideDuration={6000}
        action={(snackbarId) => (
          <IconButton onClick={() => closeSnackbar(snackbarId)} aria-label="cerrar alerta">
            <CloseRoundedIcon />
          </IconButton>
        )}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        variant={'error'}
      />
      {user?.id ? (
        <CustomUserCard
          returnToUser={() => {
            setUser(null)
          }}
          loading={isLoading}
          user={user}
          message={requestError ?? null}
        />
      ) : (
        <></>
      )}
      {isLoading & !user ? (
        <Skeleton height={400} />
      ) : (
        <>
          <Box sx={{ flexGrow: 1, justifyContent: 'flex-end' }}>
            <Button
              variant="text"
              size="small"
              onClick={handleMostrarLeyenda}
              startIcon={<InfoIcon />}
            >
              Leyenda
            </Button>
          </Box>
          <Collapse in={mostrarLeyenda} timeout="auto" unmountOnExit>
            <Box sx={{ flexGrow: 1, mt: 4 }}>
              <Grid container spacing={2}>
                <Grid xs={4} lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{
                      padding: '2px 10px',
                      boxShadow: '#ff7800 0px -4px 0px 0px inset',
                    }}
                  >
                    No Laboral
                  </Typography>
                </Grid>
                <Grid xs={4} lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{ padding: '2px 10px', backgroundColor: '#ff7800' }}
                  >
                    Festivo
                  </Typography>
                </Grid>
                <Grid xs={4} lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{ padding: '2px 10px', backgroundColor: '#00a1ad' }}
                  >
                    Vacaciones disfrutadas
                  </Typography>
                </Grid>
                <Grid xs={4} lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{
                      padding: '2px 10px',
                      boxShadow: '#fad101 0px -4px 0px 0px inset',
                    }}
                  >
                    Vacaciones solicitadas
                  </Typography>
                </Grid>
                <Grid xs={4} lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{
                      padding: '2px 10px',
                      boxShadow: '#cf0909 0px -4px 0px 0px inset',
                    }}
                  >
                    Permisos
                  </Typography>
                </Grid>
                {fechasSolicitud && fechasSolicitud.length > 0 ? (
                  <Grid xs={4} lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      variant="caption"
                      gutterBottom
                      sx={{ padding: '2px 10px', backgroundColor: '#fad101' }}
                    >
                      EN SOLICITUD
                    </Typography>
                  </Grid>
                ) : (
                  <Grid xs={4} lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      variant="caption"
                      gutterBottom
                      sx={{
                        padding: '2px 10px',
                        boxShadow: '#acc433 0px -4px 0px 0px inset',
                      }}
                    >
                      ITs
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Collapse>
          <Calendar
            language="es"
            enableRangeSelection={allowSelection}
            onDayEnter={(event) => {
              addTooltip(event.element, event.events)
            }}
            roundRangeLimits={false}
            enableContextMenu={true}
            minDate={minDate}
            maxDate={maxDate}
            onRangeSelected={selectedRange}
            onYearChanged={changeYear}
            style="custom"
            dataSource={fechas_ds}
            customDataSourceRenderer={(el, d, ev) => {
              renderDay(el, d, ev)
            }}
          />
        </>
      )}
    </>
  )
}

export default CalendarioAnual
