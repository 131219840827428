import MuiAlert from '@mui/material/Alert'
import Grid from '@mui/material/Grid2'
import Snackbar from '@mui/material/Snackbar'
import { Box } from '@mui/system'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { esES } from '@mui/x-date-pickers/locales'
import 'dayjs/locale/es'
import {
  consultaInicial,
  executeFieldAction,
  selectCamposSolicitud,
  selectDatosSolicitud,
  setDatoSolicitud,
} from 'features/solicitudes/solicitudesSlice'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getValoresConsulta } from '../../libs/SolicitudesHelpers'
import LabelComponent from '../LabelComponent'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

function DateRangeComponent({ idpk }) {
  const dispatch = useDispatch()
  const campos = useSelector(selectCamposSolicitud)

  const datos = useSelector(selectDatosSolicitud)

  const [open, setOpen] = React.useState(false)
  const [desde, setDesde] = React.useState({})
  const [hasta, setHasta] = React.useState({})
  const [encontradoDesde, setEncontradoDesde] = React.useState(false)
  const [valorDesde, setValorDesde] = React.useState()
  const [valorHasta, setValorHasta] = React.useState()

  if (!encontradoDesde) {
    let encontrado = false
    for (const c of campos) {
      if (c.idpk === idpk) {
        setDesde(c)
        setEncontradoDesde(true)
        encontrado = true
      }
      if (encontrado && c.tipo_dato === 'DH2') {
        setHasta(c)
        break
      }
    }
  }

  React.useEffect(() => {
    setValorDesde(datos[desde.idpk])
    setValorHasta(datos[hasta.idpk])
  }, [datos, desde, hasta])

  React.useEffect(() => {
    // Lanzamos las queries iniciales que contienen a este campo
    campos.forEach((c) => {
      if (c.depende_de && c.depende_de.includes(desde.codigo_campo) && valorDesde) {
        const valores = getValoresConsulta(datos, campos)
        dispatch(consultaInicial({ idpk: c.idpk, valores }))
      }
    })
    desde.acciones.forEach((action) => {
      if (action.valor_referencia === valorDesde) {
        dispatch(executeFieldAction({ action }))
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valorDesde])

  React.useEffect(() => {
    // Lanzamos las queries iniciales que contienen a este campo
    campos.forEach((c) => {
      if (c.depende_de && c.depende_de.includes(hasta.codigo_campo) && valorHasta) {
        const valores = getValoresConsulta(datos, campos)
        dispatch(consultaInicial({ idpk: c.idpk, valores }))
      }
    })
    hasta.acciones.forEach((action) => {
      if (action.valor_referencia === valorHasta) {
        dispatch(executeFieldAction({ action }))
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valorHasta])

  const handleOpen = (valor) => {
    if (!valor) {
      setOpen(true)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const handleChangeDesde = (nuevo_valor) => {
    setValorDesde(nuevo_valor)
    dispatch(setDatoSolicitud({ idpk: desde.idpk, valor: nuevo_valor }))
  }
  const handleChangeHasta = (nuevo_valor) => {
    setValorHasta(nuevo_valor)
    dispatch(setDatoSolicitud({ idpk: hasta.idpk, valor: nuevo_valor }))
    if (valorDesde) {
    } else {
      setOpen(true)
    }
  }

  if (desde && hasta) {
    return (
      <LocalizationProvider
        localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
        dateAdapter={AdapterDayjs}
        adapterLocale="es"
      >
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            No puede seleccionar este campo hasta haber cubierto el anterior
          </Alert>
        </Snackbar>
        <Grid container spacing={2} sx={{ p: 0 }}>
          <Grid key={`g-${desde.idpk}`} size={{ xs: 12, sm: 6, md: 6 }}>
            <Box>
              <LabelComponent text={`${desde.etiqueta}${desde.obligatorio === 'S' ? ' *' : ''}`} />
              <DatePicker
                key={`desde-${desde.codigo_campo}`}
                placeholder={`${desde.etiqueta}${desde.obligatorio === 'S' ? ' *' : ''}`}
                value={valorDesde}
                onChange={(v) => handleChangeDesde(v)}
                slotProps={{
                  textField: {
                    helperText: desde.ayuda,
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid key={`g-${hasta.idpk}`} size={{ xs: 12, sm: 6, md: 6 }}>
            <Box>
              <LabelComponent text={`${hasta.etiqueta}${hasta.obligatorio === 'S' ? ' *' : ''}`} />
              <DatePicker
                key={`hasta-${hasta.codigo_campo}`}
                placeholder={`${hasta.etiqueta}${hasta.obligatorio === 'S' ? ' *' : ''}`}
                value={valorHasta}
                onChange={(v) => handleChangeHasta(v)}
                onOpen={() => handleOpen(valorDesde)}
                slotProps={{
                  textField: {
                    helperText: hasta.ayuda,
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </LocalizationProvider>
    )
  }
}

export default DateRangeComponent
