import { Box, Step, Stepper } from '@mui/material'
import StepLabel from '@mui/material/StepLabel'
import { styled } from '@mui/material/styles'
import { selectCamposSolicitud } from 'features/solicitudes/solicitudesSlice'
import { useSelector } from 'react-redux'

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepIcon-root': {
    color: theme.palette.grey[300],
    '&.Mui-active': {
      color: theme.palette.success.main,
    },
    '&.Mui-completed': {
      color: theme.palette.success.main,
    },
  },
  '& .MuiStepLabel-labelContainer': {
    '@media (min-width: 960px)': {
      textAlign: 'center',
    },
    '@media (max-width: 960px)': {
      display: 'none',
    },
  },
}))

const CustomStepper = ({ activeStep, estructura, sx }) => {
  const campos = useSelector(selectCamposSolicitud)

  return (
    <Box sx={{ ...sx }}>
      <Stepper activeStep={activeStep} orientation="horizontal" alternativeLabel>
        {estructura.map((field) => {
          const campo = campos.filter((c) => c.idpk === field.idpk)[0]

          return campo && estructura.length < 15 ? (
            <Step key={`step-${campo.idpk}`}>
              <CustomStepLabel>{campo.etiqueta}</CustomStepLabel>
            </Step>
          ) : (
            <Step key={Math.random()}>
              <CustomStepLabel> </CustomStepLabel>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}

export default CustomStepper
