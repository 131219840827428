import { Box, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid2'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'
import useFetchProfessionalData from '../hooks/useFetchProfesionalData'

const Profesionales = () => {
  const location = useLocation()
  const { response } = useFetchProfessionalData(location?.state?.user ?? null)
  const [datosProfesionales, setDatosProfesionales] = useState(response)
  const Theme = new ThemeManager()

  useEffect(() => {
    setDatosProfesionales(response)
  }, [response])

  return (
    <>
      <Box sx={{ my: 3, display: 'flex', gap: 2 }}>
        <Typography variant="h3">Profesionales</Typography>
      </Box>
      <Stack>
        <Grid className="outline-paper" container spacing={2}>
          <Grid item size={{ xs: 12 }}>
            <Card sx={{ flexGrow: 1 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item size={{ xs: 12, md: 6 }}>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      <ListItem>
                        <ListItemText
                          primary="Fecha alta"
                          secondary={`${datosProfesionales?.fecha_alta ?? ''}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Fecha antigüedad"
                          secondary={`${datosProfesionales?.fecha_antiguedad ?? ''}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Situación"
                          secondary={`${datosProfesionales?.situacion ?? ''}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Centro"
                          secondary={`${datosProfesionales?.d_centro_trabajo ?? ''}`}
                        />
                      </ListItem>
                      {Theme.hide?.WorkerData?.Professional?.Service ? (
                        <></>
                      ) : (
                        <ListItem>
                          <ListItemText
                            primary="Servicio"
                            secondary={`${datosProfesionales?.servicio ?? ''}`}
                          />
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                  <Grid item size={{ xs: 12, md: 6 }}>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      <ListItem>
                        <ListItemText
                          primary="Convenio"
                          secondary={`${datosProfesionales?.d_convenio ?? ''}`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Categoría"
                          secondary={`${datosProfesionales?.d_categoria ?? ''}`}
                        />
                      </ListItem>
                      {Theme.hide?.WorkerData?.Professional.Contract ? (
                        <></>
                      ) : (
                        <ListItem>
                          <ListItemText
                            primary="Contrato"
                            secondary={`${datosProfesionales?.d_contrato ?? ''}`}
                          />
                        </ListItem>
                      )}
                      {Theme.hide?.WorkerData?.Professional?.Hours ? (
                        <></>
                      ) : (
                        <ListItem>
                          <ListItemText
                            primary="Horas"
                            secondary={`${datosProfesionales?.horas_contrato ?? ''}`}
                          />
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                  <Grid item size={{ xs: 12, md: 6 }}>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      {Theme.hide?.WorkerData?.Professional?.PortionHours ? (
                        <></>
                      ) : (
                        <ListItem>
                          <ListItemText
                            primary="Porción horas"
                            secondary={`${datosProfesionales?.porc_horas ?? ''}`}
                          />
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                  <Grid item size={{ xs: 12, md: 6 }}>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      {Theme.hide?.WorkerData?.Professional?.NetAmmount ? (
                        <></>
                      ) : (
                        <ListItem>
                          <ListItemText
                            primary="Bruto pactado"
                            secondary={`${datosProfesionales?.bruto_pactado ?? ''}`}
                          />
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </>
  )
}

export default Profesionales
