import { Download } from '@mui/icons-material'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import axios from 'api/axios'
import { getLocaleFromFullDate } from 'common/libs/fechas'
import Calendario from 'features/calendario/pages/Calendario'
import { HojasGastoLinPage } from 'features/hojasgasto/pages/HojasGastoLinPage'
import { selectUsuario } from 'features/users/stores/userSlice'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getChipSolicitud } from '../libs/SolicitudesHelpers'
import { selectSolicitudSeleccionada, setSolicitudSeleccionada } from '../solicitudesSlice'
import CabeceraSolicitud from './CabeceraSolicitud'

const DatosSolicitud = ({ solicitud, campos }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const seleccionada = useSelector(selectSolicitudSeleccionada)
  const user = useSelector(selectUsuario)

  const [chat, setChat] = useState([])

  const [mensaje, setMensaje] = useState('')
  const [enviar, setEnviar] = useState(false)

  let fechasSolicitud
  if (solicitud.id_solicitud_pe === 'VAC01') {
    fechasSolicitud = solicitud.datos
      .map((d) => {
        if (d.valor_date) return d.valor_date
      })
      .filter((f) => f)
  }

  useEffect(() => {
    if (!solicitud) {
      if (location?.state?.solicitud) {
        dispatch(setSolicitudSeleccionada(location.state.solicitud))
        return
      }
      navigate('/listasolicitudes/')
    }
  }, [dispatch, navigate, location, solicitud, seleccionada])

  useEffect(() => {
    if (enviar) {
      const fecha = new Date().toISOString()
      const datos = {
        mensaje,
        fecha,
      }
      axios
        .post(`solicitud/chat/${solicitud.codigo_solicitud}/${solicitud.origen_solicitud}/`, datos)
        .then((request_response) => {
          const data = request_response.data
          setChat([...chat, data])
          setEnviar(false)
        })
        .catch((error) => setEnviar(false))
        .finally(() => setEnviar(false))
    }
  }, [enviar, solicitud, mensaje, chat])

  const handleDownload = (e) => {
    var link = document.createElement('a')
    link.download = e.currentTarget.dataset.nombre
    link.href = e.currentTarget.dataset.url
    link.click()
  }

  return solicitud ? (
    <Grid container spacing={2}>
      <Grid key="cabecera" size={{ xs: 12 }}>
        <CabeceraSolicitud descripcion={solicitud.d_tipo} solicitud={solicitud} />
      </Grid>
      <Grid key="datos" size={{ xs: 12, sm: 12, md: 8 }}>
        <Card>
          <CardHeader title="Datos" />
          <CardContent>
            {solicitud.id_solicitud_pe === 'VAC01' && fechasSolicitud ? (
              <Calendario
                requestUser={
                  solicitud.usuario_creacion
                    ? {
                      usuario_web: solicitud.usuario_creacion,
                    }
                    : null
                }
                vistaAmpliada={false}
                fechasSolicitud={fechasSolicitud}
              />
            ) : null}
            {solicitud.id_solicitud_pe === 'VALHG' ? (
              <HojasGastoLinPage
                id_h={parseInt(
                  solicitud.datos.filter(
                    (campo) => campo.codigo_campo === solicitud.cod_campo_id_hoja,
                  )[0].valor_number,
                )}
                encr={solicitud.encr}
                disableAll={true}
              />
            ) : null}
            <List>
              {campos.map((c) => {
                if (c.visible !== 'S') return null
                const dato = solicitud.datos.filter(
                  (d) =>
                    d.codigo_plantilla === c.codigo_plantilla && d.codigo_campo === c.codigo_campo,
                )[0]
                if (!dato) return null
                let valor = null
                let secondary = null
                switch (c.tipo_dato) {
                case 'V':
                  valor = dato.valor_alfa
                  break
                case 'N':
                  valor = Number(dato.valor_number).toFixed(c.required_decimals) ?? ''
                  break
                case 'D':
                case 'DH1':
                case 'DH2':
                  valor = dato.valor_date ? getLocaleFromFullDate(dato.valor_date) : ''
                  break
                case 'S':
                  return (
                    <Divider textAlign="left" sx={{ marginTop: 2, marginBottom: 1 }}>
                      <Chip label={c.etiqueta} />
                    </Divider>
                  )
                case 'B':
                  secondary = (
                    <IconButton edge="end" aria-label="comments">
                      <Download />
                    </IconButton>
                  )
                default:
                  break
                }
                if (c.tipo_dato === 'B') {
                  return (
                    <>
                      <ListItem key={c.id} primaryAction={secondary} disablePadding>
                        <ListItemButton
                          sx={{ height: 56 }}
                          data-url={dato.valor_file}
                          data-nombre={dato.d_valor ?? valor}
                          onClick={handleDownload}
                        >
                          <ListItemIcon>
                            <Download color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={dato.d_valor ?? valor}
                            secondary={c.etiqueta}
                            sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider component="li" />
                    </>
                  )
                }
                return (
                  <>
                    <ListItem key={c.id}>
                      <ListItemText
                        primary={dato.d_valor ?? valor}
                        secondary={c.etiqueta}
                        sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                      />
                    </ListItem>
                    <Divider component="li" />
                  </>
                )
              })}
            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid key="stausychat" size={{ xs: 12, sm: 12, md: 4 }}>
        <Stack spacing={2}>
          <Card>
            <CardHeader title="Histórico" />
            <CardContent>
              <List>
                {solicitud.estados?.map((e) => {
                  const status_fecha = getLocaleFromFullDate(e.fecha_hora)
                  return (
                    <>
                      <ListItem key={e.id} secondaryAction={''}>
                        <ListItemAvatar>
                          <Avatar
                            alt={e.d_usuario?.split(', ')[1]}
                            src={`/assets/images/${e.img_usuario}`}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={e.d_usuario}
                          secondary={
                            <>
                              {getChipSolicitud(Number(e.status), e.d_status)}
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {status_fecha}
                              </Typography>
                              {e.status_texto ? ` - ${e.status_texto}` : ''}
                            </>
                          }
                        />
                      </ListItem>
                    </>
                  )
                })}
              </List>
            </CardContent>
          </Card>
          {/*
          <Card>
            <CardHeader title="Chat" />
            <CardContent>
              <List>
                {chat?.length === 0 ? (
                  <ListItem key="no_hay">
                    <ListItemText primary="No hay mensajes" />
                  </ListItem>
                ) : (
                  chat.map((c) => {
                    return (
                      <ListItem key={c.id}>
                        <ListItemAvatar>
                          <Avatar
                            alt={c.d_usuario_web?.split(', ')[1]}
                            src={`/assets/images/${c.codigo_empresa}_${c.codigo_portal}_${c.usuario_web}.jpg`}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={getLocaleFromFullDate(c.fecha)}
                          secondary={
                            <>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {c.d_usuario_web}
                              </Typography>
                              {` - ${c.mensaje}`}
                            </>
                          }
                        />
                      </ListItem>
                    )
                  })
                )}
              </List>
            </CardContent>
            <CardActions disableSpacing>
              <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                <TextField
                  id="chat_textfield"
                  label="Mensaje"
                  multiline
                  maxRows={4}
                  sx={{ flexGrow: 1 }}
                  onChange={(e) => setMensaje(e.target.value)}
                />
                <IconButton
                  aria-label="enviar"
                  sx={{ flexGrow: 0 }}
                  onClick={() => setEnviar(true)}
                >
                  <SendIcon />
                </IconButton>
              </Stack>
            </CardActions>
          </Card>
          */}
        </Stack>
      </Grid>
    </Grid>
  ) : (
    'Cargando'
  )
}

export default DatosSolicitud
