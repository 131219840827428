import axios from 'api/axios'
const { useState, useEffect } = require('react')

/**
 * @module FetchHooks
 */

/**
 * Fetch The Personal Document Tree
 * @param {Object} props
 * @param {UserToHandle} [props.user=null]
 * @return {import('common/types/connections_types').UseFetchHookResponse<PersonalTreeDTO[]>}
 */
const useFetchArbol = ({ user = null }) => {
  const [response, setResponse] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const fetchData = () => {
    if (loading) {
      return
    }

    setLoading(true)
    axios
      .get(`/docpersonal/tree/?user=${user?.usuario_web ?? ''}`)
      .then((request_response) => {
        const data = request_response.data
        setResponse(data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchData()
  }, [user])

  return { response, error, loading }
}

export default useFetchArbol

/** @typedef {object} PersonalTreeDTO
 * @property {string} codigo_tabla
 * @property {string} codigo_nivel
 * @property {string} descripcion
 * @property {string} codigo_padre
 * @property {string} ultimo_nivel
 * @property {string} nodeId
 * @property {number} num_doc
 * @property {PersonalTreeDTO[]} hijos
 */
