import { Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { Box, Container } from '@mui/system'
import { getLocaleFromFullDate } from 'common/libs/fechas'
import { selectUsuario } from 'features/users/stores/userSlice'
import { useSelector } from 'react-redux'
import ThemeManager from 'theming/ThemeManager'
import { getChipSolicitud } from '../libs/SolicitudesHelpers'
import AccionesSolicitud from './AccionesSolicitud'

const CabeceraSolicitud = (/**@type {CabeceraSolicitud} */ { solicitud }) => {
  const user = useSelector(selectUsuario)
  const theme = new ThemeManager()

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', with: '100%', gap: 1, flexWrap: 'wrap' }}>
        <Box
          sx={{
            display:
              user?.usuario_web === solicitud?.usuario_creacion || !solicitud?.usuario_creacion
                ? 'none'
                : 'flex',
            flex: '1 1 0',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              sx={{
                width: '100px',
                aspectRatio: '1',
                height: '100%',
                mr: 2,
                fontSize: '3rem',
                flexGrow: 1,
              }}
              alt={solicitud.d_dni?.split(', ')[1]}
              src={`/assets/images/${solicitud.img_usuario_creacion}`}
            />
            <Box>
              <Typography variant="h6">Solicitante</Typography>
              <Typography variant="h4">{solicitud.d_usuario_creacion}</Typography>
              <Typography variant="subtitle1">
                {getLocaleFromFullDate(solicitud.fecha_creacion)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', flex: '1 1 0' }}>
          {solicitud.status
            ? getChipSolicitud(
                solicitud.status,
                solicitud.d_status,
                {
                  fontSize: '1.5rem',
                  padding: '16px 40px',
                  height: 'auto',
                  borderRadius: '100px',
                  width: '100%',
                },
                { width: '100%' },
              )
            : ''}
        </Box>
        <AccionesSolicitud
          codigo_solicitud={solicitud.codigo_solicitud}
          origen_solicitud={solicitud.origen_solicitud}
          acciones={solicitud.acciones?.split(',')}
          sx={{
            display: solicitud.acciones?.length > 0 ? 'flex' : 'none',
            gap: 1,
            flexDirection: 'column',
            boxSizing: 'border-box',
            justifyContent: 'center',
            flexGrow: 1,
            flex: '1 1 0',
            '& button': {
              boxShadow: 'none',
              borderRadius: '100px',
            },
          }}
        />
      </Box>

      {solicitud.ayuda ? (
        <Container maxWidth="xl">
          <Typography sx={{ mb: 2, mt: 3 }} key="sol_ayu" variant="subtitle1" gutterBottom>
            <div dangerouslySetInnerHTML={{ __html: solicitud.ayuda }}></div>
          </Typography>
        </Container>
      ) : (
        ''
      )}
    </Box>
  )
}

export default CabeceraSolicitud
