import components from './components'
import components_styles from './components_styles'
import hide from './hide'
import icons from './icons'
import options from './options'
import pages from './pages'
import { Dark, Light } from './pallete'
import routes from './routes'
import typography from './typography'

/**
 * @type {ThemeController}
 */
const Theme = {
  DarkPallete: Dark,
  LightPallete: Light,
  options: options,
  components: components,
  pages: pages,
  components_styles: components_styles,
  hide: hide,
  routes: routes,
  typography: typography,
  icons: icons,
}

export default Theme
