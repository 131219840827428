import LoginIcon from '@mui/icons-material/Login'
import { Card, CardContent, Skeleton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import SimpleTable from 'common/components/SimpleTable'
import { getLocaleFromFullDate } from 'common/libs/fechas'
import { colorSalidaMap, tiposSalidaMap } from 'features/control_presencia/libs/tiposSalidaMap'

/**
 *
 * @module ControlPresencia
 *
 */

/**
 * @callback setFichajeActiveRecord
 * @param {FichajesDTO} item
 */

/**
 *
 * Listado de ultimos fichajes
 *
 * @param {object} props
 * @param {Array<FichajesDTO>} props.response - Listado de fichajes
 */
const LastFichajesTable = ({ response, sx, loading }) => {
  return (
    <Box
      sx={{
        borderRadius: '5px',
        width: '100%',
        ...sx,
      }}
    >
      <Typography variant="h6" sx={{ my: 2 }} textAlign="left">
        Últimos Fichajes
      </Typography>
      {loading ? (
        <Card>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Skeleton variant="rounded" height={60} sx={{ width: '100%', borderRadius: 2 }} />
            <Skeleton variant="rounded" height={60} sx={{ width: '100%', borderRadius: 2 }} />
            <Skeleton variant="rounded" height={60} sx={{ width: '100%', borderRadius: 2 }} />
          </CardContent>
        </Card>
      ) : (
        <SimpleTable
          loading={loading}
          header_cell_style={{ padding: '5px' }}
          table_style={{ width: '100%', borderCollapse: 'collapse', display: 'table' }}
          generic_cell_style={{
            textAlign: 'left',
            padding: '15px 10px',
            fontWeight: '300',
            fontSize: '0.9rem',
          }}
          hideHeader={true}
          dataSource={response}
          fields={[
            {
              field: 'entrada_salida',
              title: 'Acción',
              customGetter: (
                /** @type {FichajesDTO} */
                item,
              ) => {
                if (item.entrada_salida === 'S') {
                  return <LoginIcon sx={{ fontSize: '2rem' }} color="success" />
                } else {
                  const Icon = tiposSalidaMap[item?.tipo_salida ?? 'DEFAULT']
                  const color = colorSalidaMap[item?.tipo_salida ?? 'DEFAULT']
                  return <Icon style={{ color: color, fontSize: '2rem' }} />
                }
              },
            },
            {
              field: 'fecha_terminal',
              title: 'Momento',
              customGetter: (
                /**@type {FichajesDTO}*/
                item,
              ) => {
                return getLocaleFromFullDate(item.fecha_terminal, true)
              },
            },
          ]}
        />
      )}
    </Box>
  )
}

export default LastFichajesTable
