import { GridView, TableRows } from '@mui/icons-material'
import { Box, Button, ButtonGroup } from '@mui/material'
import CustomGrid from 'common/components/CustomGrid'
import { useEffect, useState } from 'react'
import useFetchHojasGastoLin from '../hooks/useFetchHojasGastoLin'
import { HojasGastoLinAddModal } from './HojasGastoLinAddModal'
import HojasGastoLinCard from './HojasGastoLinCard'
import { HojasGastoLinTable } from './HojasGastoLinTable'
export const HojasGastoLin = ({
  disableAll,
  id,
  fechaInicio,
  fechaFin,
  handleChangeState,
  encr,
  showButton,
}) => {
  const [lineas, setLineas] = useState([])
  const { response, error, loading } = useFetchHojasGastoLin({ id_hoja: id, encr })
  const [viewType, setViewType] = useState('TABLE')

  useEffect(() => {
    if (response) setLineas(response.results)
  }, [response])
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {!disableAll ? (
          <HojasGastoLinAddModal
            id_hoja={id}
            showButton={showButton}
            updateState={(linea) => {
              setLineas([...lineas, linea])
            }}
            fechaInicio={fechaInicio}
            fechaFin={fechaFin}
          />
        ) : null}
        <ButtonGroup sx={{ display: { xs: 'none', lg: 'flex' } }}>
          <Button
            variant={viewType === 'TABLE' ? 'contained' : 'text'}
            onClick={() => {
              setViewType('TABLE')
            }}
          >
            <TableRows />
          </Button>
          <Button
            variant={viewType === 'TABLE' ? 'text' : 'contained'}
            onClick={() => {
              setViewType('GRID')
            }}
          >
            <GridView />
          </Button>
        </ButtonGroup>
      </Box>
      <Box
        sx={{
          display: { xs: 'flex', lg: 'none' },
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          alignContent: 'center',
          width: '100%',
          placeItems: 'center',
        }}
      >
        <Box sx={{ mt: 2, width: '100%' }}>
          <CustomGrid
            loading={loading}
            dataSource={lineas ?? []}
            count={lineas?.count}
            gridProps={{ spacing: 2 }}
            gridItemsProps={{ size: { xs: 12, md: 6, sm: 6 } }}
            CardLayout={({ data }) => (
              <>
                <HojasGastoLinCard
                  data={data}
                  lineas={lineas}
                  setLineas={setLineas}
                  loading={loading}
                  id_hoja={id}
                  disableAll={disableAll}
                  fechaInicio={fechaInicio}
                  fechaFin={fechaFin}
                  showButton={showButton}
                  handleChangeState={handleChangeState}
                  encr={encr}
                />
              </>
            )}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: 'none', lg: 'flex' },
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          width: '100%',
        }}
      >
        {viewType === 'TABLE' ? (
          <HojasGastoLinTable
            lineas={lineas}
            setLineas={setLineas}
            loading={loading}
            id_hoja={id}
            disableAll={disableAll}
            fechaInicio={fechaInicio}
            fechaFin={fechaFin}
            showButton={showButton}
            handleChangeState={handleChangeState}
            encr={encr}
          />
        ) : (
          <>
            <Box sx={{ mt: 2, width: '100%' }}>
              <CustomGrid
                loading={loading}
                dataSource={lineas ?? []}
                count={lineas?.count}
                gridProps={{ spacing: 2 }}
                gridItemsProps={{ size: { xs: 12, md: 6, sm: 6 } }}
                CardLayout={({ data }) => (
                  <>
                    <HojasGastoLinCard
                      data={data}
                      lineas={lineas}
                      setLineas={setLineas}
                      loading={loading}
                      id_hoja={id}
                      disableAll={disableAll}
                      fechaInicio={fechaInicio}
                      fechaFin={fechaFin}
                      showButton={showButton}
                      handleChangeState={handleChangeState}
                      encr={encr}
                    />
                  </>
                )}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  )
}
