import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import { Link } from 'react-router-dom'
import ExpandLess from '@mui/icons-material/ArrowBackIos'
import ExpandMore from '@mui/icons-material/ArrowForwardIos'
import IconsList from 'common/constants/icons_map'
import List from '@mui/material/List'
import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'

const DropRightButton = ({ current_menu, handleClick, Icono, open, small, drawerWidth }) => {
  const isOpen = useMemo(() => {
    return current_menu?.id === open
    const a = open ? 'asd' : 'asld'
  }, [current_menu?.id, open])
  const backgroundColor = useMemo(() => {
    return isOpen ? 'menu.hoverBackground' : 'menu.background'
  }, [isOpen])
  return (
    <Box sx={{ position: 'relative', ml: 1 }}>
      {isOpen ? (
        <div
          onClick={handleClick}
          style={{
            background: 'transparent',
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: '-30',
          }}
          className="menu_backdrop"
        ></div>
      ) : (
        ''
      )}
      <ListItemButton
        sx={{
          p: '4px',
          pl: 2,
          pr: 1,
          borderTopLeftRadius: '15px',
          borderBottomLeftRadius: '15px',
          backgroundColor: backgroundColor,
          '&:hover;&:focus': {
            backgroundColor: backgroundColor,
          },
        }}
        id={current_menu.id}
        onClick={handleClick}
        className="menu-list-item"
      >
        <ListItemIcon sx={{ minWidth: small ? '36px' : '56px' }}>
          {Icono ? <Icono sx={{ color: 'background.menuContrast', opacity: '0.8' }} /> : null}
        </ListItemIcon>
        {!small ? <ListItemText primary={current_menu.nombre} /> : ''}
        {open === current_menu.id ? (
          <ExpandLess sx={{ ml: small ? 1 : 2 }} fontSize="small" />
        ) : (
          <ExpandMore sx={{ ml: small ? 1 : 2 }} fontSize="small" />
        )}
      </ListItemButton>
      {open === current_menu.id ? (
        <List
          sx={{
            position: 'absolute',
            width: 'auto',
            // transform: `translate(calc(${drawerWidth} - 13px), -70px)`,
            left: '100%',
            top: '-52px',
            backgroundColor: 'menu.hoverBackground',
            marginLeft: 'auto',
            borderBottomRightRadius: '20px',
            borderTopRightRadius: '20px',
            p: 2,
          }}
          component="div"
          disablePadding
        >
          <ListItemText
            primary={
              <Typography
                sx={{ pl: 1, textWrap: 'nowrap', fontSize: '1.2rem', fontWeight: 'bold' }}
              >
                {current_menu.nombre}
              </Typography>
            }
          />
          {current_menu.hijos.map((sub) => {
            if (sub.cod_padre !== current_menu.codigo || sub.visible !== 'S') return null
            const IconoHijo = sub?.icono ? IconsList[sub.icono] : null
            if (sub.programa) {
              return (
                <ListItemButton
                  key={`${current_menu.id}-${sub.id}`}
                  sx={{ p: '4px', pl: 1, pr: 1 }}
                  id={sub.id}
                  component={Link}
                  to={`/${sub.camino ? sub.camino : sub.programa}`}
                >
                  <ListItemIcon>
                    {IconoHijo ? <IconoHijo sx={{ color: 'background.menuContrast' }} /> : null}
                  </ListItemIcon>
                  <ListItemText
                    sx={{ textWrap: 'nowrap' }}
                    primary={<Typography>{sub.nombre}</Typography>}
                  />
                </ListItemButton>
              )
            }
            return null
          })}
        </List>
      ) : (
        ''
      )}
    </Box>
  )
}

export default DropRightButton
