// @ts-check
import axios from 'api/axios'

/**
 * This class hels to manage files convertion and download
 */
class FilesHelper {
  /**
   *
   * @typedef {Object} FileReturn
   * @property {string} FileReturn.name
   * @property {string} FileReturn.base64
   *
   * @async
   *
   * @param {File} file - The file to get the base64
   * @returns {Promise<FileReturn>}
   */
  static fileToBase64 = (file) => {
    return new Promise((resolve) => {
      var reader = new FileReader()
      // Read file content on file loaded event
      reader.onload = function (event) {
        if (!event?.target?.result) throw new Error('No se pudo leer el archivo')
        resolve({
          name: file.name,
          base64: event.target.result.toString().replace('data:', '').replace(/^.+,/, ''),
        })
      }

      // Convert data to base64
      reader.readAsDataURL(file)
    })
  }

  /**
   * Downloads a base64 string as a file.
   * @param {string} base64String - The base64-encoded string to be downloaded.
   * @param {string} fileName - The name of the file to be downloaded.
   */
  static downloadBase64String(base64String, fileName) {
    // Convert the base64 string to a Blob
    var byteCharacters = atob(base64String)
    var byteNumbers = new Array(byteCharacters.length)
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    var byteArray = new Uint8Array(byteNumbers)
    var blob = new Blob([byteArray], { type: 'application/octet-stream' })

    // Create a download link
    var url = URL.createObjectURL(blob)
    var a = document.createElement('a')
    a.href = url
    a.download = fileName

    // Append the link to the body
    document.body.appendChild(a)

    // Trigger the download
    a.click()

    // Clean up
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  /**
   * Get the local url to a file generated from base64 string
   * @param {string} base64String - The base64-encoded string to be downloaded.
   * @param {string} fileName - The name of the file to be downloaded.
   */
  static urlBase64String(base64String, mime_type = 'application/pdf') {
    // Convert the base64 string to a Blob
    var byteCharacters = atob(base64String)
    var byteNumbers = new Array(byteCharacters.length)
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    var byteArray = new Uint8Array(byteNumbers)
    var blob = new Blob([byteArray], { type: mime_type })

    // Create a download link
    var url = URL.createObjectURL(blob)
    return url
  }

  /**
   * This function makes the browser download a blob string
   *
   * @param {string} blobString - The Blob String
   * @param {string} mimeType - The file mimeType
   * @param {string} fileName - The name of the file to download
   */
  static blobDownload = (blobString, mimeType, fileName) => {
    const binary = window.atob(blobString.replace(/\s/g, ''))
    const len = binary.length
    const buffer = new ArrayBuffer(len)
    const view = new Uint8Array(buffer)

    for (let i = 0; i < len; i += 1) {
      view[i] = binary.charCodeAt(i)
    }

    const blobFile = new Blob([view], { type: mimeType })
    const url = URL.createObjectURL(blobFile)

    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style.display = 'none'
    a.href = url
    a.download = fileName

    a.click()
  }

  /**
   * This function downloads a document
   * @async
   *
   * @param {number} file_id - The id of the file to download
   * @param {number} document_type - The type of document to download
   * @param {string} url - Url to send the request
   * @param {boolean} visor - True if request is for embeded display
   */
  static async downloadFile(file_id, document_type, url, visor = false) {
    const endpoint = url
      ? url.replace('/api/v1', '') + (visor ? '?v=S' : '')
      : `/descargararchivo/?t=${document_type}&f=${file_id}&v=${visor ? 'S' : 'N'}`

    /** @type {import('axios').AxiosResponse<DocumentFileDTO>} */
    const response = await axios.get(endpoint)

    if (visor) return response.data

    FilesHelper.blobDownload(
      response.data.fichero,
      response.data.mime_type,
      response.data.nombre_archivo,
    )
  }
}

export default FilesHelper
