import { getNewsImg as getImg } from 'common/libs/images'
import { useEffect, useState } from 'react'
import '../styles/visorNoticias.scss'

import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import { red } from '@mui/material/colors'

import { Alert, LinearProgress } from '@mui/material'
import news from '../../../assets/images/news.png'
import useFetchNews from '../hooks/useFetchWebNews'

const VisorNoticias = () => {
  const { response, loading } = useFetchNews()
  const [noticias, setNoticias] = useState(response)
  const [isLoading, setIsLoading] = useState(loading)

  useEffect(() => {
    setNoticias(response)
  }, [response])
  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  const [noticia, setNoticia] = useState(0)

  if (noticia === 0) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        {isLoading ? (
          <LinearProgress />
        ) : !noticias ? (
          <>
            <Alert severity="info">No se ha encontrado ninguna noticia</Alert>
          </>
        ) : (
          <Grid container spacing={2}>
            {noticias?.length > 0 ? (
              noticias.map((current_new) => {
                const fecha = new Date(current_new.fechapubli)
                const img = getImg(current_new.contenido, news)
                return (
                  <Grid key={current_new.id} xs={12} sm={6} md={3}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{ bgcolor: red[500] }}
                            alt={current_new.autor}
                            src="/static/images/avatar/2.jpg"
                          />
                        }
                        title={current_new.nombre}
                        subheader={fecha.toLocaleDateString()}
                      />
                      <CardMedia component="img" height="194" image={img} alt="Imagen noticia" />
                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          {current_new.contenido.replace(/(<([^>]+)>)/gi, '').substring(0, 100)}
                        </Typography>
                      </CardContent>
                      <CardActions disableSpacing>
                        <Button size="small" onClick={() => setNoticia(current_new.id)}>
                          Leer más
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                )
              })
            ) : (
              <></>
            )}
          </Grid>
        )}
      </Box>
    )
  }
  const n = noticias.filter((s) => s.id === noticia)[0]
  const fecha = new Date(n.fechapubli)
  const html = { __html: n.contenido }
  return (
    <>
      <Button onClick={() => setNoticia(0)}>Cerrar</Button>
      <Typography variant="h2">{n.nombre}</Typography>
      <Typography variant="body2">{fecha.toLocaleDateString()}</Typography>
      <div className="insertedHtml" style={{ padding: 2 }} dangerouslySetInnerHTML={html}></div>
    </>
  )
}

export default VisorNoticias
