export const TableVariants = {
  /** @type  {import("common/types/table_variants.types").TableVariant}**/
  default: {
    commonCellSx: {
      ['--table-border-radius']: '10px',
      ['--table-cells-border-margin']: '16px',
    },
    headerCellSx: {
      alignContent: 'initial',
    },
  },
  /** @type  {import("common/types/table_variants.types").TableVariant}**/
  compact: {
    commonSx: {
      paddingX: 0,
      paddingY: 0,
    },
    bodyStyle: {
      p: 0,
      borderRadius: 0,
    },
    headerCellSx: {
      alignContent: 'initial',
      borderBottom: '1px solid',
      borderColor: 'text.primary',
    },
    bodyCellSx: {
      borderBottom: '1px solid',
      borderColor: 'background.lighter',
    },
    searchBoxSx: {
      display: 'block',
      ['& .MuiFormControl-root']: {
        width: '100%',
      },
      mb: 0,
    },
    commonCellSx: {
      mt: 0,
      p: 1,
      ['--table-cells-border-margin']: '0px',
      ['--table-border-radius']: '0px',
    },
  },
}

export default TableVariants
