import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { LinearProgress } from '@mui/material'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid2'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { esES } from '@mui/x-date-pickers/locales'
import ScrollDetector from 'common/components/ScrollDetector'
import 'dayjs/locale/es'
import { useEffect, useState } from 'react'
import ThemeManager from 'theming/ThemeManager'
import useFetchDocumentacion from '../hooks/useFetchDocumentacion'
import useFetchDocumentacionMeta from '../hooks/useFetchDocumentacionMeta'
import DocumentacionCard from './DocumentacionCard'
import CustomGrid from 'common/components/CustomGrid'
import useHashedData from 'common/hooks/useHashedData'

/**
 * @module Documentos
 */

/**
 * Muestra la documentacion de difusión
 * @components
 *
 * @param {Object} props
 * @param {number | string} [props.document_tipe]
 */
const Documentacion = ({ document_type = null, page_size = null, compact = false, limit = 0 }) => {
  const orderArray = [
    {
      valor: 'FA',
      elemento: (
        <>
          <ArrowUpwardIcon /> Fecha publicación
        </>
      ),
    },
    {
      valor: 'FD',
      elemento: (
        <>
          <ArrowDownwardIcon /> Fecha publicación
        </>
      ),
    },
    {
      valor: 'NA',
      elemento: (
        <>
          <ArrowUpwardIcon /> Título
        </>
      ),
    },
    {
      valor: 'ND',
      elemento: (
        <>
          <ArrowDownwardIcon /> Título
        </>
      ),
    },
    {
      valor: 'CA',
      elemento: (
        <>
          <ArrowUpwardIcon /> Categoría
        </>
      ),
    },
    {
      valor: 'CD',
      elemento: (
        <>
          <ArrowDownwardIcon /> Categoría
        </>
      ),
    },
    {
      valor: 'TA',
      elemento: (
        <>
          <ArrowUpwardIcon /> Etiqueta
        </>
      ),
    },
    {
      valor: 'TD',
      elemento: (
        <>
          <ArrowDownwardIcon /> Etiquea
        </>
      ),
    },
  ]

  const [texto, setTexto] = useState('')
  const [categoria, setCategoria] = useState('')
  const [categorias, setCategorias] = useState([])
  const [etiqueta, setEtiqueta] = useState('')
  const [etiquetas, setEtiquetas] = useState([])
  const [tipo, setTipo] = useState(document_type ?? '')
  const [tipos, setTipos] = useState([])
  const [valorDesde, setValorDesde] = useState()
  const [valorHasta, setValorHasta] = useState()
  const [orden, setOrden] = useState('')

  const [busqueda, setBusqueda] = useState('')

  const [lastPage, setLastPage] = useState(0)
  const [page, setPage] = useState(1)
  const { response, loading } = useFetchDocumentacion(
    page,
    tipo,
    limit ?? page_size,
    busqueda,
    categoria,
    etiqueta,
    valorDesde,
    valorHasta,
    orden,
  )

  const { resmeta, loadmeta } = useFetchDocumentacionMeta()
  const Theme = new ThemeManager()

  const [documents, setDocuments] = useState([])

  useEffect(() => {
    if (!loading && response.results && page !== lastPage) {
      if (response.next) setLastPage(lastPage + 1)

      setDocuments(response.previous ? [...documents, ...response.results] : [...response.results])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const changePage = (page) => {
    setPage(page)
  }

  useEffect(() => {
    if (!loadmeta && resmeta) {
      setCategorias(resmeta.categorias)
      setEtiquetas(resmeta.etiquetas)
      setTipos(resmeta.tipos)
    }
  }, [loadmeta])

  const resetState = () => {
    setDocuments([])
    setPage(1)
    setLastPage(0)
  }

  const handleChangeTipo = (event) => {
    resetState()
    const {
      target: { value },
    } = event
    setTipo(value)
  }

  const handleChangeCategoria = (event) => {
    resetState()
    const {
      target: { value },
    } = event
    setCategoria(value)
  }

  const handleChangeEtiqueta = (event) => {
    resetState()
    const {
      target: { value },
    } = event
    setEtiqueta(value)
  }

  const handleChangeDesde = (nuevo_valor) => {
    resetState()
    setValorDesde(nuevo_valor)
  }
  const handleChangeHasta = (nuevo_valor) => {
    resetState()
    setValorHasta(nuevo_valor)
  }

  const handleBlurTexto = () => {
    resetState()
    setBusqueda(texto)
  }

  const handleChangeOrden = (event) => {
    resetState()
    const {
      target: { value },
    } = event
    setOrden(value)
  }

  const FULL_WIDTH_ITEM = Theme.options?.documentacionOptions?.fullWidthItem
  const GRID_SPACING = Theme.options?.documentacionOptions?.spacing ?? 2
  const CustomCard = Theme.components?.DocumentacionCard
  const CustomDocumentos = Theme.components?.Documentos

  return (
    <>
      {document_type ? (
        <></>
      ) : (
        <Box key="buscador" sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
          <Grid container spacing={compact ? 0.1 : GRID_SPACING}>
            <Grid key="busqueda" size={{ xs: 12 }}>
              <TextField
                fullWidth
                id="texto"
                label="Introduzca un texto para buscar"
                placeholder="Búsqueda por palabra"
                variant="outlined"
                value={texto}
                onChange={(e) => setTexto(e.currentTarget.value)}
                onBlur={handleBlurTexto}
              />
            </Grid>
          </Grid>
          <Grid container spacing={compact ? 0.1 : GRID_SPACING}>
            <Grid key="tipo" size={{ xs: 12, md: 4 }}>
              <FormControl fullWidth>
                <InputLabel id="select-tipo-label">Tipo</InputLabel>
                <Select
                  labelId="select-tipo-label"
                  id="select-tipo"
                  value={tipo}
                  label="Tipo"
                  onChange={handleChangeTipo}
                  fullWidth
                >
                  <MenuItem value="">
                    <em>Sin selección</em>
                  </MenuItem>
                  {tipos?.map((t) => (
                    <MenuItem key={t.codigo} value={t.codigo}>
                      {t.descripcion}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid key="categoria" item size={{ xs: 12, md: 4 }}>
              <FormControl fullWidth>
                <InputLabel id="select-categoria-label">Categoría</InputLabel>
                <Select
                  labelId="select-categoria-label"
                  id="select-categoria"
                  value={categoria}
                  label="Categoría"
                  onChange={handleChangeCategoria}
                  fullWidth
                >
                  <MenuItem value="">
                    <em>Sin selección</em>
                  </MenuItem>
                  {categorias?.map((c) => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid key="etiqueta" item size={{ xs: 12, md: 4 }}>
              <FormControl fullWidth>
                <InputLabel id="select-etiqueta-label">Etiqueta</InputLabel>
                <Select
                  labelId="select-etiqueta-label"
                  id="select-etiqueta"
                  value={etiqueta}
                  label="Etiqueta"
                  onChange={handleChangeEtiqueta}
                  fullWidth
                >
                  <MenuItem value="">
                    <em>Sin selección</em>
                  </MenuItem>
                  {etiquetas?.map((e) => (
                    <MenuItem key={e.id} value={e.id}>
                      {e.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <LocalizationProvider
            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
            dateAdapter={AdapterDayjs}
            adapterLocale="es"
          >
            <Grid container spacing={2}>
              <Grid key={'g-desde'} size={{ xs: 12, md: 4 }}>
                <DatePicker
                  key={'desde'}
                  label="Fecha desde"
                  value={valorDesde}
                  onChange={(v) => handleChangeDesde(v)}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid key={'g-hasta'} size={{ xs: 12, md: 4 }}>
                <DatePicker
                  key={'hasta'}
                  label="Fecha hasta"
                  value={valorHasta}
                  onChange={(v) => handleChangeHasta(v)}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid key={'boton'} size={{ xs: 12, md: 4 }}>
                <FormControl fullWidth>
                  <InputLabel id="select-orden-label">Orden</InputLabel>
                  <Select
                    labelId="select-orden-label"
                    id="select-orden"
                    value={orden}
                    label="Orden"
                    onChange={handleChangeOrden}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>Sin selección</em>
                    </MenuItem>
                    {orderArray?.map((o, i) => (
                      <MenuItem key={i} value={o.valor}>
                        {o.elemento}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>
      )}
      <Box key="documentos" sx={{ flexGrow: compact ? 0 : 1 }}>
        {CustomDocumentos ? (
          <CustomDocumentos documentos={documents} />
        ) : (
          <>
            <CustomGrid
              dataSource={documents}
              count={documents?.length}
              loading={loading}
              onPageChanged={changePage}
              gridProps={{
                spacing: compact ? 0.1 : GRID_SPACING,
              }}
              gridItemsProps={{
                size: {
                  xs: 12,
                  sm: FULL_WIDTH_ITEM ? 12 : 6,
                  md: FULL_WIDTH_ITEM ? 12 : 4,
                  lg: FULL_WIDTH_ITEM ? 12 : 3,
                },
              }}
              CardLayout={({ data: document }) =>
                CustomCard ? (
                  <CustomCard document={document} compact={compact} />
                ) : (
                  <DocumentacionCard document={document} compact={compact} />
                )
              }
            />
          </>
        )}
        {limit && true ? (
          <></>
        ) : (
          <ScrollDetector
            show={!loading && response?.next !== null && page === lastPage}
            onElementIsVisible={() => {
              if (response?.next && response?.next !== null) setPage(page + 1)
            }}
          />
        )}
      </Box>
    </>
  )
}

export default Documentacion
