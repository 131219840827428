import { useState } from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid2'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import CustomUserCard from 'features/users/components/CustomUserCard'
import { useLocation } from 'react-router-dom'

import useFetchLaboral from '../hooks/useFetchLaboral'
import LaboralDocumentCard from './LaboralDocumentCard'
import CustomGrid from 'common/components/CustomGrid'

/**
 *@module Documentacion
 *@module DocumentacionLaboral
 */

/**
 * Grid View for the Laboral Docs
 *
 * @param {Object} props
 * @param {string | number} [props.ejercicio] - The year to be fetched
 * @param {string} [props.document_type] - The tipe of document to fetch
 * @example
 * <Laboral />
 */
const Laboral = ({ ejercicio = null, document_type = null }) => {
  const location = useLocation()
  const [user, setUser] = useState(location?.state?.user ?? null)
  const { response, loading } = useFetchLaboral({ user, ejercicio, tipo_documento: document_type })

  return (
    <Box sx={{ width: '100%' }}>
      {user ? <CustomUserCard user={user} returnToUser={() => setUser(null)} /> : <></>}
      <Stack spacing={0}>
        <Box key="documentos" sx={{ flexGrow: 1, mt: 2, mb: 2 }}>
          <CustomGrid
            loading={loading}
            dataSource={response}
            count={response?.length}
            gridItemsProps={{ size: { xs: 12, md: 3, sm: 6 } }}
            CardLayout={({ data: document }) => <LaboralDocumentCard document={document} />}
            gridProps={{ spacing: 2 }}
          />
        </Box>
      </Stack>
    </Box>
  )
}

export default Laboral
