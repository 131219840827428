/**
 * @module HojasGasto
 */ import { GridView, TableRows } from '@mui/icons-material'
import { Button, ButtonGroup, LinearProgress } from '@mui/material'
import { Box } from '@mui/system'
import CustomGrid from 'common/components/CustomGrid'
import { useEffect, useMemo, useState } from 'react'
import { HojasGastoAddModal } from '../components/HojasGastoAddModal'
import HojasGastosCard from '../components/HojasGastosCard'
import { HojasGastoTable } from '../components/HojasGastoTable'
import useFetchHojasGasto from '../hooks/useFetchHojasGasto'

export const HojasGastoPage = () => {
  const [viewType, setViewType] = useState('TABLE')
  const [page, setPage] = useState(1)
  const [iterator, setIterator] = useState(1)
  const [pageSize, setPageSize] = useState(12)

  const { response, loading } = useFetchHojasGasto({ page, iterator, page_size: pageSize })
  const [hashedResponse, setHasshedResponse] = useState({})

  useEffect(() => {
    const newHash = { ...hashedResponse }
    if (hashedResponse[page]) return

    newHash[page] = response.results
    setHasshedResponse(newHash)
  }, [response])

  const data = useMemo(() => {
    const newVal = Object.values(hashedResponse)?.flat() || []
    return newVal?.[0] ? newVal : []
  }, [hashedResponse])

  const changePage = (page) => {
    if (loading) return
    if (hashedResponse[page]) return
    setPage(page)
  }

  const changePageSize = (value) => {
    setPageSize(value)
    setHasshedResponse({})
    setPage(1)
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '10px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '5px', flexDirection: 'row' }}>
          <HojasGastoAddModal />
        </Box>
        <ButtonGroup sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Button
            variant={viewType === 'TABLE' ? 'contained' : 'text'}
            onClick={() => {
              setViewType('TABLE')
            }}
          >
            <TableRows />
          </Button>
          <Button
            variant={viewType === 'TABLE' ? 'text' : 'contained'}
            onClick={() => {
              setViewType('GRID')
            }}
          >
            <GridView />
          </Button>
        </ButtonGroup>
      </Box>
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          alignContent: 'center',
          width: '100%',
          placeItems: 'center',
        }}
      >
        <Box sx={{ mt: 2, width: '100%' }}>
          <CustomGrid
            loading={loading}
            onPageChanged={changePage}
            dataSource={data ?? []}
            count={response?.count}
            gridProps={{ spacing: 2 }}
            gridItemsProps={{ size: { xs: 12, md: 4, sm: 6 } }}
            CardLayout={HojasGastosCard}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'center',
          textAlign: 'center',
          width: '100%',
        }}
      >
        {data ? (
          viewType === 'TABLE' ? (
            <>
              <HojasGastoTable
                hojasGasto={data ?? []}
                loading={loading}
                setIterator={setIterator}
                count={response?.count}
                rowsPerPage={pageSize}
                page={page}
                setPage={changePage}
                setRowsPerPage={changePageSize}
              />
            </>
          ) : (
            <>
              <Box sx={{ mt: 2, width: '100%' }}>
                <CustomGrid
                  loading={loading}
                  onPageChanged={changePage}
                  dataSource={data ?? []}
                  count={response?.count}
                  gridProps={{ spacing: 2 }}
                  gridItemsProps={{ size: { xs: 12, md: 3, sm: 6 } }}
                  CardLayout={HojasGastosCard}
                />
              </Box>
            </>
          )
        ) : (
          <>
            <Box sx={{ mt: 1, width: '100%' }}>
              <LinearProgress />
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}
