import EditIcon from '@mui/icons-material/Edit'
import { Avatar, Box, CardContent, CardHeader, Stack, TextField } from '@mui/material'

import BotonSolicitudInterna from 'features/solicitudes/components/BotonSolicitudInterna'
import { selectUsuario } from 'features/users/stores/userSlice'
import { useSelector } from 'react-redux'

const WorkerData = ({ datos = null }) => {
  const user = useSelector(selectUsuario)

  return (
    <>
      <CardHeader
        sx={{ position: 'relative' }}
        avatar={
          <Box>
            <Avatar
              src={`/assets/images/${user.imagen}`}
              sx={{
                transition: '.3s',
                aspectRatio: '4/4',
                width: '128px',
                height: '128px',
                position: 'absolute',
                top: '-62px',
              }}
            />
            <BotonSolicitudInterna
              id_solicitud_pe="CIMGT"
              sx={{
                position: 'absolute',
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 95,
                color: 'primary',
              }}
              type="fab"
              fabIcon={<EditIcon />}
              fabSize="small"
            />
          </Box>
        }
        title={`${datos?.primer_apellido ?? '--'} ${datos?.segundo_apellido ?? '--'}`}
        subheader={`${datos?.nombre ?? '--'}`}
        titleTypographyProps={{ marginLeft: '140px' }}
        subheaderTypographyProps={{ marginLeft: '140px' }}
      />
      <CardContent>
        <Box component="form" sx={{ display: 'flex' }}>
          <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
            <TextField
              disabled
              id="outlined-disabled"
              label="NIF"
              value={datos?.dni ?? '--'}
              sx={{ width: '33%' }}
            />
            <TextField
              disabled
              id="outlined-disabled"
              label="Código trabajador"
              value={datos?.codigo_trabajador ?? '--'}
              sx={{ width: '33%' }}
            />
            <TextField
              disabled
              id="outlined-disabled"
              label="Número SS"
              value={datos?.numero_ss ?? '--'}
              sx={{ width: '33%' }}
            />
          </Stack>
        </Box>
      </CardContent>
    </>
  )
}

export default WorkerData
