const icons = {
  PersonOutlineOutlined: 'fi fi-rr-user',
  HomeOutlined: 'fi fi-rr-home',
  SourceOutlined: 'fi fi-rr-document',
  Campaign: 'fi fi-rr-megaphone',
  Alarm: 'fi fi-rr-alarm-clock',
  AppsOutage: 'fi fi-rr-list',
  NotificationsActiveOutlined: 'fi fi-rr-bell-ring',
  DescriptionOutlined: 'fi fi-rr-document',
  Search: 'fi fi-rr-search',
  KeyRounded: 'fi fi-rr-sign-in-alt',
  LocalAtm: 'fi fi-rr-file-invoice-dollar',
  ChevronLeftIcon: 'fi fi-ss-angle-circle-left',
  Settings: 'fi fi-rr-settings',
  LogoutIcon: 'fi fi-rr-exit',
  PeopleOutline: 'fi fi-rr-users',
  NotificationsOutlined: 'fi fi-ss-bell',
  Inventory2Outlined: 'fi fi-ss-inbox-in',
}

export default icons
