import { Box, LinearProgress } from '@mui/material'
import { Toolbar, Editor as WEditor } from '@wangeditor/editor-for-react'
import { uploadImage } from 'common/libs/upload-image'
import { useState } from 'react'

const ToolbarConfig = {
  excludeKeys: ['group-video'],
}

const Editor = ({
  editor,
  html,
  setEditor,
  setHtml,
  toolbarConfig = ToolbarConfig,
  config = null,
}) => {
  const [isUploading, setIsUploading] = useState(false)
  const [progress, setProgress] = useState(0)

  const editorConfig = {
    placeholder: 'Escribe aquí...',
    MENU_CONF: {},
  }

  editorConfig.MENU_CONF['uploadImage'] = {
    // fieldName: 'file',
    async customUpload(file, insertFn) {
      try {
        const data = await uploadImage(file, 'prueba')
        insertFn(data.url, '', '')
      } catch (error) {
        alert('Ha fallado la subida de imagen')
      }
    },

    onBeforeUpload(_) {
      setProgress(0)
      setIsUploading(true)
    },

    onProgress(progress) {
      setProgress(progress)
    },

    onSuccess(_, __) {
      setIsUploading(false)
      setProgress(100)
    },

    onFailed() {
      alert('Ha fallado la subida de imagen')
    },
    // other config...
  }

  config = config ?? editorConfig

  const created = (e) => {
    setEditor(e)
  }

  return (
    <>
      {isUploading ? <LinearProgress variant="determinate" value={progress} /> : <></>}
      <Box sx={{ border: '1px solid ', borderColor: 'background.default', zIndex: 100 }}>
        <Toolbar
          editor={editor}
          defaultConfig={toolbarConfig}
          mode="default"
          style={{ borderBottom: '1px solid #ccc' }}
        />
        <WEditor
          defaultConfig={config}
          value={html}
          onCreated={created}
          onChange={(editor) => setHtml(editor.getHtml())}
          mode="default"
          style={{ height: '500px', overflowY: 'hidden' }}
        />
      </Box>
    </>
  )
}

export default Editor
