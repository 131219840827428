import { Divider, Typography } from '@mui/material'
import SolicitudesPendientes from '../components/SolicitudesPendientes'

const ListadoSolicitudesPendientes = () => {
  return (
    <>
      <SolicitudesPendientes />
    </>
  )
}
export default ListadoSolicitudesPendientes
