import { Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import { Box } from '@mui/system'
import axios from 'api/axios'
import {
  consultaInicial,
  selectCamposSolicitud,
  selectDatosSolicitud,
  selectDescripcionesSolicitud,
  setCampoCargando,
  setDatoSolicitud,
} from 'features/solicitudes/solicitudesSlice'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LabelComponent from '../LabelComponent'

function ListComponent({ idpk }) {
  const dispatch = useDispatch()
  const campos = useSelector(selectCamposSolicitud)
  const campo = campos.filter((c) => c.idpk === idpk)[0]

  const datos = useSelector(selectDatosSolicitud)
  const valor = datos[campo.idpk]

  const descripciones = useSelector(selectDescripcionesSolicitud)
  const descripcion = descripciones[campo.idpk]

  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('')

  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [lvLoading, setLvLoading] = useState(false)
  const [error, setError] = useState('')

  const getValoresConsulta = () => {
    const valores = []

    for (const idpk in datos) {
      if (Object.hasOwnProperty.call(datos, idpk)) {
        const valor = datos[idpk]
        const field = campos.filter((c) => Number(c.idpk) === Number(idpk))[0]
        valores.push({ codigo_campo: field.codigo_campo, valor, tipo: field.tipo_dato })
      }
    }
    return valores
  }

  const getConsultaLOV = (inicializar = false) => {
    if (!lvLoading) {
      setLvLoading(true)
      const valores = getValoresConsulta()
      axios
        .post(`/consultalov/${idpk}/`, { valores, busqueda: value })
        .then((request_response) => {
          const data = request_response.data
          setOptions(data.registros)
        })
        .catch((error) => setError(error))
        .finally(() => {
          setLvLoading(false)
          if (inicializar) {
            dispatch(consultaInicial({ idpk: campo.idpk, valores: [] }))
          }
        })
    }
  }

  useEffect(() => {
    if (campo && campo.inicializar) {
      dispatch(setCampoCargando({ idpk: campo.idpk, cargando: true }))
      getConsultaLOV(true)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // Lanzamos las queries iniciales que contienen a este campo
    campos.forEach((c) => {
      if (c.depende_de && c.depende_de.includes(campo.codigo_campo) && valor) {
        const valores = getValoresConsulta()
        dispatch(consultaInicial({ idpk: c.idpk, valores }))
      }
    })

    if (value !== valor) {
      const opcion_seleccionada = options?.filter((o) => o.codigo === valor)[0]
      if (opcion_seleccionada) {
        const opcionInicial = {
          codigo: valor,
          descripcion: opcion_seleccionada.descripcion,
        }
        setValue(opcionInicial)
        setInputValue(opcion_seleccionada.descripcion)
      } else {
        const opcionInicial = {
          codigo: valor,
          descripcion: descripcion,
        }
        setValue(opcionInicial)
        setInputValue(descripcion)
        setOptions([
          {
            id: 1,
            codigo: valor,
            descripcion: descripcion,
          },
        ])
      }
    }
    // eslint-disable-next-line
  }, [valor])

  useEffect(() => {
    if (open) {
      getConsultaLOV()
    }
    // eslint-disable-next-line
  }, [open])

  const handleChange = (nuevoValor) => {
    dispatch(
      setDatoSolicitud({
        idpk: campo.idpk,
        valor: nuevoValor ? nuevoValor.codigo : null,
        descripcion: nuevoValor ? nuevoValor.descripcion : '',
      }),
    )
    setValue(nuevoValor)
  }

  if (campo) {
    return error ? (
      <Alert severity="error">Error cargando los datos</Alert>
    ) : (
      <Box sx={{ width: '100%' }}>
        <LabelComponent text={campo.etiqueta} />
        <Autocomplete
          disablePortal
          value={value}
          onChange={(_, newValue) => {
            handleChange(newValue)
          }}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue)
          }}
          open={open}
          onOpen={() => {
            setOpen(true)
          }}
          onClose={() => {
            setOpen(false)
          }}
          isOptionEqualToValue={(option, val) => {
            return option.codigo === valor
          }}
          getOptionLabel={(option) => option.descripcion ?? ''}
          options={options ?? []}
          loading={lvLoading}
          key={`list-${campo.idpk}`}
          id={campo.codigo_campo}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={campo.etiqueta}
              error={campo.error || error}
              InputLabelProps={value ? { shrink: true } : {}}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {lvLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Box>
    )
  }
}

export default ListComponent
