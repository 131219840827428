import {
  AccountTree,
  Alarm,
  AppsOutage,
  Article,
  Badge,
  CalendarMonthOutlined,
  Campaign,
  DescriptionOutlined,
  Edit,
  FileCopyOutlined,
  FolderSharedOutlined,
  HomeOutlined,
  Inventory2Outlined,
  KeyRounded,
  Link,
  LocalAtm,
  LogoutOutlined,
  Newspaper,
  NotificationsActiveOutlined,
  NotificationsOutlined,
  OpenInNew,
  PeopleOutline,
  PermContactCalendarOutlined,
  PersonOutlineOutlined,
  QuizOutlined,
  RequestPageOutlined,
  Search,
  Settings,
  SourceOutlined,
} from '@mui/icons-material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LogoutIcon from '@mui/icons-material/Logout'

const muiicons = {
  AccountTree,
  Alarm,
  AppsOutage,
  Article,
  Badge,
  CalendarMonthOutlined,
  Campaign,
  DescriptionOutlined,
  Edit,
  FileCopyOutlined,
  FolderSharedOutlined,
  HomeOutlined,
  KeyRounded,
  Link,
  LocalAtm,
  LogoutOutlined,
  Newspaper,
  NotificationsActiveOutlined,
  OpenInNew,
  PeopleOutline,
  PermContactCalendarOutlined,
  PersonOutlineOutlined,
  QuizOutlined,
  RequestPageOutlined,
  Search,
  SourceOutlined,
  ChevronLeftIcon,
  ChevronRightIcon,
  Settings,
  LogoutIcon,
  NotificationsOutlined,
  Inventory2Outlined,
}
export default muiicons
