import { Folder } from '@mui/icons-material'
import UploadIcon from '@mui/icons-material/Upload'
import { Box, Divider, LinearProgress, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import useCurrentMenu from 'common/hooks/useCurrentMenu'
import BotonSolicitudInterna from 'features/solicitudes/components/BotonSolicitudInterna'
import CustomUserCard from 'features/users/components/CustomUserCard'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import useFetchArbol from '../hooks/useFetchArbol'
import useFetchLevelDocs from '../hooks/useFetchLevelDocs'
import DocumentsView from './DocumentsView'
import FolderView from './FolderView'

/**
 * Personal.
 *
 * @param {Object} params
 * @param {string} [params.forcedLevel] The default Level
 */
const Personal = ({ forcedLevel = null }) => {
  const location = useLocation()
  const [user, setUser] = useState(location?.state?.user ?? null)
  const { response, loading } = useFetchArbol({ user })
  const [/** @type {string} */ docLevel, setDocLevel] = useState(/**@type {string}*/ null)
  const { response: responseDocs, loading: loadingDocs } = useFetchLevelDocs(
    forcedLevel && forcedLevel !== '' ? forcedLevel : docLevel?.codigo_nivel ?? null,
  )
  const { actualMatch, currentMenu } = useCurrentMenu()

  const [arbol, setArbol] = useState([])
  const [documents, setDocuments] = useState(responseDocs)

  const [isLoading, setIsLoading] = useState(loading)
  const [isLoadingDocs, setIsLoadingDocs] = useState(loadingDocs)

  useEffect(() => setIsLoading(loading), [loading])
  useEffect(() => setIsLoadingDocs(loadingDocs), [loadingDocs])
  useEffect(() => setDocuments(responseDocs), [responseDocs])
  useEffect(() => setArbol(response), [response])

  return (
    <>
      {user ? (
        <>
          <CustomUserCard
            user={user}
            loading={isLoadingDocs || isLoading}
            returnToUser={() => setUser(null)}
          />
          <Divider sx={{ mt: 1, mb: 1, opacity: 0 }} />
        </>
      ) : (
        <></>
      )}
      {forcedLevel && actualMatch?.programa !== currentMenu?.programa ? (
        <Grid container spacing={2}>
          <Grid xs={12} md={8}>
            <Box sx={{ ml: 2, mb: 2, display: 'flex', alignItems: 'center' }}>
              <Folder color="primary" sx={{ transform: 'translateY(-2px)', mr: 2 }} />
              <Typography>
                {currentMenu?.nombre}: {actualMatch?.nombre}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <BotonSolicitudInterna
              titulo="Subir Documento"
              id_solicitud_pe="PERDC"
              buttonProps={{
                sx: {
                  transition: '.3s',
                  textAlign: 'center',
                  width: {
                    xs: '100%',
                  },
                },
              }}
              buttonStartIcon={<UploadIcon />}
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      {!forcedLevel ? (
        <Grid container spacing={2}>
          <Grid xs={12} md={8}>
            <Box sx={{ ml: 2, mb: 2, display: 'flex', alignItems: 'center' }}>
              <FolderView setCurrentLevel={setDocLevel} tree={arbol} />
            </Box>
          </Grid>
          <Grid xs={12} md={4} justifyContent="flex-end">
            <BotonSolicitudInterna
              titulo="Subir documento"
              id_solicitud_pe="PERDC"
              buttonProps={{
                sx: {
                  transition: '.3s',
                  textAlign: 'center',
                },
              }}
              buttonStartIcon={<UploadIcon />}
            />
          </Grid>
          <Divider />
        </Grid>
      ) : (
        <></>
      )}
      {isLoadingDocs || loading ? (
        <LinearProgress />
      ) : (
        <DocumentsView documents={documents ?? []} hideNav={true} setCurrentLevel={setDocLevel} />
      )}
    </>
  )
}

export default Personal
