import { Logout as LogoutIcon, Settings } from '@mui/icons-material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import { Box } from '@mui/system'
import UserImage from 'common/components/UserImage'
import IconsList from 'common/constants/icons_map'
import { selectMenus } from 'features/portal/menusSlice'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const MenuDrawer = ({
  expanded,
  DRAWER_WIDTH,
  ALLWAYS_EXPANDED,
  COLLAPSED_DRAWER_WIDTH,
  mobileOpen,
  open,
  handleClick,
}) => {
  const menus = useSelector(selectMenus)

  return (
    <Collapse
      in={open || mobileOpen || ALLWAYS_EXPANDED || (!ALLWAYS_EXPANDED && expanded)}
      orientation="horizontal"
      collapsedSize={COLLAPSED_DRAWER_WIDTH}
      sx={{
        root: {
          width: '100%',
          xs: {
            width: DRAWER_WIDTH,
            height: '100vh',
            height: '100dvh',
            minWidth: 'initial !important',
            backgroundColor: 'background.menu',
          },
        },
      }}
    >
      <Box
        sx={{
          height: '100vh',
          height: '100dvh',
          width: DRAWER_WIDTH,
          backgroundColor: {
            xs: 'background.menu',
            md: 'transparent',
          },
          p: {
            xs: 0,
            md: !ALLWAYS_EXPANDED || mobileOpen ? 0 : 1,
          },
        }}
      >
        <Paper
          sx={{
            // minHeight: mobileOpen ? '100vh' : true ? '100%' : '97.4vh',
            height: '100%',
            transition: '.3s',
            width: '100%',
            borderRadius: ALLWAYS_EXPANDED ? 1 : 0,
            backgroundColor: 'background.menu',
            color: 'background.menuContrast',
          }}
        >
          <List
            sx={{
              transition: '.3s',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              '& > *': {
                flexGrow: '0 !important',

                '&:last-child': {
                  marginTop: 'auto',
                },
              },
              maxWidth: '320px',
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <UserImage
              expanded={ALLWAYS_EXPANDED || expanded}
              boxSx={{
                transition: '.3s ease-in',
                width: ALLWAYS_EXPANDED || expanded ? '100%' : COLLAPSED_DRAWER_WIDTH,
                marginX: ALLWAYS_EXPANDED || expanded ? 'auto' : 'initial',
                marginBottom: 2,
                display: {
                  xs: 'none',
                  sm: 'initial',
                },
                // md: !ALLWAYS_EXPANDED || mobileOpen ? 0 : 1,
              }}
            />
            <div>
              {menus.map((current_menu) => {
                if (current_menu.visible !== 'S') return null
                const Icono = current_menu?.icono ? IconsList[current_menu.icono] : null
                const to =
                  current_menu.programa === 'url'
                    ? current_menu.camino
                    : `/${current_menu.camino ? current_menu.camino : current_menu.programa}`
                const target = current_menu.programa === 'url' ? '_blank' : null
                const rel = current_menu.programa === 'url' ? 'noopener' : null

                if (current_menu.programa) {
                  return (
                    <ListItemButton
                      key={current_menu.id}
                      id={current_menu.id}
                      component={Link}
                      sx={{ p: '4px', pl: 2, pr: 1 }}
                      to={to}
                      target={target}
                      rel={rel}
                    >
                      <ListItemIcon>
                        {Icono ? <Icono sx={{ color: 'background.menuContrast' }} /> : null}
                      </ListItemIcon>
                      <ListItemText primary={current_menu.nombre} />
                    </ListItemButton>
                  )
                } else if (current_menu.hijos && current_menu.hijos.length > 0) {
                  return (
                    <React.Fragment key={current_menu.id}>
                      <ListItemButton
                        sx={{ p: '4px', pl: 2, pr: 1 }}
                        id={current_menu.id}
                        onClick={handleClick}
                        className="menu-list-item"
                      >
                        <ListItemIcon>
                          {Icono ? (
                            <Icono sx={{ color: 'background.menuContrast', opacity: '0.8' }} />
                          ) : null}
                        </ListItemIcon>
                        <ListItemText primary={current_menu.nombre} />
                        {open === current_menu.id ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse
                        id={current_menu.id}
                        in={open === current_menu.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {current_menu.hijos.map((sub) => {
                            if (sub.cod_padre !== current_menu.codigo || sub.visible !== 'S')
                              return null
                            const IconoHijo = sub?.icono ? IconsList[sub.icono] : null
                            if (sub.programa) {
                              return (
                                <ListItemButton
                                  key={`${current_menu.id}-${sub.id}`}
                                  sx={{ p: '4px', pl: 4, pr: 1 }}
                                  id={sub.id}
                                  component={Link}
                                  to={`/${sub.camino ? sub.camino : sub.programa}`}
                                >
                                  <ListItemIcon>
                                    {IconoHijo ? (
                                      <IconoHijo sx={{ color: 'background.menuContrast' }} />
                                    ) : null}
                                  </ListItemIcon>
                                  <ListItemText primary={sub.nombre} />
                                </ListItemButton>
                              )
                            }
                            return null
                          })}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  )
                }
                return null
              })}
            </div>
            <div>
              <ListItemButton
                sx={{ p: '4px', pl: 2, pr: 1 }}
                id={99999}
                component={Link}
                className="menu-list-item"
                to="/ajustes"
              >
                <ListItemIcon>
                  <Settings sx={{ color: 'background.menuContrast' }} />
                </ListItemIcon>
                <ListItemText primary={'Ajustes'} />
              </ListItemButton>
              <ListItemButton
                sx={{ p: '4px', pl: 2, pr: 1 }}
                id={99999}
                className="menu-list-item"
                to="/logout"
              >
                <ListItemIcon>
                  <LogoutIcon sx={{ color: 'background.menuContrast' }} />
                </ListItemIcon>
                <ListItemText primary={'Logout'} />
              </ListItemButton>
            </div>
          </List>
        </Paper>
      </Box>
    </Collapse>
  )
}

export default MenuDrawer
