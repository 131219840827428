import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import useCurrentMenu from 'common/hooks/useCurrentMenu'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'

const PageTitle = ({ customTitle, customSubtitle }) => {
  const location = useLocation()
  const { actualMatch, currentMenu } = useCurrentMenu()
  const Theme = new ThemeManager()

  const h2Text = useMemo(() => {
    const solicitud = location?.state?.solicitud
    if (solicitud) return solicitud.descripcion ?? solicitud.d_tipo ?? ''
    return actualMatch?.nombre
  }, [actualMatch?.nombre, location])

  const displayH2 = useMemo(() => {
    const exludeProgram = Theme.options?.pageTitleOptions?.excludeActualMatchElements.includes(
      actualMatch?.programa,
    )
    const parentSame = currentMenu?.nombre === actualMatch?.nombre

    return (exludeProgram || parentSame) && !h2Text
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualMatch, currentMenu, h2Text])

  return actualMatch?.nombre && currentMenu?.nombre ? (
    <Box
      sx={{
        display: Theme.options?.pageTitleOptions?.excludeElements?.includes(actualMatch?.programa)
          ? 'none'
          : 'flex',
        alignItems: 'baseline',
        flexWrap: 'wrap',
        gap: 2,
        my: 4,
      }}
    >
      <Typography variant="h1">{currentMenu?.nombre}</Typography>
      <Typography
        variant="h2"
        sx={{
          display: displayH2 ? 'none' : 'flex',
        }}
      >
        {h2Text}
      </Typography>
    </Box>
  ) : (
    <></>
  )
}

export default PageTitle
