import { Skeleton } from '@mui/material'
import Grid from '@mui/material/Grid2'
import React from 'react'

export default function CardsGroupSkeleton({ gridItemsProps }) {
  return (
    <>
      <Grid {...gridItemsProps}>
        <Skeleton sx={{ width: '100%', height: '300px' }} />
      </Grid>

      <Grid {...gridItemsProps}>
        <Skeleton sx={{ width: '100%', height: '300px' }} />
      </Grid>
      <Grid {...gridItemsProps}>
        <Skeleton sx={{ width: '100%', height: '300px' }} />
      </Grid>
      <Grid {...gridItemsProps}>
        <Skeleton sx={{ width: '100%', height: '300px' }} />
      </Grid>
      <Grid {...gridItemsProps}>
        <Skeleton sx={{ width: '100%', height: '300px' }} />
      </Grid>

      <Grid {...gridItemsProps}>
        <Skeleton sx={{ width: '100%', height: '300px' }} />
      </Grid>
      <Grid {...gridItemsProps}>
        <Skeleton sx={{ width: '100%', height: '300px' }} />
      </Grid>
      <Grid {...gridItemsProps}>
        <Skeleton sx={{ width: '100%', height: '300px' }} />
      </Grid>
    </>
  )
}
