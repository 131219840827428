import { useEffect } from 'react'
/**
 * Custom hook to synchronize the scroll positions of two `div` elements.
 *
 * @param {React.RefObject<HTMLDivElement>} div1Ref - Ref object for the first `div`.
 * @param {React.RefObject<HTMLDivElement>} div2Ref - Ref object for the second `div`.
 *
 * @example
 * const div1Ref = useRef(null);
 * const div2Ref = useRef(null);
 * useSyncedScroll(div1Ref, div2Ref);
 */
const useSyncedScroll = (div1Ref, div2Ref) => {
  useEffect(() => {
    const div1 = div1Ref.current
    const div2 = div2Ref.current

    if (!div1 || !div2) return

    const handleScrollDiv1 = () => {
      div2.scrollTop = div1.scrollTop
      div2.scrollLeft = div1.scrollLeft
    }

    const handleScrollDiv2 = () => {
      div1.scrollTop = div2.scrollTop
      div1.scrollLeft = div2.scrollLeft
    }

    div1.addEventListener('scroll', handleScrollDiv1)
    div2.addEventListener('scroll', handleScrollDiv2)

    return () => {
      div1.removeEventListener('scroll', handleScrollDiv1)
      div2.removeEventListener('scroll', handleScrollDiv2)
    }
  }, [div1Ref, div2Ref])
}

export default useSyncedScroll
