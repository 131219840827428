import { PlayArrow } from '@mui/icons-material'
import { Backdrop, Box, Button, CircularProgress, Skeleton, Typography } from '@mui/material'
import useFetchTipoSalida from 'features/control_presencia/hooks/useFetchTipoSalida'
import FichajesController, {
  PresenceControlActions,
} from 'features/control_presencia/libs/FichajesController'
import { colorSalidaMap, tiposSalidaMap } from 'features/control_presencia/libs/tiposSalidaMap'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'

/**
 * @module ControlPresencia
 */

/**
 * Buttons to mark enter or leave of
 * the presence control
 *
 * @param {Object} props
 * @param {boolean} props.loading
 * @param {boolean} props.modal
 * @param {function} props.setLastIteration
 * @param {number} props.lastIteration
 * @param {import('common/contexts/CustomModalContext').CustomModalHandler} props.handleModal
 * @example
 * ...
 * <PresenceActions
 *  loading={loading}
 *  setLastIteration={setLastIteration}
 *  lastIteration={lastIteration}
 *  handleModal={handleModal}
 *  modal={modal}
 * />
 * ...
 * @return {JSX.Element}
 */
const PresenceActions = ({ loading, modal, setLastIteration, lastIteration, handleModal }) => {
  const { response: tiposSalida } = useFetchTipoSalida()
  const [openBackdrop, setOpenBackdrop] = useState(false)

  /**
   * Mark a presence control registry
   * @async
   * @param {PresenceControlActions} type - The tipe of action to perform
   * @param {string} [exitCode] - If is a exit type this param must be provided
   * @returns {Promise<void>}
   * */
  async function markAction(type, exitCode) {
    try {
      setOpenBackdrop(true)
      await FichajesController.create(type, exitCode)
      setLastIteration(lastIteration + 1)
      enqueueSnackbar('Guardado correctamente', {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error', {
        variant: 'error',
      })
      console.error(error)
    } finally {
      setOpenBackdrop(false)
    }
  }

  return loading ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: 2,
        width: '100%',
      }}
    >
      <Skeleton variant="rounded" height={40} width={240} />
      <Skeleton variant="rounded" height={40} width={240} />
      <Skeleton variant="rounded" height={40} width={240} />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: 2,
        width: '100%',
      }}
    >
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: 'column',
          gap: '20px',
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
        <Typography>Guardando Fichaje</Typography>
      </Backdrop>
      <Button
        startIcon={<PlayArrow />}
        disabled={loading || modal}
        onClick={async () => {
          markAction(PresenceControlActions.Entrada)
        }}
        variant="text"
        color="success"
        sx={{ flexGrow: 0, textWrap: 'nowrap' }}
      >
        Entrada
      </Button>
      <Box sx={{ flex: '1 1 0', display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}>
        {tiposSalida?.map((exit, index) => {
          const Icon = tiposSalidaMap[exit.codigo] || tiposSalidaMap.DEFAULT
          const color = colorSalidaMap[exit.codigo] || colorSalidaMap.DEFAULT
          return (
            <Button
              key={index}
              startIcon={<Icon />}
              disabled={loading || modal}
              onClick={async () => {
                markAction(PresenceControlActions.Salida, exit.codigo)
              }}
              variant="text"
              color={color}
              sx={{
                textWrap: 'nowrap',
              }}
            >
              {exit.descripcion}
            </Button>
          )
        })}
      </Box>
    </Box>
  )
}

export default PresenceActions
