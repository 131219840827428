import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import LockPersonIcon from '@mui/icons-material/LockPerson'
import { Tooltip } from '@mui/material'
import SimpleTable from 'common/components/SimpleTable'
import Table from 'common/components/Table'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import { enqueueSnackbar } from 'notistack'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'
import { closeHojaGasto } from '../lib/HojasGastoController'

/**
 *
 *
 * @typedef HojasGastoProps
 *
 * @prop {Array<object>} hojasGasto -
 * @prop {boolean} loading -
 * @prop {(number) => void} setIterator -
 * @prop {number} count -
 * @prop {number} page -
 * @prop {number} rowsPerPage -
 * @prop {(number) => void} setPage -
 * @returns {JSX.Element}
 */

/**
 * @param {HojasGastoProps} props
 */
export const HojasGastoTable = ({
  hojasGasto,
  loading,
  setIterator,
  count,
  page,
  rowsPerPage,
  setRowsPerPage,
  setPage,
}) => {
  const Theme = new ThemeManager()
  const navigate = useNavigate()
  const { handleModal } = useContext(CustomModalContext)
  // const handleClick = async ({ id }) => {
  //   try {
  //     handleModal({ type: ModalTypes.Loading })
  //     await closeHojaGasto({ id })
  //     handleModal({ enabled: false })
  //
  //     enqueueSnackbar('Hoja cerrada correctamente', { variant: 'success' })
  //     setIterator(Math.random())
  //   } catch (e) {
  //     enqueueSnackbar('No ha sido posible cerrar la hoja')
  //     console.log(e.message)
  //   }
  // }

  const onRowClick = ({ row }) => {
    navigate(`/hojasgasto/${row.id}`)
  }

  return (
    <>
      <Table
        onRowsPerPageChanged={setRowsPerPage}
        dataSource={hojasGasto}
        header_style={{
          backgroundColor: Theme.pallete?.primary?.main,
          color: Theme.pallete.primary.contrastText,
        }}
        count={count}
        page={page}
        ignoreOldTable={false}
        rowsPerPage={rowsPerPage}
        header_cell_style={{ padding: '10px' }}
        table_style={{ width: '100%', borderCollapse: 'collapse' }}
        container_style={{ borderRadius: '5px', marginBottom: '5px', marginTop: '10px' }}
        onPageChanged={setPage}
        generic_cell_style={{
          textAlign: 'center',
          padding: '15px 10px',
        }}
        loading={loading}
        emptyText={'No hay hojas de gasto'}
        stripped={true}
        onRowClick={onRowClick}
        fields={[
          {
            field: '',
            title: '',
            cellWidth: '80px',
            forceWidth: true,
            customGetter: (item) =>
              item.status === '0500' ? (
                <Tooltip title="Abierta">
                  <LockOpenIcon color="success" />
                </Tooltip>
              ) : item.status === '1500' ? (
                <Tooltip title="En validación" color="warning">
                  <LockPersonIcon />
                </Tooltip>
              ) : (
                <Tooltip title="Cerrada">
                  <LockIcon color="error" />
                </Tooltip>
              ),
          },
          {
            field: 'id',
            title: 'Id',
          },

          {
            field: 'ejercicio',
            title: 'Ejercicio',
          },
          {
            field: 'd_periodo',
            title: 'Periodo',
          },
          {
            field: 'fecha_inicio',
            title: 'Fecha Inicio',
          },
          {
            field: 'fecha_fin',
            title: 'Fecha Fin',
          },
          {
            field: 'd_tipo',
            title: 'Tipo',
          },
          {
            field: 'd_tipo_grupo',
            title: 'Grupo',
          },
          {
            field: 'importe',
            title: 'Total',
          },
        ]}
      />
    </>
  )
}
