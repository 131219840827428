import { PlayArrow } from '@mui/icons-material'
import { Box, Button, Skeleton } from '@mui/material'
import { ModalTypes } from 'common/hooks/useCustomModal'
import { enqueueSnackbar } from 'notistack'
import useFetchTipoSalida from '../hooks/useFetchTipoSalida'
import FichajesController, { PresenceControlActions } from '../libs/FichajesController'
import { colorSalidaMap, tiposSalidaMap } from '../libs/tiposSalidaMap'

/**
 * @module ControlPresencia
 */

/**
 * Buttons to mark enter or leave of
 * the presence control
 *
 * @param {Object} props
 * @param {boolean} props.loading
 * @param {boolean} props.modal
 * @param {function} props.setLastIteration
 * @param {number} props.lastIteration
 * @param {import('common/contexts/CustomModalContext').CustomModalHandler} props.handleModal
 * @example
 * ...
 * <PresenceActions
 *  loading={loading}
 *  setLastIteration={setLastIteration}
 *  lastIteration={lastIteration}
 *  handleModal={handleModal}
 *  modal={modal}
 * />
 * ...
 * @return {JSX.Element}
 */
const PresenceActions = ({ loading, modal, setLastIteration, lastIteration, handleModal }) => {
  const { response: tiposSalida } = useFetchTipoSalida()

  /**
   * Mark a presence control registry
   * @async
   * @param {PresenceControlActions} type - The tipe of action to perform
   * @param {string} [exitCode] - If is a exit type this param must be provided
   * @returns {Promise<void>}
   * */
  async function markAction(type, exitCode) {
    try {
      handleModal({ type: ModalTypes.Loading, enabled: true })
      await FichajesController.create(type, exitCode)
      setLastIteration(lastIteration + 1)
    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error', {
        variant: 'error',
      })
      console.error(error)
    } finally {
      handleModal({ type: ModalTypes.Loading, enabled: false })
    }
  }

  return loading ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: 2,
        width: '100%',
      }}
    >
      <Skeleton variant="rounded" height={40} width={240} />
      <Skeleton variant="rounded" height={40} width={240} />
      <Skeleton variant="rounded" height={40} width={240} />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: 2,
        width: '100%',
      }}
    >
      <Button
        startIcon={<PlayArrow />}
        disabled={loading || modal}
        onClick={async () => {
          markAction(PresenceControlActions.Entrada)
        }}
        variant="contained"
        color="success"
        sx={{ flexGrow: 0, textWrap: 'nowrap' }}
      >
        Entrada
      </Button>
      <Box sx={{ flex: '1 1 0', display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}>
        {tiposSalida?.map((exit, index) => {
          const Icon = tiposSalidaMap[exit.codigo] || tiposSalidaMap.DEFAULT
          const color = colorSalidaMap[exit.codigo] || colorSalidaMap.DEFAULT
          return (
            <Button
              key={index}
              startIcon={<Icon />}
              disabled={loading || modal}
              onClick={async () => {
                markAction(PresenceControlActions.Salida, exit.codigo)
              }}
              variant="contained"
              color={color}
              sx={{
                textWrap: 'nowrap',
              }}
            >
              {exit.descripcion}
            </Button>
          )
        })}
      </Box>
    </Box>
  )
}

export default PresenceActions
