const icons = {
  'fi-rr-user': 'fi fi-rr-user',
  'fi-rr-lock': 'fi fi-rr-lock',
  'fi-rr-folder': 'fi fi-rr-folder',
  'fi-rr-megaphone': 'fi fi-rr-megaphone',
  'fi-rr-alarm-clock': 'fi fi-rr-alarm-clock',
  'fi-rr-list': 'fi fi-rr-list',
  'fi-rr-bell-ring': 'fi fi-rr-bell-ring',
  'fi-rr-document': 'fi fi-rr-document',
  'fi-rr-comments': 'fi fi-rr-comments',
  'fi-rr-browser': 'fi fi-rr-browser',
  'fi-rr-search': 'fi fi-rr-search',
  'fi-rr-sign-in-alt': 'fi fi-rr-sign-in-alt',
  'fi-rr-settings': 'fi fi-rr-settings',
  'fi-rr-exit': 'fi fi-rr-exit',
  'fi-sr-angle-circle-left': 'fi fi-sr-angle-circle-left',
  'fi-ss-bell': 'fi fi-ss-bell',
  'fi-sr-inbox-in': 'fi fi-sr-inbox-in',
  'fi-rr-file-invoice-dollar': 'fi fi-rr-file-invoice-dollar',
  'fi-rr-users': 'fi fi-rr-users',
  'fi-rr-arrow-circle-down': 'fi fi-ss-arrow-circle-down',
  'fi-rr-eye': 'fi fi-rr-eye',
  'fi-rr-info': 'fi fi-rr-info',
  'fi-ss-info': 'fi fi-ss-info',
  'fi-rr-triangle-warning': 'fi fi-rr-triangle-warning',
  'fi-ss-triangle-warning': 'fi fi-ss-triangle-warning',
  'fi-rr-check-circle': 'fi fi-rr-check-circle',
  'fi-rr-folder-open': 'fi fi-rr-folder-open',
  'fi-rr-menu-burger': 'fi fi-rr-menu-burger',
}

export default icons
