import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import DeleteIcon from '@mui/icons-material/Delete'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import LockPersonIcon from '@mui/icons-material/LockPerson'
import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import { getLocaleFromFullDate } from 'common/libs/fechas'
import { enqueueSnackbar, SnackbarProvider } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'
import { HojasGastoLin } from '../components/HojasGastoLin'
import useFetchHojaGasto from '../hooks/useFetchHojaGasto'
import {
  closeHojaGasto,
  deleteHojaGasto,
  getDetalladaHoja,
  getFirmasHoja,
  openHojaGasto,
} from '../lib/HojasGastoController'
export const HojasGastoLinPage = ({ id_h, encr, disableAll = false }) => {
  const { id_hoja } = useParams()
  const id = id_hoja ? id_hoja : id_h
  const [iterator, setIterator] = useState(1)
  const [buttons, setButtons] = useState([])

  const { response, error, loading } = useFetchHojaGasto({
    id_hoja: id,
    encr,
    iterator,
  })

  const navigate = useNavigate()

  const Theme = new ThemeManager()

  const { handleModal } = useContext(CustomModalContext)

  const handleClick = async () => {
    try {
      handleModal({ type: ModalTypes.Loading })
      const res = await closeHojaGasto({ id: response.id })
      handleModal({ enabled: false })
      if (res) setIterator(Math.random())
    } catch (e) {
      handleModal({ enabled: false })
    }
  }
  const handleDeleteClick = async () => {
    try {
      handleModal({ type: ModalTypes.Loading })
      const res = await deleteHojaGasto({ id: response.id })
      handleModal({ enabled: false })
      if (res) navigate('/hojasgasto/')
    } catch (e) {
      enqueueSnackbar('No se ha podido borrar la hoja')
      handleModal({ enabled: false })
    }
  }

  const handleDetalladaClick = async ({ id }) => {
    try {
      handleModal({ type: ModalTypes.Loading })
      await getDetalladaHoja({ id })
      handleModal({ enabled: false })
    } catch (e) {
      handleModal({ enabled: false })
      enqueueSnackbar('No se ha podido obtener la vista detallada')
    }
  }
  const handleFirmasClick = async ({ id }) => {
    try {
      handleModal({ type: ModalTypes.Loading })
      await getFirmasHoja({ id })
      handleModal({ enabled: false })
    } catch (e) {
      handleModal({ enabled: false })
      enqueueSnackbar('No se ha podido obtener la vista de firmas')
      console.log(e.message)
    }
  }
  const handleChangeState = () => {
    setIterator(iterator + 1)
  }

  const handleOpenClick = async ({ id }) => {
    try {
      handleModal({ type: ModalTypes.Loading })
      const res = await openHojaGasto({ id })
      handleModal({ enabled: false })
      if (!res) return
      setIterator(iterator + 1)
    } catch (error) {
      console.log(error.message)
    }
  }

  const deleteButtonOnclick = () => {
    handleModal({
      type: ModalTypes.Confirm,
      closable: true,
      enabled: true,
      content: 'Está seguro de que desea borrar la hoja?',
      confirmDialogButtons: (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            textAlign: 'center',
            gap: '5px',
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              handleDeleteClick()
              handleModal({ enabled: false })
            }}
          >
            Aceptar
          </Button>
          <Button variant="contained" color="error" onClick={() => handleModal({ enabled: false })}>
            Rechazar
          </Button>
        </Box>
      ),
    })
  }

  useEffect(() => {
    if (disableAll) return
    if (Object.keys(response).length > 0) {
      let buttonArray = []
      if (response.actions.includes('B')) {
        buttonArray.push(
          <>
            <Button
              variant="contained"
              color="error"
              onClick={deleteButtonOnclick}
              key={'B_1'}
              sx={{ display: { xs: 'none', md: 'flex' } }}
              startIcon={<DeleteIcon />}
            >
              Borrar
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={deleteButtonOnclick}
              sx={{ display: { xs: 'flex', md: 'none' } }}
              key={'B_2'}
            >
              <DeleteIcon />
            </Button>
          </>,
        )
      }
      if (response.actions.includes('CE')) {
        buttonArray.push(
          <>
            <Button
              variant="contained"
              color="error"
              onClick={handleClick}
              key={'CE_1'}
              sx={{ display: { xs: 'none', md: 'flex' } }}
              startIcon={<LockIcon />}
            >
              Cerrar Hoja
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleClick}
              key={'CE_2'}
              sx={{ display: { xs: 'flex', md: 'none' } }}
            >
              <LockIcon />
            </Button>
          </>,
        )
      }
      if (response.actions.includes('A')) {
        buttonArray.push(
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenClick({ id: response?.id })}
              key={'A_1'}
              startIcon={<LockOpenIcon />}
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              Abrir Hoja
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenClick({ id: response?.id })}
              key={'A_2'}
              sx={{ display: { xs: 'flex', md: 'none' } }}
            >
              <LockOpenIcon />
            </Button>
          </>,
        )
      }
      if (response.actions.includes('D')) {
        buttonArray.push(
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleDetalladaClick({ id: response.id })}
              key={'D_1'}
              sx={{ display: { xs: 'none', md: 'flex' } }}
              startIcon={<SearchIcon />}
            >
              Detallada
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleDetalladaClick({ id: response.id })}
              key={'D_2'}
              sx={{ display: { xs: 'flex', md: 'none' } }}
            >
              <SearchIcon />
            </Button>
          </>,
        )
      }
      if (response.actions.includes('F')) {
        buttonArray.push(
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleFirmasClick({ id: response.id })}
              key={'F_1'}
              sx={{ display: { xs: 'none', md: 'flex' } }}
              startIcon={<BorderColorIcon />}
            >
              Firmas
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleFirmasClick({ id: response.id })}
              key={'F_2'}
              sx={{ display: { xs: 'flex', md: 'none' } }}
            >
              <BorderColorIcon />
            </Button>
          </>,
        )
      }
      setButtons(buttonArray)
    }
  }, [response])
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '10px',
        width: '100%',
        mb: 1,
      }}
    >
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={2000}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        variant={'error'}
      />
      <Grid
        container
        sx={{ width: '100%', placeItems: 'center', justifyContent: 'center', mb: 1 }}
        key={response?.id}
      >
        <Card sx={{ width: '100%' }}>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                marginBottom: '10px',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography>Id: {response?.id}</Typography>

                {response?.status === '0500' ? (
                  <Tooltip title="Abierta">
                    <LockOpenIcon color="success" />
                  </Tooltip>
                ) : response?.status === '1500' ? (
                  <Tooltip title="En validación" color="warning">
                    <LockPersonIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title="Cerrada">
                    <LockIcon color="error" />
                  </Tooltip>
                )}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography>Ejercicio: {response?.ejercicio}</Typography>
                <Typography>Periodo: {response?.d_periodo}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="caption" display="block" gutterBottom>
                    Fecha inicio
                  </Typography>
                  <Typography variant="body1">
                    {getLocaleFromFullDate(response?.fecha_inicio, false)}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="caption" display="block" gutterBottom>
                    Fecha fin
                  </Typography>
                  <Typography variant="body1">
                    {getLocaleFromFullDate(response?.fecha_fin, false)}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography>Tipo: {response?.d_tipo}</Typography>
                <Typography>Grupo: {response?.d_tipo_grupo}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                textAlign: 'center',
                justifyContent: 'center',
                background: Theme.pallete.background.alternative,
                // border: 1,
                // borderColor: 'transparent',
                borderRadius: 1,
              }}
            >
              <Typography sx={{ color: 'white' }}>Total: {response?.importe}</Typography>
            </Box>
          </CardContent>
          <CardActions>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Button
                sx={{
                  margin: '5px',
                  display: { xs: 'none', md: 'flex' },
                }}
                startIcon={<ArrowBackIcon />}
                variant="contained"
                color="primary"
                onClick={() => navigate('/hojasgasto/')}
              >
                Volver
              </Button>
              <Button
                sx={{
                  margin: '5px',
                  display: { xs: 'flex', md: 'none' },
                }}
                variant="contained"
                color="primary"
                onClick={() => navigate('/hojasgasto/')}
              >
                <ArrowBackIcon />
              </Button>
              <Box
                gap={2}
                sx={{
                  margin: '5px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end',
                  flexWrap: { xs: 'wrap', sm: 'nowrap' },
                }}
              >
                {buttons}
              </Box>
            </Box>
          </CardActions>
        </Card>
      </Grid>
      <HojasGastoLin
        disableAll={disableAll}
        id={id}
        fechaInicio={response?.fecha_inicio}
        fechaFin={response?.fecha_fin}
        showButton={response?.actions?.includes('C')}
        handleChangeState={handleChangeState}
        encr={encr}
        loading
      />
    </Box>
  )
}
