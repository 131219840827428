import MisSolicitudes from '../components/MisSolicitudes'

export const ListadoMisSolicitudes = () => {
  return (
    <>
      <MisSolicitudes />
    </>
  )
}
export default ListadoMisSolicitudes
