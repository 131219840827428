import { Search } from '@mui/icons-material'
import { Box, Button, TextField } from '@mui/material'
import Grid from '@mui/material/Grid2'
import Icon from 'icons/Icons'
import { useMemo, useState } from 'react'
import CardsGroupSkeleton from './CardsGroupSkeleton'
import ScrollDetector from './ScrollDetector'

/**
 *
 * @param {CardsGroupProps} props
 * @returns {JSX.Element}
 */
export const CustomGrid = ({
  dataSource,
  gridProps,
  gridItemsProps,
  page,
  count,
  CardLayout,
  loading,
  pageLimit = 12,
  onPageChanged,
  onSearchQueryChanged,
  showInternalSearch = false,
  useButtonPagination = true,
}) => {
  const [internalPage, setInternalPage] = useState(page ?? 1)
  const [searchValue, setSearchValue] = useState('')

  const showPagination = useMemo(() => {
    if (loading) return false
    return count > dataSource?.length && !loading
  }, [internalPage, count, dataSource?.length])

  const nextPage = () => {
    const maxPage = Math.ceil(count / pageLimit)
    if (maxPage <= internalPage) return
    setInternalPage(internalPage + 1)
    if (onPageChanged) onPageChanged(internalPage + 1)
  }

  const [queryString, setQueryString] = useState('')

  const handleQueryString = (value) => {
    if (!onSearchQueryChanged && showInternalSearch) return setQueryString(value)
    onSearchQueryChanged()
  }
  const itemsToRender = useMemo(() => {
    /**@type {Array<Object>}*/
    let newVal = dataSource
    if (queryString) {
      newVal = newVal.reduce((previousArray, currentValue) => {
        const newArray = [...previousArray]
        const values = Object.values(currentValue).flat()

        for (let i = 0; i < values.length; i++) {
          if (values[i]?.toString()?.toLowerCase()?.includes(queryString?.toLowerCase())) {
            newArray.push(currentValue)
            break
          }
        }

        return newArray
      }, [])
      return newVal
    }

    if (!dataSource?.[0]) return []
    return newVal
  }, [dataSource, queryString])

  return (
    <Box className="CardsGroup" sx={{ width: '100%' }}>
      {onSearchQueryChanged || showInternalSearch ? (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mb: 2 }}>
          <Box></Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <TextField
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleQueryString(searchValue)
                }
              }}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value ?? '')
              }}
              id="input-with-icon-textfield"
              placeHolder="Buscar"
              InputProps={{
                placeholder: 'Buscar',
                // endAdornment: <Search size={20} />,
              }}
              variant="standard"
            />
            <Button
              sx={{ px: 2, ml: 1 }}
              startIcon={<Icon icon="Search" />}
              onClick={() => {
                handleQueryString(searchValue)
              }}
            >
              Buscar
            </Button>
          </Box>
        </Box>
      ) : (
        <></>
      )}
      <Grid container {...gridProps}>
        {itemsToRender?.map((item, index) => {
          return (
            <Grid key={index} {...gridItemsProps}>
              {CardLayout ? <CardLayout data={item} /> : <>No Layout</>}
            </Grid>
          )
        })}

        {loading ? <CardsGroupSkeleton gridItemsProps={gridItemsProps} /> : <></>}
      </Grid>
      {showPagination && !loading ? (
        <>
          {useButtonPagination ? (
            <Button sx={{ my: 2 }} onClick={nextPage}>
              Mostrar más
            </Button>
          ) : (
            <ScrollDetector show={showPagination && !loading} onElementIsVisible={nextPage} />
          )}
        </>
      ) : (
        <></>
      )}
    </Box>
  )
}

export default CustomGrid

/**
 *
 * @typedef CardsGroupProps
 * @prop {Array<Object>} datasource
 * @prop {import('@mui/material').Grid2Props} gridProps
 * @prop {import('@mui/material').Grid2Props} gridItemsProps
 * @prop {number} [page]
 * @prop {number} [pageLimit]
 * @prop {number} [count]
 * @prop {boolean} [loading]
 * @prop {boolean} [useButtonPagination=false]
 * @prop {(number) => void} [onPageChanged]
 * @prop {(string) => void} [onSearchQueryChanged]
 * @prop {JSX.Element} [CardLayout]
 */
