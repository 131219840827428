import { Skeleton, Box } from '@mui/material'

export default function CorporateTreeSkeleton() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Skeleton height={35} width="350px" variant="rounded" sx={{ borderRadius: '20px' }} />
      <Skeleton height={35} width="350px" variant="rounded" sx={{ borderRadius: '20px' }} />
      <Skeleton height={35} width="350px" variant="rounded" sx={{ borderRadius: '20px' }} />
      <Skeleton height={35} width="350px" variant="rounded" sx={{ borderRadius: '20px' }} />
      <Skeleton height={35} width="350px" variant="rounded" sx={{ borderRadius: '20px' }} />
      <Skeleton height={35} width="350px" variant="rounded" sx={{ borderRadius: '20px' }} />
    </Box>
  )
}
