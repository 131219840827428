import { useEffect, useState } from 'react'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { Alert, Avatar, Button, Skeleton } from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import useFetchOrganigrama from '../hooks/useFetchOrganigrama'

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: '40px',
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}))

function StyledTreeItem(props) {
  const { bgColor, color, labelInfo, labelText, ...other } = props
  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', p: 0.5, pr: 0 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Typography variant="h6">{labelText}</Typography>
          </div>
          {labelInfo.map((employee, index) => (
            <div
              key={`${index}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                marginBottom: '8px',
              }}
            >
              <Avatar
                src={`/assets/images/${employee.imagen}`}
                sx={{
                  transition: '.3s',
                  backgroundColor: 'secondary.main',
                  aspectRatio: '4/4',
                  width: '30px',
                  height: '30px',
                  mr: 2,
                  color: 'secondary.contrastText',
                }}
              />
              <Typography variant="body1">
                {`${employee.nombre} ${employee.primer_apellido} ${employee.segundo_apellido}`}
              </Typography>
            </div>
          ))}
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  )
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelInfo: PropTypes.array,
  labelText: PropTypes.string.isRequired,
}

const getStructureIds = (nodes) => {
  let ids = []
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    ids.push(node.id.toString())
    if (node.hijos.length > 0) {
      const children = getStructureIds(node.hijos)
      ids = ids.concat(children)
    }
  }
  return ids
}

const Estructura = () => {
  const location = useLocation()
  const { response, loading: isLoading, error } = useFetchOrganigrama(location?.state?.user ?? null)
  const [organigrama, setOrganigrama] = useState(response)
  const [loading, setLoading] = useState(isLoading)
  const [structureItems, setStructureItems] = useState([])
  const [expandedItems, setExpandedItems] = useState([])

  useEffect(() => {
    setOrganigrama(response)
  }, [response])

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    let itemIds = []
    if (organigrama) {
      for (let i = 0; i < organigrama.length; i++) {
        const node = organigrama[i]
        itemIds.push(node.id.toString())
        if (node.hijos.length > 0) {
          const children = getStructureIds(node.hijos)
          itemIds = itemIds.concat(children)
        }
      }
    }
    setExpandedItems(itemIds)
    setStructureItems(itemIds)
  }, [organigrama])

  const nodos = (datos, iniciar = false) => {
    const listaNodos = datos.map((rama, idx) => {
      return rama.hijos ? (
        <StyledTreeItem
          itemId={rama.id.toString()}
          key={`id_${idx}${rama.id.toString()}`}
          labelText={rama.descripcion}
          labelInfo={rama.empleados}
        >
          {nodos(rama.hijos, false)}
        </StyledTreeItem>
      ) : (
        <StyledTreeItem
          itemId={rama.id.toString()}
          key={`id_${idx}${rama.id.toString()}`}
          labelText={rama.descripcion}
          labelInfo={rama.empleados}
        />
      )
    })
    return listaNodos
  }

  const arbolOrga =
    organigrama && organigrama.length > 0 ? (
      nodos(organigrama, true)
    ) : (
      <>
        {loading ? (
          <SimpleTreeView
            aria-label="organigrama_skeleton"
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            expandedItems={['1', '2']}
          >
            <TreeItem
              itemId="1"
              label={<Skeleton height={50} width={300 * (1.5 + Math.random(0, 1))} />}
            >
              <TreeItem
                itemId="11"
                label={<Skeleton height={50} width={300 * (1.5 + Math.random())} />}
              />
              <TreeItem
                itemId="12"
                label={<Skeleton height={50} width={300 * (1.5 + Math.random())} />}
              />
              <TreeItem
                itemId="13"
                label={<Skeleton height={50} width={300 * (1.5 + Math.random())} />}
              />
            </TreeItem>
            <TreeItem
              itemId="2"
              label={<Skeleton height={50} width={300 * (1.5 + Math.random())} />}
            >
              <TreeItem
                itemId="21"
                label={<Skeleton height={50} width={300 * (1.5 + Math.random())} />}
              />
              <TreeItem
                itemId="22"
                label={<Skeleton height={50} width={300 * (1.5 + Math.random())} />}
              />
            </TreeItem>
          </SimpleTreeView>
        ) : (
          <>
            {error ? (
              <Alert severity="warning">
                No hemos podido obtener el organigrama para este usuario. Puede que se encuentre mal
                parametrizado
              </Alert>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    )

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds)
  }

  const handleExpandClick = () => {
    setExpandedItems((oldExpanded) => (oldExpanded.length === 0 ? structureItems : []))
  }

  return (
    <Box>
      <Button onClick={handleExpandClick}>
        {expandedItems.length === 0 ? 'Expandir Todo' : 'Colapsar Todo'}
      </Button>
      <SimpleTreeView
        aria-label="organigrama"
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        expandedItems={expandedItems}
        onExpandedItemsChange={handleExpandedItemsChange}
      >
        {arbolOrga}
      </SimpleTreeView>
    </Box>
  )
}

export default Estructura
