import { DarkMode, LightMode } from '@mui/icons-material'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Switch,
} from '@mui/material'
import { useState } from 'react'
import ThemeManager from 'theming/ThemeManager'

const GeneralSettings = ({}) => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light')
  const [collapseMenu, setCollapseMenu] = useState(
    localStorage.getItem('NO_EXPAND_MENU') || 'false',
  )
  const [width, setWidth] = useState(localStorage.getItem('width') || 'full')
  const [tema, setTema] = useState(localStorage.getItem('tema') || 'default')

  const applyChanges = () => {
    localStorage.setItem('theme', theme)
    localStorage.setItem('NO_EXPAND_MENU', collapseMenu)
    localStorage.setItem('width', width)
    localStorage.setItem('tema', tema)
    window.location.reload()
  }

  const themeController = new ThemeManager()

  return (
    <>
      <h1 style={{ marginBottom: '1em' }}>Ajustes Generales</h1>
      {/* <Divider textAlign="right" sx={{ my: 2 }}> */}
      {/*   Aspecto */}
      {/* </Divider> */}
      <Grid spacing={2} container>
        {themeController.hide?.Options?.General?.Color ? (
          <></>
        ) : (
          <Grid xs={12} item>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Tema</InputLabel>
              <Select
                sx={{ '.MuiSelect-select': { display: 'flex !important', alignItems: 'center' } }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={theme}
                label="Tema"
                onChange={(e) => setTheme(e.target.value)}
              >
                <MenuItem value={'light'}>
                  <ListItemIcon>
                    <LightMode fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Modo Claro</ListItemText>
                </MenuItem>
                <MenuItem value={'dark'}>
                  <ListItemIcon>
                    <DarkMode fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Modo Oscuro</ListItemText>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {themeController?.hide?.Options?.General?.MenuCollapse ? (
          <></>
        ) : (
          <Grid xs={12} item>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Ancho</InputLabel>
              <Select
                sx={{ '.MuiSelect-select': { display: 'flex !important', alignItems: 'center' } }}
                labelId="mode-selector-label"
                id="mode-selector"
                value={width}
                label="Modo"
                onChange={(e) => setWidth(e.target.value)}
              >
                <MenuItem value={'full'}>
                  <ListItemIcon>
                    <FullscreenIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Ancho completo</ListItemText>
                </MenuItem>
                <MenuItem value={'contained'}>
                  <ListItemIcon>
                    <FullscreenExitIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Modo contenedor</ListItemText>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid xs={12} item>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Tema</InputLabel>
            <Select
              sx={{ '.MuiSelect-select': { display: 'flex !important', alignItems: 'center' } }}
              labelId="tema-selector-label"
              id="tema-selector"
              value={tema}
              label="Tema"
              onChange={(e) => setTema(e.target.value)}
            >
              <MenuItem value={'default'}>
                <ListItemIcon>
                  <FullscreenIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Defecto</ListItemText>
              </MenuItem>
              <MenuItem value={'edisa'}>
                <ListItemIcon>
                  <FullscreenExitIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edisa</ListItemText>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {themeController?.hide?.Options?.General?.MenuCollapse ? (
          <></>
        ) : (
          <Grid xs={12} item>
            <FormControlLabel
              control={
                <Switch
                  checked={collapseMenu == 'true'}
                  onChange={(event) => setCollapseMenu(event.target.checked ? 'true' : 'false')}
                />
              }
              label="Colapsar el menú automáticamente"
            />
          </Grid>
        )}
      </Grid>
      <Grid container sx={{ mt: 1 }} spacing={1}>
        <Grid xs={12} lg={6} item>
          <Button
            sx={{ width: '100%' }}
            onClick={applyChanges}
            variant="contained"
            disableElevation
          >
            Aplicar Cambios
          </Button>
        </Grid>
        <Grid xs={12} lg={6} item>
          <Button sx={{ width: '100%' }} variant="contained" color="warning" disableElevation>
            Cancelar Cambios
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default GeneralSettings
