import { Dark, Light } from './pallete'
import { esES } from '@mui/material/locale'
/**
 * @returns {EdisaTheme}
 */
const getActivePallete = () => {
  const storedTheme = localStorage.getItem('theme') || 'light'

  const pallete = storedTheme === 'light' ? Light : Dark
  return pallete
}

const pallete = getActivePallete()

/**
 * @type {ThemeOptions}
 */
const options = {
  fullHideMenu: false,
  // TODO: Revisar que mierda es esto
  apply: true,
  cssUrls: [
    'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
    '/assets/resources/personalizaciones.css',
  ],
  pageTitleOptions: {
    excludeElements: ['home'],
    excludeActualMatchElements: ['solicitud'],
  },
  SimpleTable: {
    container_style: {
      backgroundColor: pallete.background.paper,
      borderRadius: '30px',
      padding: '16px 24px',
    },
    table_style: {
      borderCollapse: 'separate',
      borderSpacing: '0px 8px',
      tableLayout: 'initial',
    },
    row_style: {
      backgroundColor: '#fff',
      borderRadius: '15px',
      clipPath: 'margin-box',
    },
  },
  Table: {
    forceReplaceOldTables: true,
  },
  WorkerData: {
    subpageSelectors: 'pills',
  },
  locale: esES,
  ListaSolicitudes: {
    presentation: 'grid',
  },
  newsOptions: {
    firstNewsBanner: false,
  },
  documentacionOptions: {
    spacing: 2,
  },
}

export default options
