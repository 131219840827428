import { Chip } from '@mui/material'
import { Box } from '@mui/system'

/**
 * Construye el elemento visual que se mostrará en el estado de una solicitud
 * @param {'number'} status - Status de la solicitud
 * @param {'string'} d_status - Descripción del status
 * @param {import('@mui/material').SxProps<import('@mui/system').Theme>} sx - Descripción del status
 * @param {import('@mui/material').SxProps<import('@mui/system').Theme>} sxBox - Descripción del status
 * @returns {import("@mui/material").ChipClasses}
 */
export const getChipSolicitud = (status, d_status, sx = {}, sxBox = {}) => {
  let color = 'warning'
  if (status === 200 || status === 201) {
    color = 'primary'
  } else if (status === 500 || status % 2 === 0) {
    color = 'success'
    if (!d_status) {
      d_status = 'Aprobada parcial'
    }
  } else if (status === 600 || status % 2 === 1) {
    color = 'error'
    if (!d_status) {
      d_status = 'Rechazada'
    }
  }

  return (
    <Box sx={{ p: '0px', display: 'flex', alignItems: 'center', ...sxBox }}>
      <Chip label={d_status} color={color} size="small" sx={{ ...sx }} />
    </Box>
  )
}

/**
 * Construye el elemento visual que se mostrará en el estado de una solicitud
 * @param {'number'} status - Status de la solicitud
 * @param {'string'} d_status - Descripción del status
 * @returns {import("@mui/material").ChipClasses}
 */
export const getPropsBadgeSolicitud = (status, d_status) => {
  let color = 'warning'
  if (status === 200 || status === 201) {
    color = 'primary'
  } else if (status === 500 || status % 2 === 0) {
    color = 'success'
    if (!d_status) {
      d_status = 'Aprobada parcial'
    }
  } else if (status === 600 || status % 2 === 1) {
    color = 'error'
    if (!d_status) {
      d_status = 'Rechazada'
    }
  }
  return {
    badgeContent: d_status,
    color,
  }
}

/**
 * Gets the current form input values
 * @param {'array'} datos - Current data array in state
 * @param {'array'} campos - Current field array in state
 * @returns {'array'}
 */
export const getValoresConsulta = (datos, campos) => {
  const valores = []
  for (const idpk in datos) {
    if (Object.hasOwnProperty.call(datos, idpk)) {
      const valor = datos[idpk]
      const field = campos.filter((c) => Number(c.idpk) === Number(idpk))[0]
      valores.push({ codigo_campo: field.codigo_campo, valor })
    }
  }
  return valores
}
