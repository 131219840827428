import { lazy as _lazy } from 'react'

function lazy(importFn) {
  return _lazy(async () => {
    const m = await importFn()
    return { default: m.ReactComponent }
  })
}

const icons = {
  DocOutline: lazy(async () => import('icons/assets/svgs/teenyicons_doc-outline.svg')),
  PptOutline: lazy(async () => import('icons/assets/svgs/teenyicons_ppt-outline.svg')),
  XlsOutline: lazy(async () => import('icons/assets/svgs/teenyicons_xls-outline.svg')),
  PdfOutline: lazy(async () => import('icons/assets/svgs/teenyicons_pdf-outline.svg')),
  PngOutline: lazy(async () => import('icons/assets/svgs/teenyicons_png-outline.svg')),
  JpgOutline: lazy(async () => import('icons/assets/svgs/teenyicons_jpg-outline.svg')),
  TextAltOutline: lazy(
    async () => import('icons/assets/svgs/teenyicons_text-document-alt-outline.svg'),
  ),
}

export default icons
