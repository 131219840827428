/**
 * @module CommonComponents
 */

import { Search } from '@mui/icons-material'
import { Button, TablePagination, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import useSyncedScroll from 'common/hooks/useSyncScroll'
import { useState, useEffect, useMemo, useRef } from 'react'
import ThemeManager from 'theming/ThemeManager'
import SimpleTable from './SimpleTable'
import TableSkeleton from './TableSkeleton.jsx'
import { TableVariants } from 'common/libs/TableVariants'
import Icon from 'icons/Icons'

/**
 * A generic table based on MUI DataGrid but for simple use
 * @param {TableProps} props
 */
const Table = ({
  dataSource,
  fields,
  onRowClick,
  onPageChanged,
  page,
  count,
  rowsPerPage = 12,
  header_style = {},
  header_cell_style = {},
  generic_cell_style = {},
  onSearchQueryChanged = undefined,
  onRowsPerPageChanged = undefined,
  table_style = {},
  container_style = {},
  body_style = {},
  hideHeader = false,
  loading = false,
  debug = false,
  stripped = false,
  emptyText = 'No hay datos',
  useSimpleTable = false,
  showInternalSearch = false,
  ignoreOldTable = false,
  variant = 'default',
}) => {
  const Theme = new ThemeManager()

  const replaceOldTable = Theme?.options?.Table?.forceReplaceOldTables ?? useSimpleTable ?? true
  /**@type {import('common/types/table_variants.types').TableVariant}*/
  const variantValues = TableVariants[variant]

  const headerRef = useRef(null)
  const contentRef = useRef(null)
  const [queryString, setQueryString] = useState('')
  const [searchValue, setSearchValue] = useState('')

  useSyncedScroll(headerRef, contentRef)

  const gridTemplateColumns = useMemo(() => {
    let newTemplate = ''
    if (!fields) return ''
    fields.forEach((field) => {
      newTemplate = `${newTemplate} minmax(${field.forceWidth ? field.cellWidth : '100px'}, ${
        field.cellWidth || '1fr'
      })`
        .trimEnd()
        .trimStart()
    })
    return newTemplate
  }, [fields])

  const [internalPage, setInternalPage] = useState(page)

  useEffect(() => {
    setInternalPage(1)
  }, [rowsPerPage])

  const start = useMemo(() => {
    return (internalPage - 1) * rowsPerPage
  }, [internalPage, rowsPerPage])

  const end = useMemo(() => start + rowsPerPage, [start, rowsPerPage])

  const itemsToRender = useMemo(() => {
    /**@type {Array<Object>}*/
    let newVal = dataSource
    if (queryString) {
      newVal = newVal.reduce((previousArray, currentValue) => {
        const newArray = [...previousArray]
        const values = Object.values(currentValue).flat()

        for (let i = 0; i < values?.length; i++) {
          if (values[i]?.toString()?.toLowerCase()?.includes(queryString?.toLowerCase())) {
            newArray.push(currentValue)
            break
          }
        }

        return newArray
      }, [])
      console.table({ newVal })
    }

    if (!newVal?.[0]) return []
    newVal = newVal?.slice(start, end)
    return newVal
  }, [dataSource, end, start, queryString])

  /** @type {import('@mui/material').SxProps<import('@mui/system').Theme>}*/
  const commonSx = {
    paddingX: 1,
    paddingY: 1,
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: gridTemplateColumns,
    overflowX: 'auto',
    ...variantValues.commonSx,
  }

  /** @type {import('@mui/material').SxProps<import('@mui/system').Theme>}*/
  const commonCellSx = {
    py: 2,
    px: 2,
    mt: 2,
    alignContent: 'center',
    ...(variantValues?.commonCellSx ?? {}),
    [`&:nth-child(${fields.length}n+1)`]: {
      borderTopLeftRadius: 'var(--table-border-radius)',
      borderBottomLeftRadius: 'var(--table-border-radius)',
      ml: 'var(--table-cells-border-margin)',
    },
    [`&:nth-child(${fields.length}n+${fields.length})`]: {
      mr: 'var(--table-cells-border-margin)',
      borderTopRightRadius: 'var(--table-border-radius)',
      borderBottomRightRadius: 'var(--table-border-radius)',
    },
  }

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null)
  const handleQueryString = (value) => {
    if (!onSearchQueryChanged && showInternalSearch) return setQueryString(value)
    setInternalPage(1)
    onSearchQueryChanged()
  }

  return (
    <Box
      sx={{
        display: 'block',
        height: 'fit-content',
        width: '100%',
        p: 0,
        my: 2,
        ...(variantValues?.containerSx ?? {}),
      }}
      className="common-table"
    >
      {showInternalSearch || onSearchQueryChanged ? (
        <Box
          className="common-table-search-box"
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            mb: 2,
            ...(variantValues?.searchBoxSx ?? {}),
          }}
        >
          <Box></Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <TextField
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleQueryString(searchValue)
                }
              }}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value ?? '')
              }}
              id="input-with-icon-textfield"
              placeHolder="Buscar"
              InputProps={{
                placeholder: 'Buscar',
              }}
              variant="standard"
            />
            <Button
              sx={{ px: 2, ml: 1 }}
              startIcon={<Icon size="1rem" icon="fi-rr-search" />}
              onClick={() => {
                handleQueryString(searchValue)
              }}
            >
              Buscar
            </Button>
          </Box>
        </Box>
      ) : (
        <></>
      )}
      {hideHeader || (!ignoreOldTable && !replaceOldTable) ? (
        ''
      ) : (
        <Box ref={headerRef} sx={{ ...commonSx, overflowX: 'hidden' }}>
          {fields?.map((item, index) => {
            return (
              <Box
                sx={{
                  ...commonCellSx,
                  mt: 'initial',
                  ...(variantValues?.headerCellSx ?? {}),
                }}
                key={index}
              >
                <Typography fontWeight="600">{item.title}</Typography>
              </Box>
            )
          }) ?? (
            <>
              {itemsToRender?.length <= 0 && !loading ? (
                <Box sx={{ width: '100%', py: 4 }}>
                  <Typography variant="h3" sx={{ opacity: '0.3' }}>
                    No hay elementos
                  </Typography>
                </Box>
              ) : (
                ''
              )}
            </>
          )}
        </Box>
      )}
      {loading ? (
        <TableSkeleton />
      ) : (
        <>
          {!ignoreOldTable && !replaceOldTable ? (
            <SimpleTable
              fields={fields}
              dataSource={itemsToRender}
              onRowClick={onRowClick}
              header_style={header_style}
              header_cell_style={header_cell_style}
              generic_cell_style={generic_cell_style}
              table_style={table_style}
              container_style={container_style}
              body_style={body_style}
              hideHeader={hideHeader}
              loading={loading}
              debug={debug}
              stripped={stripped}
              emptyText={emptyText}
            />
          ) : (
            <Box
              ref={contentRef}
              sx={{
                ...commonSx,
                backgroundColor: 'background.paper',
                borderRadius: 5,
                pt: 2,
                pb: 3,
                display: itemsToRender?.length <= 0 ? 'none' : 'grid',
                ...(variantValues?.bodyStyle ?? {}),
              }}
            >
              {itemsToRender?.map((row, rowIndex) => {
                return fields?.map((field, index) => {
                  return (
                    <Box
                      onMouseEnter={() => setHoveredRowIndex(rowIndex)} // Set hovered row
                      onMouseLeave={() => {
                        if (hoveredRowIndex === rowIndex) setHoveredRowIndex(null)
                      }}
                      sx={{
                        transition: '.3s',
                        backgroundColor:
                          hoveredRowIndex === rowIndex
                            ? 'background.success'
                            : 'background.default', // Change background on hover
                        color: 'text.primary',
                        cursor: onRowClick ? 'pointer' : 'initial',
                        ...commonCellSx,
                        ...(variantValues?.bodyCellSx ?? {}),
                      }}
                      key={`field${index}`}
                      onClick={() => {
                        if (onRowClick) onRowClick({ row })
                      }}
                    >
                      <Typography>
                        {field.customGetter ? field.customGetter(row) : row[field.field]}
                      </Typography>
                    </Box>
                  )
                })
              }) ?? <></>}
            </Box>
          )}
          {count > itemsToRender?.length ? (
            <TablePagination
              onPageChange={(e, newPage) => {
                setInternalPage(newPage + 1)
                if (onPageChanged) {
                  onPageChanged(newPage + 1)
                }
              }}
              component="div"
              count={queryString && !onSearchQueryChanged ? itemsToRender?.length : count}
              page={internalPage - 1}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[12, 24, 36, 48]}
              onRowsPerPageChange={(a) => {
                if (onRowsPerPageChanged) onRowsPerPageChanged(a?.target?.value ?? 12)
              }}
            />
          ) : (
            ''
          )}
        </>
      )}
    </Box>
  )
}

export default Table
