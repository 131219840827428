import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Link } from 'react-router-dom'

const ParentButton = ({ rel, to, target, current_menu, Icono, small }) => {
  return (
    <ListItemButton
      key={current_menu.id}
      id={current_menu.id}
      component={Link}
      sx={{
        p: '4px',
        borderTopLeftRadius: '15px',
        borderBottomLeftRadius: '15px',
        pl: 2,
        pr: 1,
        ml: 1,
        fontWeight: '300',
      }}
      to={to}
      target={target}
      rel={rel}
    >
      <ListItemIcon>
        {Icono ? <Icono sx={{ color: 'background.menuContrast' }} /> : null}
      </ListItemIcon>
      {!small ? <ListItemText primary={current_menu.nombre} /> : ''}
    </ListItemButton>
  )
}

export default ParentButton
