import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import { Box } from '@mui/system'
import IconsList from 'common/constants/icons_map'
import { selectMenus } from 'features/portal/menusSlice'
import { Icon } from 'icons/Icons'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'
import UserImage from '../../../components/UserImage'
import DropRightButton from './DropRightButton'
import ParentButton from './ParentButton'

const MenuDrawerDefault = ({
  expanded,
  ALLWAYS_EXPANDED: EXPANDED,
  mobileOpen,
  open,
  handleClick,
  small: smallParent,
}) => {
  const menus = useSelector(selectMenus)

  const themeManager = new ThemeManager()

  const apply = themeManager.options.apply ?? false

  const [ALLWAYS_EXPANDED, set_ALLWAYS_EXPANDED] = useState(EXPANDED)
  const [small, setSmall] = useState(smallParent)

  return (
    <Box
      sx={{
        height: '100dvh',
        backgroundColor: {
          xs: 'transparent',
          md: 'transparent',
        },
        p: {
          xs: 0,
          md: !ALLWAYS_EXPANDED || mobileOpen ? 0 : apply ? 0 : 1,
        },
        position: 'relative',
      }}
    >
      <Box sx={{ marginX: 'auto' }}>
        <UserImage
          expanded={ALLWAYS_EXPANDED || expanded}
          boxSx={{
            transition: '.3s ease-in',
            width: small ? '60px' : '100px',
            marginX: 'auto',
            marginBottom: 2,
            display: {
              xs: 'initial',
              sm: 'initial',
            },
            position: 'absolute',
            top: small ? '4%' : '2%',
            left: small ? '11%' : '27%',
            zIndex: 2,
          }}
        />
      </Box>
      <Paper
        className="menu"
        sx={{
          height: '93%',
          transition: '.3s',
          width: '100%',
          borderRadius: ALLWAYS_EXPANDED && !apply ? 1 : '',
          backgroundColor: 'menu.background',
          color: 'background.menuContrast',
          position: 'relative',
          top: '7vh',
          paddingTop: '120px',
          paddingLeft: '5px',
          overflow: 'inherit',
          scrollbarWidth: 'none',
        }}
      >
        <List
          sx={{
            transition: '.3s',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            '& > *': {
              flexGrow: '0 !important',
              '&:last-child': {
                marginTop: 'auto',
              },
            },
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <div key="menu-ppal">
            {menus.map((current_menu) => {
              if (current_menu.visible !== 'S') return null
              const Icono = current_menu?.icono ? IconsList[current_menu.icono] : null
              const to =
                current_menu.programa === 'url'
                  ? current_menu.camino
                  : `/${current_menu.camino ? current_menu.camino : current_menu.programa}`
              const target = current_menu.programa === 'url' ? '_blank' : null
              const rel = current_menu.programa === 'url' ? 'noopener' : null

              if (current_menu.programa) {
                return (
                  <>
                    <ParentButton
                      expanded={expanded}
                      current_menu={current_menu}
                      to={to}
                      Icono={Icono}
                      target={target}
                      rel={rel}
                      small={small}
                    />
                  </>
                )
              } else if (current_menu.hijos && current_menu.hijos.length > 0) {
                return (
                  <React.Fragment key={current_menu.id}>
                    <DropRightButton
                      expanded={expanded}
                      current_menu={current_menu}
                      handleClick={handleClick}
                      Icono={Icono}
                      open={open}
                      small={small}
                    />
                  </React.Fragment>
                )
              }
              return null
            })}
          </div>
          <div key="menu-sec">
            {
              <ListItemButton
                sx={{ p: '4px', pl: small ? 3 : 2, pr: 1 }}
                id={99999}
                className="menu-list-item"
                onClick={() => {
                  localStorage.setItem('NO_EXPAND_MENU', ALLWAYS_EXPANDED ? 'false' : 'true')
                  set_ALLWAYS_EXPANDED(!ALLWAYS_EXPANDED)
                  setSmall(!small)
                }}
              >
                <ListItemIcon sx={{ minWidth: small ? '36px' : '56px' }}>
                  {small ? (
                    <Icon icon="ChevronRightIcon" sx={{ color: 'background.menuContrast' }} />
                  ) : (
                    <Icon icon="ChevronLeftIcon" sx={{ color: 'background.menuContrast' }} />
                  )}
                </ListItemIcon>
                {!small ? <ListItemText primary={'Cerrar menú'} /> : <></>}
              </ListItemButton>
            }
            <ListItemButton
              sx={{ p: '4px', pl: small ? 3 : 2, pr: 1 }}
              id={99999}
              component={Link}
              className="menu-list-item"
              to="/ajustes"
            >
              <ListItemIcon sx={{ minWidth: small ? '36px' : '56px' }}>
                <Icon icon="Settings" sx={{ color: 'background.menuContrast' }} />
              </ListItemIcon>
              {small ? '' : <ListItemText primary={'Ajustes'} />}
            </ListItemButton>
            <ListItemButton
              sx={{ p: '4px', pl: small ? 3 : 2, pr: 1 }}
              id={99999}
              className="menu-list-item"
              to="/logout"
            >
              <ListItemIcon sx={{ minWidth: small ? '36px' : '56px' }}>
                <Icon icon="LogoutIcon" sx={{ color: 'background.menuContrast' }} />
              </ListItemIcon>
              {small ? '' : <ListItemText primary={'Logout'} />}
            </ListItemButton>
          </div>
        </List>
      </Paper>
    </Box>
  )
}

export default MenuDrawerDefault
