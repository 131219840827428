import { Box, CircularProgress } from '@mui/material'
import back from 'assets/images/wallpaper.login.png'

const LoginBackground = ({ children, loading = true }) => {
  const imageBg = {
    backgroundColor: 'background.default',
    backgroundImage: `url(${back})`,
    // background: `linear-gradient(rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.40)), url(${back}) no-repeat`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }
  const white = {
    backgroundColor: 'background.default',
  }
  const bg = loading ? white : imageBg

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <Box
          sx={{
            width: {
              xs: '100%',
              md: '55%',
            },
            height: {
              xs: '30%',
              md: '100%',
            },
            backgroundColor: 'white',
          }}
        />
        <Box
          sx={{
            width: {
              xs: '100%',
              md: '45%',
            },
            height: {
              xs: '70%',
              md: '100%',
            },
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            ...bg,
          }}
        />
      </Box>
      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
          mx: {
            xs: 'auto',
            md: 10,
          },
          mt: {
            xs: 2,
            md: 5,
          },
          width: {
            xs: '90%',
            md: '60%',
          },
          height: '100%',
          minWidth: {
            xs: 'auto',
            md: '380px',
          },
          display: 'flex',
          flexDirection: 'column',
          // alignItems: {
          //   xs: 'center',
          //   md: 'flex-start',
          // },
          placeItems: 'center',
          alignItems: 'center',
          placeContent: 'center',
        }}
      >
        <>{loading ? <CircularProgress /> : <>{children}</>}</>
      </Box>
    </Box>
  )
}

export default LoginBackground
