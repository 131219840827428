import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import TaskIcon from '@mui/icons-material/Task'
import { Alert, Autocomplete, Snackbar, SpeedDial, SpeedDialAction, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import useFetchDocs from '../hooks/useFetchDocs'
import ActionsMenu from './ActionsMenu'
import './css/ListadoDocumentos.css'
import DocumentDialogs from './DocumentDialogs'
import DocumentSkeleton from './DocumentListSkeleton'

import SearchIcon from '@mui/icons-material/Search'

const defaultErrorState = {
  error: false,
  code: 0,
  msg: '',
}

const ListadoDocumentos = () => {
  const [trigger, setTrigger] = useState(null)
  const { response, loading } = useFetchDocs(trigger, setTrigger)
  const [ficheros, setFicheros] = useState(response)
  const [ficherosBuscador, setFicherosBuscador] = useState({
    labe: String,
    id: Number,
  })

  const [filtro, setFiltro] = useState('')

  const [modalOpen, setModalOpen] = useState(false)
  const [action, setAction] = useState('')
  const [modalComentariosOpen, setModalComentariosOpen] = useState(false)
  const [selectedDoc, setSelectedDoc] = useState(-1)
  const [errorStatus, setErrorStatus] = useState(defaultErrorState)
  const [busqueda, setBusqueda] = useState('')
  const [ficherosTemp, setFicherosTemp] = useState(response)

  const [openFilter, setOpenFilter] = useState(false)
  const handleOpenFilter = () => setOpenFilter(true)
  const handleCloseFilter = () => setOpenFilter(false)

  useEffect(() => {
    setFicheros(response)
    let ficherosTemp = []
    response.map((item, index) => {
      let nombre_fichero = item.nombre_fichero.split('.')[0]
      ficherosTemp.push({
        label: item.id_documento.split('-')[1] + ' ' + nombre_fichero.replaceAll('_', ' '),
        id: index,
      })
    })
    setFicherosBuscador(ficherosTemp)
    setFicherosTemp(response)
  }, [response])

  useEffect(() => {
    if (busqueda.trim() === '' || busqueda === undefined) {
      if (filtro !== '') {
        handleSearchFilter()
      } else {
        setFicheros(ficherosTemp)
      }
    } else {
      if (filtro !== '') {
        let ficherosFiltr = ficherosTemp.filter((fichero) =>
          (fichero.id_documento.split('-')[1] + ' ' + fichero.nombre_fichero.replaceAll('_', ' '))
            .toLocaleLowerCase()
            .includes(busqueda.toLocaleLowerCase()),
        )
        handleSearchFilter(ficherosFiltr)
      } else {
        setFicheros(
          ficherosTemp.filter((fichero) =>
            (fichero.id_documento.split('-')[1] + ' ' + fichero.nombre_fichero.replaceAll('_', ' '))
              .toLocaleLowerCase()
              .includes(busqueda.toLocaleLowerCase()),
          ),
        )
      }
    }
  }, [busqueda])

  useEffect(() => {
    handleSearchFilter()
  }, [filtro])

  const handleSearchFilter = (ficheros) => {
    if (filtro === 'todos') {
      setFicheros(ficheros !== undefined ? ficheros : ficherosTemp)
    } else if (filtro === 'firmados') {
      setFicheros(
        ficheros !== undefined
          ? ficheros.filter((fichero) => fichero.puede_firmar === 'N')
          : ficherosTemp.filter((fichero) => fichero.puede_firmar === 'N'),
      )
    } else if (filtro === 'sinfirmar') {
      setFicheros(
        ficheros !== undefined
          ? ficheros.filter((fichero) => fichero.puede_firmar === 'S')
          : ficherosTemp.filter((fichero) => fichero.puede_firmar === 'S'),
      )
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setErrorStatus(defaultErrorState)
  }

  return (
    <>
      <div className="gestion-documentos-buscador">
        <Autocomplete
          freeSolo
          onInputChange={(event, newInputValue) => {
            setBusqueda(newInputValue)
          }}
          id="controllable-states-demo"
          options={ficherosBuscador}
          sx={{
            width: {
              xs: '100%',
              sm: '80%',
              md: '50%',
              lg: '40%',
              xl: '40%',
            },
            margin: 'auto',
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                <p className="gestion-documentos-buscador-label">
                  <SearchIcon /> Buscar documento
                </p>
              }
            />
          )}
        />
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: 'absolute', bottom: 30, right: 30 }}
          icon={<FilterAltOutlinedIcon />}
          onClose={handleCloseFilter}
          onOpen={handleOpenFilter}
        >
          <SpeedDialAction
            key={action.name}
            icon={<TaskIcon />}
            tooltipTitle={'Firmados'}
            tooltipOpen
            onClick={() => {
              setFiltro('firmados')
            }}
          />
          <SpeedDialAction
            key={action.name}
            icon={<InsertDriveFileIcon />}
            tooltipTitle={<p>Sin&nbsp;firmar</p>}
            tooltipOpen
            onClick={() => {
              setFiltro('sinfirmar')
            }}
          />
          <SpeedDialAction
            key={action.name}
            icon={<ContentCopyOutlinedIcon />}
            tooltipTitle={'Todos'}
            tooltipOpen
            onClick={() => {
              setFiltro('todos')
            }}
          />
        </SpeedDial>
      </div>
      {!loading &&
      ficheros !== undefined &&
      typeof ficheros === 'object' &&
      (ficheros.length > 0 || ficherosTemp.length > 0) ? (
        <>
          <Grid container spacing={2}>
            {ficheros.map((item, index) => (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
                <Card
                  key={index}
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {item.puede_firmar === 'S' ? (
                    <p className="document-status-label-sin">Sin firmar</p>
                  ) : (
                    <p className="document-status-label-con">Firmado</p>
                  )}

                  <CardHeader
                    title={item.nombre_fichero.replaceAll('_', ' ').split('.')[0]}
                    subheader={
                      <div className="card-subheader">
                        <p className="card-subheader-number">{item.id_documento.split('-')[1]}</p>
                        <p className="card-subheader-date">
                          {new Date(item.fecha_documento).toLocaleString('es-ES').split(',')[0]}
                        </p>
                      </div>
                    }
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    {item.informacion_cabecera_documento_list.map((literal, index) => (
                      <Typography variant="body2" color="text.secondary" key={index}>
                        <span style={{ fontWeight: 'bold' }}>{literal.literal}</span>
                        <br />
                        {literal.texto}
                      </Typography>
                    ))}
                  </CardContent>

                  <CardActions disableSpacing={true}>
                    <div style={{ marginLeft: 'auto' }}>
                      <ActionsMenu
                        puedeFirmar={item.puede_firmar}
                        docData={{
                          index: index,
                          id_archivo: item.id_archivo,
                          token_acceso: item.token_acceso,
                          mime_type: item.mime_type,
                        }}
                        setSelectedDoc={setSelectedDoc}
                        setModalOpen={setModalOpen}
                        setAction={setAction}
                        setModalComentariosOpen={setModalComentariosOpen}
                        setTrigger={setTrigger}
                      />
                    </div>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
          <DocumentDialogs
            setErrorStatus={setErrorStatus}
            docData={ficheros[selectedDoc]}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            action={action}
            modalComentariosOpen={modalComentariosOpen}
            setModalComentariosOpen={setModalComentariosOpen}
            setTrigger={setTrigger}
          />
          {errorStatus.error ? (
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={errorStatus.error}
              autoHideDuration={3000}
              onClose={handleClose}
            >
              <Alert
                variant="filled"
                onClose={handleClose}
                severity={errorStatus.code > 300 ? 'error' : 'success'}
                sx={{ width: '100%' }}
              >
                {errorStatus.msg}
              </Alert>
            </Snackbar>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <DocumentSkeleton spacing={2} />
        </>
      )}
    </>
  )
}

export default ListadoDocumentos
