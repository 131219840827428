/**
 * @module HojasGasto
 */
import axios from 'api/axios'
import { useCallback, useEffect, useState } from 'react'

/**
 * @module FetchHooks
 */

/**
 * Hook that fetch the my_users_data
 * {@link http://localhost:8000/api/v1/misusuarios}
 * @returns {import('common/types/connections_types').UseFetchHookResponse<import('common/types/connections_types').PaginatedResponse<Object>}
 */
const useFetchHojasGasto = ({ page, iterator = 0, page_size = 12 }) => {
  const [response, setResponse] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(() => {
    if (loading) {
      return
    }
    setLoading(true)
    axios
      .get(`/hojasgasto/?page=${page}&page_size=${page_size ?? 12}`)
      .then((request_response) => {
        const data = request_response.data
        setResponse(data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, iterator, page_size])

  useEffect(() => {
    if (!loading) fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, page, iterator])

  return { response, error, loading }
}

export default useFetchHojasGasto
