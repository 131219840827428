import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material'

const CamposSolicitudSkeleton = () => {
  return (
    <Box component="form" autoComplete="off">
      <Container>
        <Typography key="sol_ayu" variant="subtitle1" gutterBottom>
          <Skeleton key="s-descri" animation="wave" width="100%" />
        </Typography>
      </Container>
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 5 }}>
          {[...Array(5).keys()].map((index) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Skeleton variant="circular" height={30} width={30} />
                <Skeleton variant="rounded" height={20} width={100} />
              </Box>
            )
          })}
        </Box>
      </Container>

      <Container maxWidth="xl">
        <Card
          sx={{
            width: '100%',
          }}
        >
          <CardHeader title={<Skeleton animation="wave" width={350} height={40} />} sx={{ p: 5 }} />
          <CardContent sx={{ p: 5 }}>
            <Grid key="grid1" container spacing={3}>
              {[...Array(6).keys()].map((c) => {
                const numFields = 6
                return (
                  <Grid
                    item
                    key={`g-${c.idpk}`}
                    xs={12}
                    sm={numFields > 4 ? 4 : 12}
                    md={numFields > 1 && numFields <= 4 ? 6 : numFields > 4 ? 3 : 12}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Skeleton
                        animation="pulse"
                        height={20}
                        sx={{ width: `calc(100% * ${Math.random(1)})` }}
                      />
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={50}
                        sx={{ width: '100%' }}
                      />
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </CardContent>
        </Card>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Skeleton variant="rounded" height={50} width={200} sx={{ borderRadius: '1000px' }} />
          <Skeleton variant="rounded" height={50} width={200} sx={{ borderRadius: '1000px' }} />
        </Box>
      </Container>
    </Box>
  )
}

export default CamposSolicitudSkeleton
