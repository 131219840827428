import { Drawer } from '@mui/material'
import Box from '@mui/material/Box'
import { selectUsuario } from 'features/users/stores/userSlice'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'
import DefaultDrawer from './MenuDrawer'

const Menu = ({ window, mobileOpen, handleDrawerToggle, sx }) => {
  const ALLWAYS_EXPANDED = !(localStorage.getItem('NO_EXPAND_MENU') === 'true')
  const themeController = new ThemeManager()
  const location = useLocation()

  let MenuDrawer = DefaultDrawer

  if (
    themeController?.activeTheme?.components &&
    themeController.activeTheme.components['MenuDrawer']
  ) {
    MenuDrawer = themeController.activeTheme?.components
      ? themeController.activeTheme?.components['MenuDrawer'] ?? DefaultDrawer
      : DefaultDrawer
  }

  const [expanded, setExpanded] = React.useState(true)
  const [open, setOpen] = React.useState(0)

  useEffect(() => {
    setExpanded(false)
  }, [])

  useEffect(() => {
    setOpen(0)
  }, [location])

  const handleClick = (e) => {
    const el = Number(e.currentTarget.id)
    if (el === open) {
      setOpen(0)
    } else {
      setOpen(el)
    }
  }

  const usuario = useSelector(selectUsuario)

  const container = window !== undefined ? () => window().document.body : undefined

  if (!usuario) return <></>
  return (
    <Box
      sx={{
        position: ALLWAYS_EXPANDED ? 'relative' : 'relative',
        width: {
          xs: 0,
          md: 'auto',
          // md:
          //   ALLWAYS_EXPANDED || (ALLWAYS_EXPANDED === false && expanded)
          //     ? DRAWER_WIDTH
          //     : COLLAPSED_DRAWER_WIDTH,
        },
        display: 'flex',
        zIndex: 99,
        ...sx,
      }}
    >
      <Box
        component="nav"
        sx={{
          transition: '.3s',
          flexShrink: { sm: 0 },
          maxWidth: '100%',
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {
              xs: 'block',
              md: themeController.activeTheme.options.fullHideMenu ? 'initial' : 'none',
            },
            '& .MuiDrawer-paper': {
              backgroundColor: 'transparent',
              boxSizing: 'border-box',
              width: 'auto',
              border: 'none',
            },
          }}
        >
          <MenuDrawer
            mobile={true}
            expanded={expanded}
            DRAWER_WIDTH={'auto'}
            ALLWAYS_EXPANDED={ALLWAYS_EXPANDED}
            mobileOpen={mobileOpen}
            open={open}
            handleClick={handleClick}
            toggleDrawer={handleDrawerToggle}
          />
        </Drawer>
        {themeController.activeTheme.options['fullHideMenu'] ? (
          <></>
        ) : (
          <Box sx={{ display: { xs: 'none', md: 'initial' } }}>
            <MenuDrawer
              expanded={expanded}
              DRAWER_WIDTH={'auto'}
              ALLWAYS_EXPANDED={ALLWAYS_EXPANDED}
              mobileOpen={mobileOpen}
              open={open}
              handleClick={handleClick}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Menu
