import { Icon } from 'icons/Icons'

const IconsList = {
  home: (props) => <Icon icon="HomeOutlined" {...props} />,
  user: (props) => <Icon icon="PersonOutlineOutlined" {...props} />,
  logout: (props) => <Icon icon="LogoutOutlined" {...props} />,
  calendar: (props) => <Icon icon="CalendarMonthOutlined" {...props} />,
  teamcalendar: (props) => <Icon icon="PermContactCalendarOutlined" {...props} />,
  documentos: (props) => <Icon icon="SourceOutlined" {...props} />,
  nomina: (props) => <Icon icon="RequestPageOutlined" {...props} />,
  documento: (props) => <Icon icon="DescriptionOutlined" {...props} />,
  personal: (props) => <Icon icon="FolderSharedOutlined" {...props} />,
  comunicacion: (props) => <Icon icon="Campaign" {...props} />,
  article: (props) => <Icon icon="Article" {...props} />,
  editar: (props) => <Icon icon="Edit" {...props} />,
  noticias: (props) => <Icon icon="Newspaper" {...props} />,
  enlaces: (props) => <Icon icon="Link" {...props} />,
  preguntas: (props) => <Icon icon="QuizOutlined" {...props} />,
  organigrama: (props) => <Icon icon="AccountTree" {...props} />,
  users: (props) => <Icon icon="PeopleOutline" {...props} />,
  solicitudes: (props) => <Icon icon="AppsOutage" {...props} />,
  badge: (props) => <Icon icon="Badge" {...props} />,
  firmaFicheros: (props) => <Icon icon="FileCopyOutlined" {...props} />,
  fichajes: (props) => <Icon icon="Alarm" {...props} />,
  notificaciones: (props) => <Icon icon="NotificationsActiveOutlined" {...props} />,
  openinnew: (props) => <Icon icon="OpenInNew" {...props} />,
  search: (props) => <Icon icon="Search" {...props} />,
  key: (props) => <Icon icon="KeyRounded" {...props} />,
  localatm: (props) => <Icon icon="LocalAtm" {...props} />,
}

export default IconsList
