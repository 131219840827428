import { ArrowRightAlt, HourglassTop } from '@mui/icons-material'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import ArrowBack from '@mui/icons-material/ArrowBack'
import DoDisturb from '@mui/icons-material/DoDisturb'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Alert, Fade, Grid } from '@mui/material'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import { PREVIOUS_ROUTE_STORE } from 'api/axios'
import Logo from 'common/components/Logo'
import { NotificationsContext } from 'features/notifications/providers/NotificationsProvider'
import { userLogin } from 'features/users/stores/userSlice'
import { enqueueSnackbar } from 'notistack'
import { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'
import PasswordController from '../lib/PasswordController'

/**
 * Callback for adding two numbers.
 * a
 * @callback loadingParentCallback
 * @param {boolean} state - The loading state for the parent.
 */

/**
 * Formulario de inicio de sesión
 *
 * @param {Object} params - parametros del componente
 * @param {loadingParentCallback} [params.loadingParentCallback] - callback para cambiar el estado del componente
 *
 * @returns {React.ReactElement} - un componente para iniciar sesión
 */
const LoginForm = ({ loadingParentCallback = undefined }) => {
  const dispatch = useDispatch()
  const { forceRefresh } = useContext(NotificationsContext)
  const PREVIOUS_ROUTE = sessionStorage.getItem(PREVIOUS_ROUTE_STORE)

  const userRef = useRef()
  const passwordRef = useRef()

  const navigate = useNavigate()
  const [lockout, setLockout] = useState(false)
  const [step, setStep] = useState(1)
  const containerRef = useRef(null)

  useEffect(() => {
    // @ts-ignore
    if (userRef.current) userRef.current.focus()
  }, [])

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loginRequestStatus, setLoginRequestStatus] = useState('idle')

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (e) => e.preventDefault()

  const onUsernameChanged = (e) => {
    setUsername(e.target.value)
    if (lockout) setLockout(false)
  }
  const onPasswordChanged = (e) => setPassword(e.target.value)
  const [positionUsername, setPositionUsername] = useState('relative')
  const [positionPassword, setPositionPassword] = useState('absolute')
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (step === 1) {
      setPositionUsername('relative')
      setPositionPassword('absolute')
    }
    if (step === 2) {
      setPositionPassword('relative')
      setPositionUsername('absolute')
    }
  }, [step])

  const canLogin = [username].every(Boolean) && loginRequestStatus === 'idle'

  const checkLocked = async () => {
    setLoading(true)
    try {
      if (await PasswordController.hasToResetPassword(username)) {
        setLockout(true)
        enqueueSnackbar(
          'Es necesario que cambies tu contraseña, por favor verifica tu correo para continuar',
          {
            variant: 'error',
          },
        )
        setLoading(false)
        setErrorMessage('El usuario está bloqueado')
        return
      }
      setStep(2)
      setLockout(false)
    } catch (error) {
      setStep(2)
      setLockout(false)
    }
    setLoading(false)
  }

  const onLoginClicked = async () => {
    if (canLogin && !lockout) {
      try {
        setLoginRequestStatus('pending')

        // @ts-ignore
        await dispatch(userLogin({ username, password })).unwrap()
        if (loadingParentCallback) {
          loadingParentCallback(true)
        }

        setUsername('')
        setPassword('')
        forceRefresh()

        if (PREVIOUS_ROUTE) {
          sessionStorage.removeItem(PREVIOUS_ROUTE_STORE)
          navigate(PREVIOUS_ROUTE)
        } else navigate('/')
      } catch (err) {
        if (err.message) {
          setErrorMessage(err.message)
        } else {
          setErrorMessage('Usuario o contraseña incorrecto')
        }
        if (loadingParentCallback) {
          loadingParentCallback(false)
        }
      } finally {
        setLoginRequestStatus('idle')
      }
    }
  }
  const themeManager = new ThemeManager()

  const logoOutside = themeManager.options.loginLogoOutside ?? false
  const apply = themeManager.options.apply ?? false
  const colorLetra = themeManager.options.colorLetra ?? '#000000'

  return (
    <div className="inicio">
      {logoOutside ? (
        <Logo
          logoType="Large"
          sx={{
            mb: 5,
            transfor: 'translateX(-30%)',
          }}
        />
      ) : (
        <></>
      )}
      {apply ? (
        <>
          <Logo
            logoType="Large"
            sx={{
              m: {
                xs: 'auto',
                md: '10px 0px 50px 0px',
              },
              my: 5,
              width: {
                xs: '70%',
                md: '30%',
              },
            }}
          />
          <Typography
            variant="h4"
            sx={{
              fontWeight: '100',
              color: '#2E4B84',
              margin: {
                xs: 8,
                md: '30px',
              },
              fontSize: {
                xs: '1.75rem',
              },
            }}
          >
            Portal del Empleado
          </Typography>
        </>
      ) : (
        <></>
      )}
      <Paper
        className="inicio"
        sx={{ backgroundColor: 'background.paper', color: colorLetra, p: 4, width: { md: '100%' } }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {!logoOutside && !apply ? (
            <Logo
              logoType="Large"
              sx={{
                width: '100%',
                height: '100%',
                mb: 2,
              }}
            />
          ) : (
            <></>
          )}
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h6" sx={{ m: 2, minWidth: '25ch' }} align="center">
            {step === 1 ? 'NOMBRE DE USUARIO' : 'CONTRASEÑA'}
          </Typography>
        </div>

        <Box ref={containerRef} sx={{ transition: '.3s', width: '100%', position: 'relative' }}>
          <Fade in={step === 1}>
            <div
              style={{
                position: positionUsername,
                top: 0,
              }}
            >
              <FormControl sx={{ width: '100%', minWidth: '25ch' }} variant="standard">
                <Input
                  autoFocus
                  id="standard-adornment-username"
                  inputProps={{
                    tabIndex: 1,
                    placeholder: 'Usuario',
                  }}
                  type="text"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        aria-label="icono usuario"
                        sx={{ color: colorLetra }}
                      >
                        <AccountCircleOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && username) {
                      checkLocked()
                    }
                  }}
                  autoComplete="false"
                  ref={userRef}
                  value={username}
                  onChange={onUsernameChanged}
                />
              </FormControl>

              {/* <FormGroup sx={{ mt: 1 }}>
                <FormControlLabel
                  disabled={lockout}
                  control={<Checkbox />}
                  label="Manterner conectado"
                />
              </FormGroup> */}
              <Button
                endIcon={loading ? <HourglassTop /> : !lockout ? <ArrowRightAlt /> : <DoDisturb />}
                variant="contained"
                sx={{
                  width: '100%',
                  mt: 2,
                }}
                onClick={checkLocked}
                disabled={lockout || !canLogin}
              >
                Siguiente
              </Button>
            </div>
          </Fade>
          <Fade in={step === 2}>
            <div
              style={{
                width: '100%',
                position: positionPassword,
                top: 0,
              }}
            >
              <FormControl sx={{ width: '100%', minWidth: '25ch' }} variant="standard">
                <Input
                  id="standard-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  inputRef={passwordRef}
                  inputProps={{ tabIndex: 2, placeholder: 'Contraseña' }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      onLoginClicked()
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        sx={{ color: colorLetra }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={password}
                  onChange={onPasswordChanged}
                />
              </FormControl>
              <Button
                sx={{ display: 'block', mt: 2, color: colorLetra }}
                LinkComponent={Link}
                disabled={lockout}
                to="/login"
                state={{ recovery: true }}
              >
                Recuperar contraseña
              </Button>
              <Grid container spacing={0} alignItems="center" sx={{ mt: 1 }}>
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => {
                      setStep(1)
                    }}
                    sx={{ color: colorLetra }}
                  >
                    <ArrowBack />
                  </IconButton>
                </Grid>
                <Grid item xs={10}>
                  <Button
                    variant="contained"
                    sx={{
                      width: '100%',
                    }}
                    onClick={onLoginClicked}
                    disabled={lockout || !canLogin}
                  >
                    Entrar
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Box>
        {errorMessage ? (
          <Alert
            sx={{ mt: 2, minWidth: '25ch', width: '100%' }}
            variant="outlined"
            severity="error"
          >
            {errorMessage}
          </Alert>
        ) : (
          <></>
        )}
      </Paper>
    </div>
  )
}

export default LoginForm
