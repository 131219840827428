/**
 * @module DatosTrabajador
 */

import AddIcon from '@mui/icons-material/Add'
import { Alert, Box, LinearProgress, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid2'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import BotonSolicitudInterna from 'features/solicitudes/components/BotonSolicitudInterna'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import FilaExperiencia from '../components/FilaExperiencia'
import FilaFormacion from '../components/FilaFormacion'
import useFetchUserExperience from '../hooks/useFetchExperience'
import useFetchUserFormation from '../hooks/useFetchUserFormation'

/**
 * Component to show the experience of the Worker
 */
const Curriculum = () => {
  const location = useLocation()
  const { response, loading: loadingFormation } = useFetchUserFormation(
    location?.state?.user ?? null,
  )
  const { response: experienceResponse } = useFetchUserExperience(location?.state?.user ?? null)
  const [experienciaTrabajador, setExperienciaTrabajador] = useState(experienceResponse)
  useEffect(() => setExperienciaTrabajador(experienceResponse), [experienceResponse])

  const [formacionExterna, setFormacionExterna] = useState(response)
  const [isLoadingFormation, setLoadingFormation] = useState(loadingFormation)

  useEffect(() => {
    setFormacionExterna(response)
  }, [response])
  useEffect(() => {
    setLoadingFormation(loadingFormation)
  }, [loadingFormation])

  return (
    <>
      <Box sx={{ my: 3, display: 'flex', gap: 2 }}>
        <Typography variant="h3">Curriculum</Typography>
      </Box>
      <Stack>
        <Grid className="outline-paper" container spacing={2}>
          <Grid item size={{ xs: 12 }}>
            <Card sx={{ flexGrow: 1 }}>
              <CardHeader
                title="Experiencia laboral"
                action={
                  <BotonSolicitudInterna
                    titulo="Añadir"
                    id_solicitud_pe="EXPIN"
                    buttonStartIcon={<AddIcon />}
                    buttonVariant="text"
                    buttonSize="small"
                  />
                }
              />
              <CardContent>
                <TableContainer component={Paper}>
                  <Table aria-label="tabla formación">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Puesto</TableCell>
                        <TableCell>Actividad</TableCell>
                        <TableCell>Fecha inicio</TableCell>
                        <TableCell>Fecha fin</TableCell>
                        <TableCell>Duración</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!experienciaTrabajador ? (
                        <></>
                      ) : (
                        experienciaTrabajador.map((exp) => {
                          const finicio = new Date(
                            exp?.f_inicio?.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'),
                          )
                          const ffin = new Date(
                            exp.f_fin?.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'),
                          )

                          let { fecha_inicio, fecha_fin, ...row } = exp

                          row.fecha_inicio = finicio.toLocaleDateString('es-ES', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })
                          row.fecha_fin = ffin.toLocaleDateString('es-ES', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })

                          return <FilaExperiencia key={row.id} row={row} />
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item size={{ xs: 12 }}>
            <Card sx={{ flexGrow: 1 }}>
              <CardHeader
                title="Formación"
                action={
                  <BotonSolicitudInterna
                    titulo="Añadir"
                    id_solicitud_pe="FORIN"
                    buttonStartIcon={<AddIcon />}
                    buttonVariant="text"
                    buttonSize="small"
                  />
                }
              />
              <CardContent>
                <TableContainer component={Paper}>
                  {!formacionExterna ? (
                    <>
                      {isLoadingFormation ? (
                        <>
                          <LinearProgress />
                        </>
                      ) : (
                        <>
                          <Alert severity="info">No se ha encontrado ninguna formacion</Alert>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <Table aria-label="tabla formación">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Título</TableCell>
                        <TableCell>Materia</TableCell>
                        <TableCell>Fecha inicio</TableCell>
                        <TableCell>Fecha fin</TableCell>
                        <TableCell>Duración</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!formacionExterna ? (
                        <></>
                      ) : (
                        formacionExterna.map((formation, index) => {
                          const fixedStartDate = formation?.f_inicio?.replace(
                            /(\d{2})-(\d{2})-(\d{4})/,
                            '$2/$1/$3',
                          )
                          const fixedEndDate = formation.f_fin?.replace(
                            /(\d{2})-(\d{2})-(\d{4})/,
                            '$2/$1/$3',
                          )

                          const finicio = new Date(fixedStartDate)
                          const ffin = new Date(fixedEndDate)

                          /** @type {ExtendedRow} **/
                          const newRow = { ...formation, fecha_inicio: null, fecha_fin: null }

                          newRow.fecha_inicio = !isNaN(Date.parse(fixedStartDate))
                            ? finicio.toLocaleDateString('es-ES', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              })
                            : '---'
                          newRow.fecha_fin = !isNaN(Date.parse(fixedEndDate))
                            ? ffin.toLocaleDateString('es-ES', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              })
                            : '---'

                          console.log(newRow)

                          return <FilaFormacion key={`Formation ${index}`} row={newRow} />
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </>
  )
}

export default Curriculum
