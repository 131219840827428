import { Download } from '@mui/icons-material'
import { Button, CircularProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import CustomTable from 'common/components/CustomTable'
import Table from 'common/components/Table'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import { getFechaFromISO } from 'common/libs/fechas'
import FileIcons from 'common/libs/FilesIcons'
import { selectPortal } from 'features/portal/portalSlice'
import Icon from 'icons/Icons'
import { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'
import { descargarDocumento } from 'tool/funciones'
import useFetchLaboral from '../hooks/useFetchLaboral'

/**
 * @module Documentacion
 * @module DocumentacionLaboral
 */

/**
 * Table view for the Laboral Docs
 * This is an independent view and will
 * no take the object from the menu
 *
 * @param {object} props
 * @param {number | string} [props.ejercicio] -  The year to be fetched
 * @param {string} [props.document_type ]- The tipe of document to fetch
 * @example
 * <LaboralTableView />
 */
const LaboralTableView = ({ ejercicio = null, document_type = null }) => {
  const location = useLocation()
  const [user] = useState(location?.state?.user ?? null)
  const { response, loading } = useFetchLaboral({ user, ejercicio, tipo_documento: document_type })
  const portal = useSelector(selectPortal)
  const { handleModal } = useContext(CustomModalContext)
  const [downloading, setDownloading] = useState(null)

  const themeManager = new ThemeManager()

  const CustomTableComp = themeManager.activeTheme?.components
    ? (themeManager.activeTheme?.components['CustomTable'] ?? CustomTable)
    : CustomTable

  return (
    <>
      <Table
        loading={loading}
        dataSource={response}
        count={response?.length ?? 1}
        page={1}
        fields={[
          {
            field: 'd_rh_documento',
            title: 'Documento',
            customGetter: ({ descargado, d_rh_documento }) => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Icon icon={FileIcons['pdf']?.icon} />
                  <Typography fontWeight={descargado?.icon ? 'initial' : 'bold'}>
                    {d_rh_documento}
                  </Typography>
                </Box>
              )
            },
            width: '150px',
          },
          {
            field: 'fecha_inicio',
            customGetter: ({ descargado, nom_inicio }) => {
              return (
                <Typography fontWeight={descargado ? 'initial' : 'bold'}>
                  {getFechaFromISO(nom_inicio, portal?.locale?.replaceAll('-', '_') ?? 'es-ES')}
                </Typography>
              )
            },
            // customGetter: (field) =>
            //   getFechaFromISO(field.row.nom_inicio, portal?.locale?.replaceAll('-', '_') ?? 'es-ES'),
            title: 'Fecha Inicio',
            width: '150px',
          },
          {
            field: 'fecha_fin',
            // customGetter: (field) =>
            //   getFechaFromISO(field.row.nom_fin, portal?.locale?.replaceAll('-', '_') ?? 'es-ES'),
            customGetter: (row) => {
              console.log({ row })
              return (
                <Typography fontWeight={row?.descargado ? 'initial' : 'bold'}>
                  {getFechaFromISO(row?.nom_fin, portal?.locale?.replaceAll('-', '_') ?? 'es-ES')}
                </Typography>
              )
            },
            title: 'Fecha Fin',
            width: '150px',
          },
          {
            field: 'actions',
            title: 'Descargar',
            width: 150,
            flex: 0.7,
            customGetter: (document) => {
              return (
                <Box sx={{ width: '100%', display: 'flex', placeItems: 'center', gap: '2px' }}>
                  <Button
                    color={FileIcons['pdf']?.color}
                    sx={{
                      backgroundColor: 'transparent',
                      px: 2,
                      color: FileIcons['pdf']?.color,
                      ['&:hover']: { backgroundColor: 'initial' },
                    }}
                    startIcon={
                      <>
                        {downloading === document?.id ? (
                          <CircularProgress size="14px" color={FileIcons['pdf']?.color} />
                        ) : (
                          <Icon
                            icon="fi-rr-arrow-circle-down"
                            color={FileIcons['pdf']?.color}
                            size="14px"
                          />
                        )}
                      </>
                    }
                    onClick={() => {
                      handleModal({ type: ModalTypes.Loading, enabled: true })
                      setDownloading(document.id)
                      descargarDocumento(document.tipo_doc_principal, document.id).finally(() => {
                        handleModal({ type: ModalTypes.Loading, enabled: false })
                        setDownloading(null)
                      })
                    }}
                  >
                    PDF
                  </Button>
                  {document.has_xml > 0 ? (
                    <Button
                      sx={{ background: 'transparent', px: 2, color: FileIcons['xml']?.color }}
                      color="warning"
                      onClick={() => {
                        setDownloading(document.id + 'xml')
                        handleModal({ type: ModalTypes.Loading, enabled: true })
                        descargarDocumento(document.tipo_doc_secundario, document.id).finally(
                          () => {
                            handleModal({ type: ModalTypes.Loading, enabled: false })
                            setDownloading(null)
                          },
                        )
                      }}
                      startIcon={
                        <>
                          {downloading === document?.id + 'xml' ? (
                            <CircularProgress size="14px" color={FileIcons['xml']?.color} />
                          ) : (
                            <Icon
                              icon="fi-rr-arrow-circle-down"
                              color={FileIcons['xml']?.color}
                              size="14px"
                            />
                          )}
                        </>
                      }
                    >
                      XML
                    </Button>
                  ) : (
                    <></>
                  )}
                </Box>
              )
            },
          },
        ]}
      />
    </>
  )
}

export default LaboralTableView
