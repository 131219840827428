import Documentacion from '../components/Documentacion'

const DifusionDocumentos = ({ object }) => {
  return (
    <>
      <Documentacion document_type={object ?? null} compact={false} />
    </>
  )
}

export default DifusionDocumentos
