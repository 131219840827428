import { Avatar, Box, IconButton, Typography } from '@mui/material'
import { selectUsuario } from 'features/users/stores/userSlice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'

/**
 * User Avatar
 * on click will take you to the user data
 *
 * @param {object} props
 * @param {import('@mui/material').SxProps<import('@emotion/react').Theme>} props.boxSx
 * @example
 *
 * <UserImage boxSx={{with: "100%"}}/>
 *
 * @returns {JSX.Element}
 */
const UserImage = ({ boxSx, expanded, hideText }) => {
  const navigate = useNavigate()
  const user = useSelector(selectUsuario)

  const themeManager = new ThemeManager()

  const apply = themeManager.options.apply ?? false

  return (
    <Box sx={{ position: 'relative', ...boxSx }}>
      <IconButton
        size="large"
        aria-label="cuenta de usuario"
        aria-controls="menu-appbar"
        aria-haspopup="dialog"
        onClick={() => navigate('/datos')}
        color="inherit"
        sx={{
          zIndex: 2,
          width: expanded ? '50%' : '100%',
          marginX: 'auto',
          display: 'block',
          transition: '.3s',
          width: '100%',
          border: '3px solid ',
          borderColor: 'background.menu',
          p: 0,
        }}
      >
        <Avatar
          src={`/assets/images/${user.imagen}`}
          sx={{
            transition: '.3s',
            backgroundColor: 'secondary.main',
            aspectRatio: '4/4',
            width: '100%',
            height: '100%',
            color: 'secondary.contrastText',
          }}
        />
      </IconButton>
      {!hideText ? (
        <Box
          sx={{
            width: '100%',
            visibility: expanded ? 'visible' : 'hidden',
            opacity: expanded ? 1 : 0,
            transitionDelay: expanded ? '0s' : '0s',
            // display: expanded ? 'initial' : 'none',
            translate: expanded ? 'initial' : '0px -100px ',
            height: expanded ? 'initial' : '0px',
            whiteSpace: 'nowrap',
            transition: '.3s',
            zIndex: 1,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              display: 'block',
              width: '100%',
              textAlign: 'center',
              whiteSpace: 'wrap',
              color: apply ? '#ffffff' : '',
              fontSize: apply ? '1rem' : '',
            }}
          >
            {user?.nombre.split(',')[0] ?? ''}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              display: 'block',
              width: '100%',
              textAlign: 'center',
              color: apply ? '#ffffff' : '',
              fontSize: apply ? '0.9rem' : '',
            }}
          >
            {user?.nombre.split(',')[1] ?? ''}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  )
}

export default UserImage
