import { Chip, Divider, Skeleton } from '@mui/material'
import { Box } from '@mui/system'
import SimpleTable from 'common/components/SimpleTable'
import { getLocaleFromFullDate } from 'common/libs/fechas'

/**
 *
 * @module ControlPresencia
 *
 */

/**
 * @callback setFichajeActiveRecord
 * @param {FichajesDTO} item
 */

/**
 *
 * Listado de ultimos fichajes
 *
 * @param {object} props
 * @param {Array<FichajesDTO>} props.response - Listado de fichajes
 */
const LastFichajesTable = ({ response, sx, loading }) => {
  return loading ? (
    <Box
      sx={{
        borderRadius: '5px',
        width: '100%',
        ...sx,
      }}
    >
      <Skeleton variant="rounded" height={60} sx={{ width: '100%' }} />
      <Skeleton variant="rounded" height={60} sx={{ width: '100%' }} />
      <Skeleton variant="rounded" height={60} sx={{ width: '100%' }} />
    </Box>
  ) : (
    <Box
      sx={{
        borderRadius: '5px',
        width: '100%',
        ...sx,
      }}
    >
      <Divider sx={{ my: 2 }} textAlign="left">
        Últimos Fichajes
      </Divider>
      <SimpleTable
        header_cell_style={{ padding: '5px' }}
        table_style={{ width: '100%', borderCollapse: 'collapse', display: 'table' }}
        container_style={{
          marginBottom: '5px',
          borderRadius: '5px',
          // marginTop: '10px',
        }}
        generic_cell_style={{
          textAlign: 'left',
          padding: '15px 10px',
        }}
        hideHeader={true}
        header_style={{
          textAlign: 'left',
          borderRadius: '5px',
          padding: '10px',
        }}
        stripped={true}
        dataSource={response}
        fields={[
          {
            field: 'entrada_salida',
            title: 'Acción',
            customGetter: (
              /** @type {FichajesDTO} */
              item,
            ) => {
              return item.entrada_salida === 'S' ? (
                <Chip color="error" variant="outlined" label="Salida" />
              ) : (
                <Chip color="success" variant="outlined" label="Entrada" />
              )
            },
          },
          {
            field: 'fecha_terminal',
            title: 'Momento',
            customGetter: (
              /**@type {FichajesDTO}*/
              item,
            ) => {
              return getLocaleFromFullDate(item.fecha_terminal, true)
            },
          },
        ]}
      />
    </Box>
  )
}

export default LastFichajesTable
