/**  @type {import("icons/Icons.types").FilesIconsVariants} */
export const FileIcons = {
  docx: {
    color: 'primary.main',
    icon: 'DocOutline',
    text: 'docx',
  },
  doc: {
    color: 'primary.main',
    icon: 'DocOutline',
    text: 'doc',
  },
  pdf: {
    color: 'error.main',
    icon: 'PdfOutline',
    text: 'pdf',
  },
  xml: {
    color: 'warning.main',
    icon: 'TextAltOutline',
    text: 'xml',
  },
  jpg: {
    color: 'success.main',
    icon: 'JpgOutline',
    text: 'jpg',
  },
  xls: {
    color: 'success.main',
    icon: 'XlsOutline',
    text: 'xls',
  },
  png: {
    color: 'success.main',
    icon: 'PngOutline',
    text: 'pdf',
  },
  txt: {
    color: 'primary.main',
    icon: 'TextAltOutline',
    text: 'txt',
  },
  default: {
    color: 'warning.main',
    icon: 'TextAltOutline',
    text: 'text',
  },
}

export default FileIcons
