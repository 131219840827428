import { Tooltip, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import Skeleton from '@mui/material/Skeleton'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import useFetchSolicitudInterna from '../hooks/useFetchSolicitudInterna'
import { addSolicitudALista } from '../solicitudesSlice'

/**
 * Button to star a libra request
 *
 * @param {Object} props - The react component props
 * @param {string} props.titulo - The request title
 * @param {string} props.id_solicitud_pe - The request id
 * @param {string} [props.preButtonText] - Text to show before the button
 * @param {string} [props.postButtonText] - Text to show after the button
 * @param {string} [props.noAvailableText] - Text to show when the button is not available
 * @param {import('@mui/material').SxProps} [props.sx={}] - The MuiButton sx
 * @param {import('@mui/material').ButtonProps} [props.buttonProps={}] - The MuiButton props
 */
const BotonSolicitudInterna = ({
  titulo = 'Cambiar',
  id_solicitud_pe,
  buttonProps = {},
  buttonSize = 'medium',
  buttonVariant = 'contained',
  buttonColor = 'primary',
  buttonStartIcon = null,
  buttonEndIcon = null,
  sx = { width: '100%', maxWidth: 345 },
  type = 'button',
  fabIcon = null,
  fabSize = 'medium',
  fabColor = 'primary',
  preButtonText = null,
  postButtonText = null,
  noAvailableText = null,
  tooltip = null,
}) => {
  const dispatch = useDispatch()

  const { response, loading } = useFetchSolicitudInterna({ id_solicitud_pe })
  const [solicitud, setSolicitud] = useState({})

  useEffect(() => {
    setSolicitud(response[0])
    dispatch(addSolicitudALista(response[0]))
  }, [response, dispatch])

  const button = loading ? (
    <Skeleton animation="wave" width={48} height={48} />
  ) : solicitud ? (
    type === 'button' ? (
      <div>
        {preButtonText ? (
          <Typography sx={{ color: '#c20e19', fontWeight: 'bold' }}>{preButtonText}</Typography>
        ) : (
          ''
        )}
        <Button
          variant={buttonVariant}
          size={buttonSize}
          color={buttonColor}
          sx={{ ...sx }}
          {...buttonProps}
          startIcon={buttonStartIcon}
          endIcon={buttonEndIcon}
        >
          <Link
            to="/solicitud"
            style={{
              textDecoration: 'none',
              color: 'inherit',
              width: '100%',
              display: 'block',
              textAlign: 'inherit',
            }}
            state={{ solicitud }}
          >
            {titulo}
          </Link>
        </Button>
        {postButtonText ? (
          <Typography sx={{ color: '#c20e19', fontWeight: 'bold' }}>{postButtonText}</Typography>
        ) : (
          ''
        )}
      </div>
    ) : type === 'fab' ? (
      <Fab sx={sx} size={fabSize} color={fabColor}>
        <Link
          to="/solicitud"
          style={{
            textDecoration: 'none',
            color: 'inherit',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          state={{ solicitud }}
          sx={sx}
        >
          {fabIcon}
        </Link>
      </Fab>
    ) : (
      <></>
    )
  ) : (
    <>
      {noAvailableText ? (
        <Typography sx={{ color: '#c20e19', fontWeight: 'bold' }}>{noAvailableText}</Typography>
      ) : (
        ''
      )}
    </>
  )

  return tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button
}

export default BotonSolicitudInterna
