import { Dark, Light } from './pallete'

/**
 *
 * @returns {EdisaTheme}
 */
const getPalette = () => {
  const storedTheme = localStorage.getItem('theme') || 'light'

  const pallete = storedTheme === 'light' ? Light : Dark
  return pallete
}

/**
 * @type {EdisaTheme}
 */
const palette = getPalette()

/**
 *
 * @type {import('@mui/material').Components<Omit<import('@mui/material').Theme, "components">>}
 */
const components_styles = {
  MuiPopover: {
    styleOverrides: {
      root: {
        '&.headerNotificationBox,&.headerLaboralNotificationBox': {
          '& .MuiPaper-root': {
            backgroundColor: palette.background.warning,
            '& .MuiButton-text': {
              backgroundColor: '#00000000',
            },
          },
        },
      },
    },
  },
  MuiBox: {
    styleOverrides: {
      root: {
        backgroundColor: '#0f0',
        boxShadow: '2px 2px 2px black',
        '& .simple-table': {
          backgroundColor: '#ff0000',
        },
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      backgroundColor: '#f9f',
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        '& #solicitud-label-component': {
          pl: 2,
        },
        boxShadow: 'none',
        '&.MuiPaper-rounded': {
          borderRadius: '30px',
          // borderTopRightRadius: '20px',
        },
        '&.inicio': {
          borderRadius: '30px',
          '@media (min-width: 960px)': {
            padding: '100px',
            width: '100%',
          },
          '@media (max-width: 960px)': {
            marginTop: '50px',
            padding: '100px 25px 100px 25px',
          },
        },
        '&.menu': {
          // overflowY: 'auto',
          '@media (min-width: 960px)': {
            borderRadius: '0 30px 0 0',
          },
        },
        '&.content-paper': {
          borderRadius: '30px',
          padding: '40px',
          fontWeight: 300,
        },
        '&.degradado': {
          backgroundColor: '#426BBA4A',
          backgroundImage: 'linear-gradient(140deg, #a0c1ff9e, #ffe0a9d6)',
          width: '100%',
        },
        '&.naranjita': {
          backgroundColor: palette.background.warning,
        },
        '&.titSolicitud': {
          boxShadow: 'none',
          fontWeight: '300',
          verticalAlign: 'baseline',
        },
        '&.docs': {
          borderRadius: '40px',
          color: 'primary.constrastText',
        },
        '&.MuiPopover-paper': {
          backgroundColor: palette.background.paper,
          color: palette.text.primary,
          borderRadius: '40px',
          padding: '10px',
          '& .MuiStack-root': {
            '& .MuiBox-root': {
              // backgroundColor: '#fff',
              backgroundColor: palette.background.default,
              color: '#142D5F',
              borderRadius: '15px',
              paddingLeft: '5px',
              '& .MuiSvgIcon-root': {
                // color: '#FFA620',
              },
            },
          },
        },
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        '@media (max-width: 960px)': {
          borderRadius: '0 0 30px 0',
        },
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        color: 'primary.main',
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        color: palette.primary.main,
        '& .MuiInputBase-root': {
          fontWeight: '400',
          color: palette.primary.main,
          padding: '2px',
          backgroundColor: palette.background.default,
          zIndex: 0,
          height: '48px',
          borderRadius: '15px',
          borderBottom: 'none',
          boxShadow: 'none',
          '&::before': {
            display: 'none',
          },
          '& input.MuiInputBase-input': {
            padding: '10px 10px',
          },
          '& .MuiOutlinedInput-root': { p: 1 },
          '& input.MuiOutlinedInput-input': {
            borderRadius: '15px',
            borderColor: palette.primary.main,
            boxSizing: 'border-box',
          },

          '& input:autofill; & input:-webkit-autofill': {
            backgroundColor: '#2b2b2b',
          },
        },
        '& .MuiFormLabel-root': {
          zIndex: 2,
          fontSize: '0.9rem',
          fontWeight: '300',
          marginTop: '0px',
          marginLeft: '0px',
          mr: 1,
          color: palette.text.primary,
        },
      },
    },
  },
  MuiButton: {
    variants: [
      {
        props: { variant: 'text' },
        style: {
          backgroundColor: palette.background.paper,
          paddingLeft: '18px',
          paddingRight: '18px',
        },
      },
    ],
    styleOverrides: {
      root: {
        boxShadow: 'none',
        '&.MuiButton-text': {},
        borderRadius: '1000px',
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        '&.bold': {
          fontWeight: '500',
        },
        '&.lighter': {
          fontWeight: '300',
        },
        '&.blue': {
          color: 'secondary.main',
        },
      },
    },
  },

  MuiGrid: {
    styleOverrides: {
      root: {
        '& .MuiDataGrid-columnHeaders': {
          // border: 'none',
        },
        '&.botonClaro': {
          backgroundColor: '#ECF0F8',
        },
        '& .map': {
          borderRadius: '30px',
        },
        '&.mapContainer': {
          flexDirection: 'row-reverse',
          flexWrap: 'wrap',
        },
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        color: '#2E4B84',
        borderRadius: '10px',
        boxShadow: 'none',
        '&:before': {
          backgroundColor: 'unset',
        },
        '& .MuiAccordionSummary-root': {
          '& .MuiTypography-body1': {
            fontSize: '1.1rem',
            fontWeight: '450',
          },
          '& .MuiTypography-body2': {
            fontWeight: '350',
            fontSize: '0.775rem',
          },
        },
        '& .MuiAccordionDetails-root': {
          '& .MuiTypography-body1': {
            fontSize: '0.875rem',
            fontWeight: '400',
          },
        },
      },
    },
  },
  MuiStack: {
    styleOverrides: {
      root: {
        verticalAlign: 'baseline',
        '&.solicitudes': {
          display: 'flex',
          '@media (min-width: 960px)': {
            flexDirection: 'row',
          },
          '@media (max-width: 960px)': {
            flexDirection: 'column',
          },
          flexWrap: 'wrap',
          width: '100%',
          gap: '10px',
          '& .MuiCard-root': {
            marginTop: 0,
            '@media (min-width: 960px)': {
              flex: '0 0 calc(25% - 7.5px)',
            },
            '@media (max-width: 960px)': {
              flex: '0 0 100%',
            },
            height: '115px',
            maxWidht: '25%',
            boxSizing: 'border-box',
            boxShadow: 'none',
            '& .MuiButtonBase-root': {
              borderRadius: '30px',
              height: '105px',
              '& .MuiCardContent-root': {
                marginLeft: '60px',
                widht: '250px',
                overflow: 'hidden', //345f98
              },
              '& .MuiTypography-root': {
                fontSize: '1rem',
                fontWeight: '410',
              },
              '& .MuiTypography-body2': {
                display: 'none',
              },
            },
          },
        },
        '& .outline-paper': {
          '& .MuiPaper-root': {
            backgroundColor: 'transparent',
            '& .MuiCardHeader-action': {
              color: '#2E4B84',
            },
            '& .MuiCardContent-root': {
              border: '4px solid ' + palette.background.paper,
              borderRadius: '30px',
              '& .MuiList-root': {
                backgroundColor: 'transparent',
                '& .MuiListItem-root': {
                  backgroundColor: palette.background.paper,
                  borderRadius: '10px',
                  marginBottom: '8px',
                  minHeight: '56px',
                  '& .MuiListItemText-root': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    '& span': {
                      fontSize: '1rem',
                      lineHeight: '1.5',
                    },
                    '& p': {
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      lineHeight: '1.5',
                    },
                  },
                },
                '& .MuiListItem-root:last-child': {
                  marginBottom: '0px',
                },
              },
            },
            '& .MuiCardContent-root:last-child': {
              paddingBottom: '16px',
            },
          },
          '& .emergencia': {
            '& .MuiCardHeader-root': {
              color: palette.error.main,
            },
            '& .MuiCardContent-root': {
              backgroundColor: palette.error.main,
              border: 'none',
              color: '#fff',
              '& .MuiList-root': {
                '& .MuiListItem-root': {
                  backgroundColor: palette.background.default,
                  color: palette.error.main,
                  borderRadius: '10px',
                  marginBottom: '8px',
                  minHeight: '56px',
                },
              },
            },
          },
        },
        '&.header-actions': {
          '& .library-icon': {
            color: palette.secondary.main,
            '& path': {
              fill: palette.secondary.main,
            },
          },
        },
      },
    },
  },
  MuiBottomNavigation: {
    styleOverrides: {
      root: {
        '&.MuiBottomNavigationAction-root': {
          color: '#2E4B84',
        },
        '& .MuiFab-circular': {
          backgroundColor: '#2E4B84',
          color: '#ffff',
          boxShadow: 'none',
          '&.SpeedDialbasicexample-actions': {},
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '30px',
        '& .MuiTypography-body1': {
          fontSize: '0.8rem',
        },
        '&.perfil': {
          backgroundColor: palette.background.primary80,
          borderRadius: '40px',
          color: '#fff',
          '& .MuiFormLabel-root': {
            marginTop: '10px',
            padding: '5px',
            fontWeight: '500',
            color: '#9096AA',
          },
          '& .MuiCardActions-root': {
            '& .MuiSelected': {
              color: '#254b7e',
            },
          },
        },
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      'div.inicio': {
        '@media (min-width: 960px)': {
          width: '80%',
          margin: 'auto',
          marginTop: 0,
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        // color: '#004260',
        // borderColor: '#004260',
        '& .MuiAlert-icon': {
          // color: '#0288d1',
        },
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        marginRight: '8px',
      },
    },
  },
}

export default components_styles
