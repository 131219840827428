import { Paper } from '@mui/material'
import Grid from '@mui/material/Grid2'
import ThemeManager from 'theming/ThemeManager'
import AccountSettings from '../components/AccountSettings'
import GeneralSettings from '../components/GeneralSettings'

const Settings = ({}) => {
  const themeController = new ThemeManager()
  return (
    <>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid sx={{ margin: 'auto' }} size={{ xs: 12, md: 7 }}>
            <AccountSettings />
          </Grid>
          {themeController.hide.Options?.General?.Hide ? (
            <></>
          ) : (
            <Grid item size={{ xs: 12, md: 5 }}>
              <GeneralSettings />
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  )
}

export default Settings
