import {
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import FilesHelper from 'common/libs/Files'
import { getFechaFromISO, getLocaleFromFullDate } from 'common/libs/fechas'
import { useContext, useRef, useState } from 'react'
import ConfirmReadModal from 'features/documentos/components/ConfirmReadModal'
import { useEffect, useMemo } from 'react'
import Icon from 'icons/Icons'
import FileIcons from 'common/libs/FilesIcons'
import DocumentViewer from 'features/documentos/components/DocumentViewer'
import { GlobalVariablesContext } from 'common/providers/GlobalVariablesProvider'
import Grid from '@mui/material/Grid2'
import { descargarDocumento } from 'tool/funciones'

/**
 * Component that renders and download a document
 *
 * @param {Object} props
 * @param {LaboralDocDTO} props.document - The document to show
 */
const LaboralDocumentCard = ({ document }) => {
  const [displayDocument, setDisplayDocument] = useState(document)
  const [NeedReading, SetNeedsReading] = useState(
    displayDocument?.lectura_obligatoria && !document.descargado,
  )
  const [downloading, setDownloading] = useState(false)

  const downloadFile = async (e) => {
    setDownloading(true)
    descargarDocumento(e.target.dataset.tipo, e.target.dataset.id).finally(() => {
      const date = new Date().toISOString()
      setDisplayDocument({ ...displayDocument, descargado: date })
      setDownloading(false)
    })
  }

  const { IS_GOTTENBERG_ACTIVE } = useContext(GlobalVariablesContext)
  const [float, setFloat] = useState(false)

  const { handleModal } = useContext(CustomModalContext)
  function markedCallback(_, date) {
    SetNeedsReading(false)
    setDisplayDocument({ ...displayDocument, descargado: date, obligatorio: true })
  }
  const elementRef = useRef(null)
  const [position, setPosition] = useState({ top: 0, left: 0 })

  useEffect(() => {
    const updatePosition = () => {
      if (elementRef.current) {
        const { top, left } = elementRef.current.getBoundingClientRect()
        setPosition({ top, left })
      }
    }

    updatePosition()
    window.addEventListener('resize', updatePosition)

    return () => window.removeEventListener('resize', updatePosition)
  }, [float])

  /** @type {import('icons/Icons.types').FileIcon} **/
  const fileIcon = useMemo(() => {
    return FileIcons['pdf'] ?? FileIcons['default']
  }, [document?.mime_type])

  const viewDocument = () => {
    handleModal({
      enabled: true,
      closable: true,
      type: ModalTypes.Custom,
      content: (
        <DocumentViewer
          document_id={displayDocument.id}
          document_type={displayDocument.tipo_doc_principal}
          title={displayDocument.d_rh_documento}
          MIMEType={'pdf'}
          downloaded={displayDocument.descargado}
        />
      ),
    })
  }

  useEffect(() => console.log({ document }), [document])
  const downloadedString = useMemo(() => {
    return displayDocument?.descargado ? getLocaleFromFullDate(displayDocument?.descargado) : null
  }, [displayDocument?.descargado])

  return (
    <>
      <Box
        onClick={() => {
          setFloat(!float)
        }}
        sx={{
          display: float ? 'block' : 'none',
          transition: '.3s ease .3s, opacity .3s ease .4s',
          opacity: float ? 0.2 : 0,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: '9998',
          background: 'black',
        }}
      ></Box>
      <Paper
        ref={elementRef}
        sx={{
          display: 'flex',
          p: 2,
          backgroundColor: 'background.paper',
          gap: 2,
          zIndex: '9999',
          // transition: float ? '.3s ease-in-out, transform .1s ease .3s' : '.3s ease-in-out',
          transition: '.3s',
          boxShadow: float ? '0 0 16px lightgrey' : 'none',
          height: float ? 'auto' : '100%',
          position: float ? 'fixed' : 'initial',
          top: float ? '50%' : position.top,
          left: float ? '50%' : position.left,
          transform: float ? 'translate(-50%, -50%)' : 'unset',
        }}
      >
        <Box>
          <Icon
            size="36px"
            icon={fileIcon?.icon ?? 'Article'}
            color={fileIcon?.color ?? 'primary.main'}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ width: '100%' }}>
              <Typography sx={{ fontSize: '.8rem', fontWeight: '500' }}>
                {document?.d_rh_documento ?? ''}
              </Typography>
              <Typography sx={{ fontSize: '.7rem' }}>
                {downloadedString ? `Descargado el ${downloadedString}` : 'Pendiente de descarga'}
              </Typography>
            </Box>
            <Icon
              sx={{
                display: displayDocument?.observaciones ? 'flex' : 'none',
                alignItems: 'baseline',
              }}
              onClick={() => {
                setFloat(!float)
              }}
              icon="fi-ss-info"
              size="1rem"
              color="warning.main"
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', my: 1 }}>
            {displayDocument.nom_inicio && displayDocument.nom_fin ? (
              <Grid sx={{}} spacing={2} container>
                <Grid item size={{ xs: 6 }}>
                  <Typography sx={{ fontSize: '.8rem', fontWeight: '500' }}>
                    Fecha inicio
                  </Typography>
                  <Typography sx={{ fontSize: '.8rem', fontWeight: 'normal' }}>
                    {document?.nom_inicio
                      ? getLocaleFromFullDate(displayDocument.nom_inicio, false)
                      : ''}
                  </Typography>
                </Grid>
                <Grid item size={{ xs: 6 }}>
                  <Typography sx={{ fontSize: '.8rem', fontWeight: '500' }}>Fecha fin</Typography>
                  <Typography sx={{ fontSize: '.8rem', fontWeight: 'normal' }}>
                    {document?.nom_fin ? getLocaleFromFullDate(displayDocument.nom_fin, false) : ''}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Box>
          <Box
            sx={{
              mt: 'auto',
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              justifyContent: 'end',
            }}
          >
            {NeedReading ? (
              <Button
                onClick={() => {
                  handleModal({
                    enabled: true,
                    content: (
                      <ConfirmReadModal
                        document={displayDocument}
                        SetNeedsReading={markedCallback}
                      />
                    ),
                    type: ModalTypes.Custom,
                  })
                }}
                sx={{ mr: 'auto' }}
                startIcon={<Icon icon="fi-rr-eye" size="12px" />}
              >
                Marcar lectura
              </Button>
            ) : (
              ''
            )}
            {IS_GOTTENBERG_ACTIVE ? (
              <Button onClick={viewDocument} startIcon={<Icon icon="fi-rr-eye" size="12px" />}>
                Ver
              </Button>
            ) : (
              ''
            )}

            {displayDocument.has_xml > 0 ? (
              <Button
                size="small"
                color="warning"
                startIcon={
                  downloading ? (
                    <CircularProgress size="14px" color={fileIcon.color} />
                  ) : (
                    <Icon color="warning.main" icon="fi-rr-arrow-circle-down" />
                  )
                }
                data-id={displayDocument.id}
                data-tipo={displayDocument.tipo_doc_secundario}
                onClick={downloadFile}
              >
                XML
              </Button>
            ) : (
              ''
            )}
            <Button
              size="small"
              color="error"
              startIcon={
                downloading ? (
                  <CircularProgress size="14px" color={fileIcon.color} />
                ) : (
                  <Icon icon="fi-rr-arrow-circle-down" color="error.main" />
                )
              }
              data-id={displayDocument.id}
              data-tipo={displayDocument.tipo_doc_principal}
              onClick={downloadFile}
            >
              PDF
            </Button>
          </Box>
        </Box>
      </Paper>
    </>
  )
}

export default LaboralDocumentCard
