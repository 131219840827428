import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import FilesHelper from 'common/libs/Files'
import { getFechaFromISO, getLocaleFromFullDate } from 'common/libs/fechas'
import { useContext, useRef, useState } from 'react'
import ConfirmReadModal from 'features/documentos/components/ConfirmReadModal'
import { useEffect, useMemo } from 'react'
import Icon from 'icons/Icons'
import FileIcons from 'common/libs/FilesIcons'
import DocumentViewer from 'features/documentos/components/DocumentViewer'
import { GlobalVariablesContext } from 'common/providers/GlobalVariablesProvider'

/**
 * Component that renders and download a document
 *
 * @param {Object} props
 * @param {DocCorporativaDto} props.document - The document to show
 */
const CorporateDocumentCard = ({ document }) => {
  const [displayDocument, setDisplayDocument] = useState(document)
  const [NeedReading, SetNeedsReading] = useState(displayDocument?.obligatorio && !document.leido)

  const { IS_GOTTENBERG_ACTIVE } = useContext(GlobalVariablesContext)
  const [float, setFloat] = useState(false)

  const [downloading, setDownloading] = useState(false)

  const { handleModal } = useContext(CustomModalContext)
  function markedCallback(_, date) {
    SetNeedsReading(false)
    setDisplayDocument({ ...displayDocument, descargado: date, obligatorio: true })
  }
  const elementRef = useRef(null)
  const [position, setPosition] = useState({ top: 0, left: 0 })

  useEffect(() => {
    const updatePosition = () => {
      if (elementRef.current) {
        const { top, left } = elementRef.current.getBoundingClientRect()
        setPosition({ top, left })
      }
    }

    // Call on mount and on resize
    updatePosition()
    window.addEventListener('resize', updatePosition)

    // Cleanup on unmount
    return () => window.removeEventListener('resize', updatePosition)
  }, [float])

  /** @type {import('icons/Icons.types').FileIcon} **/
  const fileIcon = useMemo(() => {
    return FileIcons[document?.mime_type?.toLowerCase() ?? 'default'] ?? FileIcons['default']
  }, [document?.mime_type])

  const viewDocument = () => {
    handleModal({
      enabled: true,
      closable: true,
      type: ModalTypes.Custom,
      content: (
        <DocumentViewer
          document_id={document.id}
          document_type={document.tipo_doc}
          MIMEType={document.mime_type}
          forced={document.obligatorio}
          title={document.descargado}
          readDate={document.leido}
        />
      ),
    })
  }

  useEffect(() => console.log({ document }), [document])
  const downloadedString = useMemo(() => {
    return displayDocument?.descargado ? getLocaleFromFullDate(displayDocument?.descargado) : null
  }, [displayDocument?.descargado])

  return (
    <>
      <Box
        onClick={() => {
          setFloat(!float)
        }}
        sx={{
          display: float ? 'block' : 'none',
          transition: '.3s ease .3s, opacity .3s ease .4s',
          opacity: float ? 0.2 : 0,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: '9998',
          background: 'black',
        }}
      ></Box>
      <Paper
        ref={elementRef}
        sx={{
          display: 'flex',
          p: 2,
          backgroundColor: 'background.paper',
          gap: 2,
          flexDirection: 'column',
          zIndex: '9999',
          // transition: float ? '.3s ease-in-out, transform .1s ease .3s' : '.3s ease-in-out',
          transition: '.3s',
          boxShadow: float ? '0 0 16px lightgrey' : 'none',
          height: float ? 'auto' : '100%',
          position: float ? 'fixed' : 'initial',
          top: float ? '50%' : position.top,
          left: float ? '50%' : position.left,
          transform: float ? 'translate(-50%, -50%)' : 'unset',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <Box>
            <Icon
              size="36px"
              icon={fileIcon?.icon ?? 'Article'}
              color={fileIcon?.color ?? 'primary.main'}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box sx={{ width: '100%' }}>
                <Typography sx={{ fontSize: '.8rem', fontWeight: '500' }}>
                  {document?.descripcion ?? ''}
                </Typography>
                <Typography sx={{ fontSize: '.7rem' }}>
                  {downloadedString ? `Descargado el ${downloadedString}` : 'Pendiente de descarga'}
                </Typography>
              </Box>
              <Icon
                sx={{
                  display: displayDocument?.observaciones ? 'flex' : 'none',
                  alignItems: 'baseline',
                }}
                onClick={() => {
                  setFloat(!float)
                }}
                icon="fi-ss-info"
                size="1rem"
                color="warning.main"
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', my: 1 }}>
              <Box sx={{ display: NeedReading ? 'flex' : 'none', gap: 1, alignItems: 'center' }}>
                <Icon
                  sx={{ display: 'flex-inline', alignItems: 'center', mt: '3px' }}
                  icon="fi-ss-triangle-warning"
                  size=".8rem"
                  color="error.main"
                />
                <Typography sx={{ fontSize: '.8rem', fontWeight: '500', display: 'inline' }}>
                  Lectura obligatoria
                </Typography>
              </Box>
              <Box sx={{ display: displayDocument?.d_categoria ? 'initial' : 'none', gap: 1 }}>
                <Typography sx={{ fontSize: '.8rem', fontWeight: '500', display: 'inline' }}>
                  Categoría:
                </Typography>
                <Typography sx={{ fontSize: '.8rem', display: 'inline', ml: 1 }}>
                  {displayDocument?.d_categoria}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: displayDocument?.observaciones && float ? 'initial' : 'none',
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '.8rem',
                    fontWeight: '500',
                    display: 'inline',
                    transition: '.3s',
                  }}
                >
                  Observaciones:
                </Typography>
                <Box sx={{ border: '.5px solid', p: 1, minWidth: '400px' }}>
                  <div dangerouslySetInnerHTML={{ __html: displayDocument.observaciones }}></div>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 'auto',
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            justifyContent: 'end',
          }}
        >
          {NeedReading ? (
            <Button
              onClick={() => {
                handleModal({
                  enabled: true,
                  content: (
                    <ConfirmReadModal document={displayDocument} SetNeedsReading={markedCallback} />
                  ),
                  type: ModalTypes.Custom,
                })
              }}
              sx={{ mr: 'auto' }}
              startIcon={<Icon icon="fi-rr-check-circle" size="12px" />}
            >
              Marcar lectura
            </Button>
          ) : (
            ''
          )}
          {IS_GOTTENBERG_ACTIVE ? (
            <Button onClick={viewDocument} startIcon={<Icon icon="fi-rr-eye" size="12px" />}>
              Ver
            </Button>
          ) : (
            ''
          )}
          <Button
            onClick={async () => {
              setDownloading(true)
              await FilesHelper.downloadFile(
                displayDocument.id,
                document.tipo_doc,
                document.url,
              ).finally(() => {
                const date = new Date().toISOString()
                setDisplayDocument({ ...displayDocument, descargado: date })
                setDownloading(false)
              })
            }}
            sx={{
              color: fileIcon.color,
            }}
            startIcon={
              <>
                {downloading ? (
                  <CircularProgress size="14px" color={fileIcon.color} />
                ) : (
                  <Icon icon="fi-rr-arrow-circle-down" color={fileIcon.color} size="12px" />
                )}
              </>
            }
          >
            {fileIcon.text.toUpperCase()}
          </Button>
        </Box>
      </Paper>
    </>
  )
}

export default CorporateDocumentCard
