import { Box, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid2'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'

/**
 * @module DatosTrabajador
 */

/**
 * Component to show the bank data for the Worker
 * @param {Object} props
 * @param {WorkerDataDto} props.datosTrabajador
 */
const Bancarios = ({ datosTrabajador }) => {
  return (
    <>
      <Box sx={{ my: 3, display: 'flex', gap: 2 }}>
        <Typography variant="h3">Bancarios</Typography>
      </Box>
      <Stack>
        <Grid className="outline-paper" container spacing={2}>
          <Grid item size={{ xs: 12 }}>
            <Card sx={{ flexGrow: 1 }}>
              <CardContent>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  <ListItem>
                    <ListItemText
                      primary="Banco"
                      secondary={`${datosTrabajador?.d_banco_trab ?? '--'}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="IBAN"
                      secondary={`${datosTrabajador?.iban_trab ?? '--'}`}
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </>
  )
}

export default Bancarios
