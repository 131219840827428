import { ArrowUpward, TextSnippet } from '@mui/icons-material'
import { Card, CardContent, CardHeader, IconButton, Paper, Typography } from '@mui/material'
import ScrollHelper from 'common/libs/ScrollHelper'
import { selectPortal } from 'features/portal/portalSlice'
import { useSelector } from 'react-redux'
import ThemeManager from 'theming/ThemeManager'

/**
 *
 * @param {Object} props
 * @param {Model145DTO} props.data - The user to show the data
 */
const Model145PensionsData = ({ data }) => {
  const portal = useSelector(selectPortal)

  const accent_bg = new ThemeManager().mui_theme.palette.background.default
  return (
    <Paper>
      <Card>
        <CardHeader
          avatar={<TextSnippet />}
          sx={{
            backgroundColor: 'background.lighter',
            boxShadow: 'none',
          }}
          title="Pensiones y Pagos"
          action={
            <IconButton onClick={() => ScrollHelper.scrollTo({ id: 'model145-intro' })}>
              <ArrowUpward />
            </IconButton>
          }
        />
        <CardContent sx={{ p: 2 }}>
          <ul
            style={{
              marginLeft: `${2 * 8}px`,
              display: 'flex',
              gap: '1em',
              flexDirection: 'column',
            }}
          >
            <li>
              <Typography>
                <b>Pensión compensatoria en favor del cónyuge:</b>
                <br />
                <span>
                  Importe anual que esta Vd. obligado a satisfacer por resolución judicial
                  <span
                    style={{ marginLeft: '1em', backgroundColor: accent_bg, padding: '5px 10px' }}
                  >
                    {parseFloat(data?.irpf_fv?.pension_conyuge ?? '0')
                      .toFixed(2)
                      .toLocaleString(portal?.locale?.replaceAll('_', '-') ?? 'es-ES')}
                  </span>
                </span>
              </Typography>
            </li>
            <li>
              <Typography>
                <b>Anualidades por alimentos en favor de los hijos:</b>
                <br />
                <span>
                  Importe anual que esta Vd. obligado a satisfacer por resolución judicial
                  <span
                    style={{ marginLeft: '1em', backgroundColor: accent_bg, padding: '5px 10px' }}
                  >
                    {parseFloat(data?.irpf_fv?.pension_hijos ?? '0')
                      .toFixed(2)
                      .toLocaleString(portal?.locale?.replaceAll('_', '-') ?? 'es-ES')}
                  </span>
                </span>
              </Typography>
            </li>
            <li>
              <Typography>
                <b>Importante: </b>
                <br />
                <span>
                  Solo podrá cumplimentar este apartado los contribuyentes que hayan adquirido su
                  vivienda habitual, o hayan satisfecho cantidades por obras de rehabilitación de la
                  misma, antes del 1 de enero de 2013. Si esta Vd. efectuando pagos por préstamos
                  destinados a la adquisiciún o rehabilitación de su vivienda habitual por los que
                  vaya a tener derecho a deducción por inversión en la vivienda habitual en el IRPF
                  y la cuantía total de sus retribuciones íntegras en concepto de rendimientos del
                  trabajo procedentes de todos sus pagadores es inferior a 33.007,20 euros anuales.
                </span>
              </Typography>
            </li>
          </ul>
        </CardContent>
      </Card>
    </Paper>
  )
}

export default Model145PensionsData
