import { Box, Skeleton } from '@mui/material'

function DocLaboralSkeleton() {
  return (
    <Box
      sx={{
        display: 'block',
        backgroundColor: 'background.paper',
        borderRadius: 5,
        height: 'fit-content',
        width: '100%',
        p: 2,
        my: 2,
      }}
    >
      <Skeleton height={80} />
      <Skeleton height={80} />
      <Skeleton height={80} />
      <Skeleton height={80} />
      <Skeleton height={80} />
      <Skeleton height={80} />
    </Box>
  )
}

export default DocLaboralSkeleton
