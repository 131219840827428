import { Box } from '@mui/system'
import FallBackImage from 'assets/images/logo.edisa.png'
import { ReactComponent as MainLogo } from 'assets/images/logo.svg'
import { selectPortal } from 'features/portal/portalSlice'
import { useSelector } from 'react-redux'
import ThemeManager from 'theming/ThemeManager'

/**
 *
 * @param {Object} props
 * @param {import("common/types/logos_types").LogoVariant} props.logoType = "Large" - The variant for the logo
 * @param {SxProps} [props.sx = {}] - The variant for the logo
 */
const Logo = ({ sx = {}, logoType = 'Large' }) => {
  const themeController = new ThemeManager()

  const portal = useSelector(selectPortal)

  /** @type {import('common/types/logos_types').LogoComponents} */
  const logos = {
    Large: portal?.portal?.logo_login ? (
      <img
        src={`assets/images/${portal.portal.logo_login}`}
        title="Logo Portal"
        alt="Logo Portal"
        style={{ maxHeight: '100px' }}
      />
    ) : (
      <MainLogo style={{ width: '100%', height: '100%' }} />
    ),
    NavBar: MainLogo,
    Header: portal?.portal?.logo_menu ? (
      <img
        src={`assets/images/${portal.portal.logo_menu}`}
        title="Logo Portal"
        alt="Logo Portal"
        onError={(event) => {
          event.target.src = FallBackImage
        }}
        style={{ maxHeight: '100%' }}
      />
    ) : (
      <MainLogo style={{ width: '100%', height: '100%' }} />
    ),
  }

  if (themeController?.logos) {
    Object.keys(themeController.logos).forEach((key) => {
      if (themeController.logos[key]) logos[key] = themeController.logos[key]
    })
  }

  return <Box sx={{ widht: '100%', ...sx }}>{logos[logoType]}</Box>
}

export default Logo
