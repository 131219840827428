import { getMenusStatus, selectMenus } from 'features/portal/menusSlice'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
/**
 * @module Common
 */

/**
 * @typedef {Object} useCurrentMenuResponse
 * @prop {MenuDto} useCurrentMenu.actualMatch
 * @prop {MenuDto} useCurrentMenu.currentMenu
 */

/**
 * Get the current menu and his direct parent
 *
 * @returns {useCurrentMenuResponse}
 */
const useCurrentMenu = () => {
  const menus = useSelector(selectMenus)
  const menusStatus = useSelector(getMenusStatus)
  const url = useLocation()
  const [currentMenu, setCurrentMenu] = useState(null)
  const [actualMatch, setActualMatch] = useState(null)

  const matches = (menu) => {
    const urlPath =
      url.pathname[url.pathname.length - 1] === '/' ? url.pathname.slice(0, -1) : url.pathname
    const path = `/${menu.camino || menu.programa}`
    const active = menu.activo === 'S'
    let childMatch = false
    let match = urlPath === path
    let matchInner = null

    if (!menu.programa) {
      menu.hijos.forEach((menu) => {
        const inMatch = matches(menu)
        if (inMatch) {
          matchInner = inMatch
          setActualMatch(inMatch)
          return
        }
      })
    }
    if (matchInner) return true

    const thisIs = (match || childMatch) && active
    if (!thisIs) return undefined

    setActualMatch(menu)

    return thisIs ? menu : undefined
  }

  useEffect(() => {
    if (menusStatus !== 'succeeded') return

    setCurrentMenu(
      menus.find((menu) => {
        const match = matches(menu)
        if (match) {
          return true
        }
      }),
    )
  }, [menusStatus, menus, url])

  return { currentMenu, actualMatch }
}

export default useCurrentMenu
