import AddIcon from '@mui/icons-material/Add'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import SpeedDial from '@mui/material/SpeedDial'
import { DatePicker } from '@mui/x-date-pickers'
import { getLocaleFromFullDate } from 'common/libs/fechas'
import FilesHelper from 'common/libs/Files'
import { enqueueSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useNavigate } from 'react-router-dom'
import useFetchGruposGastos from '../hooks/useFetchGruposGastos'
import useFetchOptionsHojasGastoLin from '../hooks/useFetchOptionsHojasGastoLin'
import { createHojaGastoLin } from '../lib/HojasGastoLinController'
export const HojasGastoLinAddModal = ({
  id_hoja,
  updateState,
  showButton = true,
  editObject = null,
  fechaInicio,
  fechaFin,
}) => {
  const navigate = useNavigate()

  const [openAddModal, setOpenAddModal] = useState(false)
  const [fecha, setFecha] = useState({})
  const [grupoGastos, setGrupoGastos] = useState('')
  const [grupoGastosArrayPosition, setGrupoGastosArrayPosition] = useState(0)
  const [concepto, setConcepto] = useState('')
  const [conceptArrayPosition, setConceptArrayPosition] = useState(0)
  const [descripcion, setDescripcion] = useState('')
  const [observaciones, setObservaciones] = useState('')
  const [cantidad, setCantidad] = useState('1')
  const [precio, setPrecio] = useState('0')
  const [importe, setImporte] = useState(0)
  const [documents, setDocuments] = useState([])

  const [addError, setAddError] = useState(false)
  const [fechaError, setFechaError] = useState('')
  const [grupoGastoError, setGrupoGastoError] = useState('')
  const [documentsError, setDocumentsError] = useState('')
  const [descripcionError, setDescripcionError] = useState('')
  const [conceptoError, setConceptoError] = useState('')
  const [precioError, setPrecioError] = useState('')
  const [cantidadError, setCantidadError] = useState('')

  const { response, error, loading } = useFetchOptionsHojasGastoLin()
  const {
    response: grupos_gastos,
    error: grupos_gastos_error,
    loading: grupos_gastos_loading,
  } = useFetchGruposGastos()

  const handleClose = () => {
    setOpenAddModal(!openAddModal)
  }

  const resetState = () => {
    setOpenAddModal(false)
    setFecha({})
    setGrupoGastos(1)
    setGrupoGastosArrayPosition(0)
    setConcepto('')
    setConceptArrayPosition(0)
    setDescripcion('')
    setObservaciones('')
    setCantidad('1')
    setPrecio('0')
    setImporte(0)
    setDocuments([])
  }
  const handleClick = async () => {
    try {
      let error = false
      if (Object.keys(fecha).length < 1) {
        error = true
        setFechaError('La fecha es obligatoria')
      } else {
        if (new Date(fechaInicio) <= new Date(fecha) && new Date(fecha) <= new Date(fechaFin)) {
          setFechaError('')
        } else {
          error = true
          setFechaError(
            'La fecha debe estar entre ' +
              getLocaleFromFullDate(fechaInicio, false) +
              ' y ' +
              getLocaleFromFullDate(fechaFin, false),
          )
        }
      }
      if (!grupoGastos) {
        error = true
        setGrupoGastoError('El grupo de gastos es obligatorio')
      } else {
        setGrupoGastoError('')
      }

      if (
        grupos_gastos[grupoGastosArrayPosition].conceptos[conceptArrayPosition].obligar_adjunto ===
          'S' &&
        documents.length < 1
      ) {
        error = true
        setDocumentsError('Para este concepto los documentos son obligatorios')
      } else {
        setDocumentsError('')
      }

      if (!descripcion) {
        error = true
        setDescripcionError('La descripción es obligatoria')
      } else {
        setDescripcionError('')
      }

      if (!concepto) {
        setConceptoError('El concepto es obligatorio')
        error = true
      } else {
        setConceptoError('')
      }

      if (parseFloat(precio) <= 0) {
        error = true
        setPrecioError('El precio no puede ser menor o igual a cero')
      } else {
        setPrecioError('')
      }
      if (cantidad <= 0) {
        error = true
        setCantidadError('La cantidad no puede ser menor o igual a cero')
      } else {
        setCantidadError('')
      }

      if (error) {
        setAddError(true)
        return
      } else {
        setAddError(false)
      }

      let concept

      for (let i = 0; i < grupos_gastos[grupoGastosArrayPosition].conceptos.length; i++) {
        if (grupos_gastos[grupoGastosArrayPosition].conceptos[i].id == concepto) {
          concept = grupos_gastos[grupoGastosArrayPosition].conceptos[i].concepto
          break
        }
      }

      const res = await createHojaGastoLin({
        id_hoja: parseInt(id_hoja),
        fecha: new Date(fecha).toISOString().split('T')[0],
        grupoGastos: grupoGastos,
        concepto: concept,
        descripcion,
        cantidad: parseInt(cantidad),
        precio: parseFloat(precio),
        observaciones,
        documentos: documents,
      })
      updateState(res)
      resetState()
      enqueueSnackbar('Línea creada correctamente', { variant: 'success' })
      handleClose()
    } catch (error) {
      enqueueSnackbar('No se ha podido crear la línea')
      console.log(error.message)
    }
  }

  useEffect(() => {
    if (cantidad && precio) {
      setImporte(parseFloat(cantidad) * parseFloat(precio))
    } else {
      setImporte(0)
    }
  }, [cantidad, precio])

  useEffect(() => {
    let position = 0
    grupos_gastos.forEach((grupo_gasto) => {
      for (let i = 0; i < grupo_gasto.conceptos.length; i++) {
        if (grupo_gasto?.conceptos[i]?.id == concepto) {
          setConceptArrayPosition(i)
          position = i
          break
        }
      }
    })
    if (grupos_gastos[grupoGastosArrayPosition]?.conceptos[position]?.precio) {
      setPrecio(grupos_gastos[grupoGastosArrayPosition]?.conceptos[position]?.precio)
    } else {
      setPrecio(0)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [concepto])

  const convert = async (acceptedFiles) => {
    let doc = []
    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i]
      const result = await FilesHelper.fileToBase64(file)
      doc = [...doc, { nombre_archivo: result.name, data: result.base64 }]
    }
    return doc
  }

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const data = await convert(acceptedFiles)
      setDocuments([...documents, ...data])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setDocuments],
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 999,
  })
  const files = documents.map((file) => {
    return <li key={file.nombre_archivo}>{file.nombre_archivo}</li>
  })

  return (
    <>
      <Modal
        open={openAddModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: { xs: 'scroll', sm: 'initial' } }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: { xs: '70%', sm: '50%' },
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', lg: '50%' },
            bgcolor: 'background.paper',
            border: '1px',
            borderRadius: '0.5rem',
            boxShadow: 24,
            p: 4,
          }}
        >
          {!loading && !grupos_gastos_loading ? (
            <Grid container spacing={2} sx={{ textAlign: 'center', placeItems: 'center' }}>
              <Grid item xs={12}>
                {addError ? (
                  <Typography sx={{ color: 'red', width: '100%' }}>
                    Algunos datos son incorrectos
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  format="YYYY-MM-DD"
                  label="Fecha Inicio"
                  value={fecha}
                  error={fechaError ? true : false}
                  onChange={(e) => setFecha(e)}
                  sx={{ width: '100%' }}
                />
                {fechaError ? (
                  <Typography sx={{ color: 'red', width: '100%' }}>{fechaError}</Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ width: '100%' }} error={grupoGastoError ? true : false}>
                  <InputLabel>Grupo Gastos</InputLabel>
                  <Select
                    value={grupoGastos}
                    label="Grupo Gastos"
                    onChange={(e) => {
                      setGrupoGastos(e.target.value)
                      for (let i = 0; i < grupos_gastos.length; i++) {
                        if (grupos_gastos[i].codigo === e.target.value) {
                          setGrupoGastosArrayPosition(i)
                          break
                        }
                      }
                      setConcepto('')
                    }}
                  >
                    {response?.grupos_gastos?.map((grupo) => (
                      <MenuItem value={grupo.codigo} key={grupo.codigo}>
                        {grupo.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {grupoGastoError ? (
                  <Typography sx={{ color: 'red', width: '100%' }}>{grupoGastoError}</Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ width: '100%' }} error={conceptoError ? true : false}>
                  <InputLabel>Concepto</InputLabel>
                  <Select
                    value={concepto}
                    label="Concepto"
                    onChange={(e) => setConcepto(e.target.value)}
                  >
                    {grupos_gastos[grupoGastosArrayPosition]?.conceptos.map((concepto) => (
                      <MenuItem value={concepto.id} key={concepto.id}>
                        {concepto.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {conceptoError ? (
                  <Typography sx={{ color: 'red', width: '100%' }}>{conceptoError}</Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup required sx={{ width: '100%' }}>
                  <TextField
                    error={descripcionError ? true : false}
                    label="Descripcion"
                    variant="outlined"
                    value={descripcion}
                    type="text"
                    onChange={(e) => setDescripcion(e.target.value)}
                  />
                </FormGroup>
                {descripcionError ? (
                  <Typography sx={{ color: 'red', width: '100%' }}>{descripcionError}</Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup sx={{ width: '100%' }}>
                  <TextField
                    label="Observaciones"
                    variant="outlined"
                    value={observaciones}
                    type="textarea"
                    onChange={(e) => setObservaciones(e.target.value)}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup sx={{ width: '100%' }}>
                  <TextField
                    error={cantidadError ? true : false}
                    label="Cantidad"
                    variant="outlined"
                    value={cantidad}
                    type="number"
                    disabled={!response.max_importe_control}
                    onChange={(e) => setCantidad(e.target.value)}
                  />
                </FormGroup>
                {cantidadError ? (
                  <Typography sx={{ color: 'red', width: '100%' }}>{cantidadError}</Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup sx={{ width: '100%' }}>
                  <TextField
                    error={precioError ? true : false}
                    label="Precio"
                    variant="outlined"
                    value={precio}
                    type="number"
                    disabled={
                      grupos_gastos[grupoGastosArrayPosition]?.conceptos[conceptArrayPosition]
                        ?.precio
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      if (!e.target.value) {
                        setPrecio(e.target.value)
                        return
                      }
                      if (
                        !grupos_gastos[grupoGastosArrayPosition]?.conceptos[conceptArrayPosition]
                          ?.precio_maximo ||
                        parseFloat(
                          grupos_gastos[grupoGastosArrayPosition]?.conceptos[conceptArrayPosition]
                            ?.precio_maximo,
                        ) >= parseFloat(e.target.value)
                      ) {
                        setPrecio(e.target.value)
                      }
                    }}
                  />
                </FormGroup>
                {precioError ? (
                  <Typography sx={{ color: 'red', width: '100%' }}>{precioError}</Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup sx={{ width: '100%' }}>
                  <TextField
                    label="Importe"
                    variant="outlined"
                    value={importe}
                    type="number"
                    disabled
                    sx={{ width: '100%' }}
                    onChange={(e) => setImporte(e.target.value)}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={2} sx={{ width: '100%' }}>
                  <Box
                    component="span"
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      border: '2px dashed',
                      backgroundColor: 'background.default',
                      overflow: 'scroll',
                      width: '100%',
                    }}
                  >
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />

                      <SaveAltIcon />
                      <p>Arrastre aquí ficheros o haga click para seleccionar</p>
                      <aside>
                        <h4>Ficheros</h4>
                        <ul>{files}</ul>
                      </aside>
                    </div>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  onClick={() => setDocuments([])}
                  color="error"
                  sx={{
                    width: '100%',
                    ':hover': { backgroundColor: 'darkblue' },
                  }}
                >
                  Borrar documentos
                </Button>
                {documentsError ? (
                  <Typography sx={{ color: 'red', width: '100%' }}>{documentsError}</Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  onClick={handleClick}
                  color="success"
                  sx={{
                    width: '100%',
                    ':hover': { backgroundColor: 'darkgreen' },
                  }}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ mt: 1, width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
        </Box>
      </Modal>
      {showButton ? (
        <>
          <Button
            startIcon={<AddCircleIcon />}
            onClick={() => setOpenAddModal(!openAddModal)}
            color="primary"
            variant="text"
            sx={{ display: { xs: 'none', md: 'flex' }, px: 2, width: 'fit-content' }}
          >
            Nueva Línea
          </Button>
          <SpeedDial
            ariaLabel="Modal close button"
            sx={{
              position: 'fixed',
              bottom: 5,
              right: 16,
              zIndex: 500,
              display: { xs: 'initial', md: 'none' },
            }}
            icon={<AddIcon />}
            onClick={() => setOpenAddModal(!openAddModal)}
          />
        </>
      ) : null}
    </>
  )
}
