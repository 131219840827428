import { Download, TaskAltOutlined, Warning } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { XCircle } from '@phosphor-icons/react'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import FilesHelper from 'common/libs/Files'
import { getLocaleFromFullDate } from 'common/libs/fechas'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { markDocumentAsReaded } from '../libs/markDocumentAsReaded'

/**
 *
 * @param {Object} props
 * @param {string} props.title - The title for the card
 * @param {string} [props.downloaded] - The last date the file has been downloaded
 * @param {string} [props.readDate] - Date that the file was marked as readed
 * @param {string} [props.MIMEType] - The extension of the file
 * @param {boolean} [props.forced=false]- This will show a warn icon on the top right of the card
 * @param {number | string} [props.document_id]
 * @param {number | string} [props.document_type]
 * @param {number | string} [props.document_url]
 * @returns {JSX.Element}
 */
const DocumentViewer = ({
  title,
  downloaded,
  readDate,
  forced = false,
  MIMEType = 'default',
  document_id,
  document_type,
  document_url,
}) => {
  const [document, setDocument] = useState(null)
  const { handleModal } = useContext(CustomModalContext)
  const [error, setError] = useState(true)
  const [confirmed, setConfirmed] = useState(false)
  const [dateReading, setDateReading] = useState(readDate)
  const [marking, setMarking] = useState(false)
  const needsReading = useMemo(() => forced && !dateReading, [forced, dateReading])

  const fetchDocument = useCallback(async () => {
    try {
      setError(false)

      const data = await FilesHelper.downloadFile(document_id, document_type, document_url, true)

      if (!data.fichero) setError(true)
      setDocument(data)
    } catch (error) {
      setError(true)
      console.error('Error fetching document:', error)
    }
  }, [document_type, document_id, document_url])

  useEffect(() => {
    fetchDocument()
  }, [fetchDocument])

  const blobUrl = useMemo(() => {
    if (document && (document.pdf || document.fichero)) {
      //data:application/pdf;base64,
      const data = `${document?.pdf ?? document?.fichero}`
      console.log(data)
      return data
    }
    return null
  }, [document])

  return (
    <Box
      sx={{
        width: '90vw',
        height: '90vh',
        backgroundColor: 'background.default',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        p: '1px',
      }}
    >
      <Box
        sx={{
          width: '90vw',
          px: 2,
          py: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography>{title}</Typography>
        <Box>
          {forced && !dateReading ? (
            <Tooltip title="Lectura obligatoria">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <Typography>Lectura obligatoria</Typography>
                <Warning color="error" />
              </Box>
            </Tooltip>
          ) : forced && dateReading ? (
            <Tooltip title={`Leído el ${getLocaleFromFullDate(dateReading)}`}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <Typography>{`Leído el ${getLocaleFromFullDate(dateReading)}`}</Typography>
                <TaskAltOutlined color="success" />
              </Box>
            </Tooltip>
          ) : (
            <></>
          )}
        </Box>
        <IconButton
          onClick={() => {
            handleModal({ enabled: false })
          }}
        >
          <XCircle />
        </IconButton>
      </Box>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'grid',
          placeItems: 'center',
          backgroundColor: 'background.paper',
        }}
      >
        {blobUrl ? (
          <iframe
            title={title}
            src={FilesHelper.urlBase64String(blobUrl, document.mime_type)}
            width="100%"
            height="100%"
          />
        ) : !error ? (
          <CircularProgress />
        ) : (
          <Typography sx={{ fontSize: '2em', opacity: 0.7 }}>
            No tiene permisos para visualizar este archivo
          </Typography>
        )}
      </Box>
      <Box sx={{ p: 0.3, display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <Button
            onClick={() => {
              FilesHelper.downloadBase64String(document.fichero, document.nombre_archivo)
            }}
            startIcon={<Download />}
            variant="outlined"
          >
            Descargar
          </Button>
        </Box>
        {needsReading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <FormControlLabel
              required
              control={
                <Checkbox
                  onChange={(e) => {
                    setConfirmed(e.target.checked)
                  }}
                />
              }
              label="Confirmo que he leido el documento"
            />
            <Button
              onClick={async () => {
                setMarking(true)
                try {
                  const response = await markDocumentAsReaded(document_id, document_type)
                  setDateReading(response.fecha)
                } catch (error) {
                } finally {
                  setMarking(false)
                }
              }}
              disabled={!confirmed || marking}
              variant="contained"
            >
              Marcar como leido
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  )
}

export default DocumentViewer
