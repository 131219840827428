import { FormatListBulleted } from '@mui/icons-material'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import { Alert, Box, Typography } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import useCurrentMenu from 'common/hooks/useCurrentMenu'
import 'dayjs/locale/es'
import { useEffect, useState } from 'react'
import TeamCalendarAccordeon from '../components/TeamCalendarAccordeon'
import TeamCalendarDateFilters from '../components/TeamCalendarDateFilters'
import TeamCalendarDates from '../components/TeamCalendarDates'
import TeamCalendarHeader from '../components/TeamCalendarHeader'
import TeamCalendarList from '../components/TeamCalendarList'
import useFetchTeam from '../hooks/useFetchTeam'
import TeamCalendarSkeleton from '../skeletons/TeamCalendarSkeleton'

const TeamCalendar = function () {
  const now = new Date()
  const currentYear = now.getFullYear()
  const [year, setYear] = useState(currentYear)
  const [month, setMonth] = useState(now.getMonth() + 1)
  const [user, setUser] = useState(null)
  const [team, setTeam] = useState(null)
  const [representation, setRepresentation] = useState('accordeon')

  const { response, loading, error } = useFetchTeam(year, month)

  const currentMenu = useCurrentMenu()

  useEffect(() => {
    if (response) {
      setUser(response.user)
      setTeam(response.team)
    }
  }, [response])

  return (
    <>
      {error ? (
        <Alert severity="error">
          Se ha producido un error en la consulta de datos. Por favor, inténtelo de nuevo más tarde
        </Alert>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 1 }}>
              <ToggleButtonGroup
                value={representation}
                size="small"
                exclusive
                onChange={(event, value) => {
                  setRepresentation(value)
                }}
              >
                <ToggleButton key="accordeon" value="accordeon" aria-label="accordeon">
                  <Box sx={{ display: 'flex', alignContent: 'center', gap: 1 }}>
                    <UnfoldMoreIcon />
                    <Typography>Vista Amplia</Typography>
                  </Box>
                </ToggleButton>
                <ToggleButton key="list" value="list" aria-label="list">
                  <Box sx={{ display: 'flex', alignContent: 'center', gap: 1 }}>
                    <FormatListBulleted />
                    <Typography>Vista Compacta</Typography>
                  </Box>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <TeamCalendarDateFilters
              loading={loading}
              currentYear={currentYear}
              year={year}
              month={month}
              sx={{ mb: 4 }}
              onYearChange={(newYear) => setYear(newYear)}
              onMonthChange={(newMonth) => setMonth(newMonth)}
            />
          </Box>
          {loading ? (
            <TeamCalendarSkeleton />
          ) : (
            <>
              <TeamCalendarHeader key={'cal_head'} year={year} month={month} />
              <TeamCalendarDates key={`cal_user`} employee={user} />
              {representation == 'list' ? (
                <TeamCalendarList team={team} />
              ) : (
                <TeamCalendarAccordeon team={team} />
              )}
            </>
          )}
        </Box>
      )}
    </>
  )
}

export default TeamCalendar
