/**
 * @type {import("@mui/material/styles/createTypography").TypographyOptions}
 */
const typography = {
  fontFamily: 'Poppins',
  h1: {
    fontSize: '3rem',
    fontWeight: '200',
  },
  h2: {
    fontSize: '2rem',
    fontWeight: '300',
  },
}

export default typography
