import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone'
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone'
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone'
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone'
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import { styled } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Organigrama from 'features/organigrama/pages/Organigrama'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'
import TabPanel from '../components/TabPanel'
import WorkerData from '../components/WorkerData'
import useFetchWorkerData from '../hooks/useFetchWorkerData'
import Bancarios from '../subpages/Bancarios'
import Curriculum from '../subpages/Curriculum'
import Personales from '../subpages/Personales'
import Profesionales from '../subpages/Profesionales'

const DatosTrabajador = () => {
  const Theme = new ThemeManager()
  const location = useLocation()
  const [user] = useState(location?.state?.user ?? null)
  const { response, loading } = useFetchWorkerData(user)

  const [datos, setDatos] = useState(response)

  useEffect(() => {
    setDatos(response)
  }, [response])

  const [value, setValue] = useState(0)

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  const CustomWorkerData = Theme.components?.WorkerData

  const PillTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => {
    return {
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
      minHeight: 'unset',
      borderRadius: '1000px',
      backgroundColor: theme.palette.background.paper,
      '&.Mui-selected': {
        color: theme.palette.success.contrastText,
        backgroundColor: theme.palette.success.main,
      },
    }
  })

  return (
    <>
      <Box className="WorkerData" sx={{ marginTop: '63px' }}>
        <Card className="perfil" sx={{ width: '100%', overflow: 'initial' }}>
          {CustomWorkerData ? (
            <CustomWorkerData datos={datos} loading={loading} />
          ) : (
            <WorkerData datos={datos} />
          )}
          {!Theme.options?.WorkerData?.subpageSelectors ||
          Theme.options?.WorkerData?.subpageSelectors === 'tabs' ? (
            <CardActions disableSpacing sx={{ justifyContent: 'flex-end' }}>
              <Tabs
                value={value}
                variant="scrollable"
                onChange={handleChange}
                aria-label="secciones datos trabajador"
                allowScrollButtonsMobile={true}
              >
                <Tab icon={<PersonOutlineTwoToneIcon />} iconPosition="start" label="Personal" />
                {Theme.hide?.WorkerData?.Professional?.Hide ? (
                  <></>
                ) : (
                  <Tab icon={<WorkTwoToneIcon />} iconPosition="start" label="Profesional" />
                )}
                {Theme.hide?.WorkerData?.Bank?.Hide ? (
                  <></>
                ) : (
                  <Tab
                    icon={<AccountBalanceTwoToneIcon />}
                    iconPosition="start"
                    label="Bancarios"
                  />
                )}
                {Theme.hide?.WorkerData?.Curriculum?.Hide ? (
                  <></>
                ) : (
                  <Tab icon={<SchoolTwoToneIcon />} iconPosition="start" label="Curriculum" />
                )}
                {Theme.hide?.WorkerData?.Organigram?.Hide ? (
                  <></>
                ) : (
                  <Tab icon={<AccountTreeTwoToneIcon />} iconPosition="start" label="Organigrama" />
                )}
              </Tabs>
            </CardActions>
          ) : (
            <></>
          )}
        </Card>
      </Box>

      {Theme.options?.WorkerData?.subpageSelectors === 'pills' ? (
        <Box
          sx={{
            mt: 5,
            mb: 1,
            width: { xs: '100%', md: 'fit-content' },
            ml: 'auto',
            '& .MuiTabs-indicator': {
              display: 'none',
            },
          }}
        >
          <Tabs
            value={value}
            variant="scrollable"
            onChange={handleChange}
            aria-label="secciones datos trabajador"
            allowScrollButtonsMobile={true}
          >
            <PillTab icon={<PersonOutlineTwoToneIcon />} iconPosition="start" label="Personal" />
            {Theme.hide?.WorkerData?.Professional?.Hide ? (
              <></>
            ) : (
              <PillTab icon={<WorkTwoToneIcon />} iconPosition="start" label="Profesional" />
            )}
            {Theme.hide?.WorkerData?.Bank?.Hide ? (
              <></>
            ) : (
              <PillTab
                icon={<AccountBalanceTwoToneIcon />}
                iconPosition="start"
                label="Bancarios"
              />
            )}
            {Theme.hide?.WorkerData?.Curriculum?.Hide ? (
              <></>
            ) : (
              <PillTab icon={<SchoolTwoToneIcon />} iconPosition="start" label="Curriculum" />
            )}
            {Theme.hide?.WorkerData?.Organigram?.Hide ? (
              <></>
            ) : (
              <PillTab icon={<AccountTreeTwoToneIcon />} iconPosition="start" label="Organigrama" />
            )}
          </Tabs>
        </Box>
      ) : (
        <></>
      )}

      <TabPanel value={value} index={0}>
        <Personales datosTrabajador={datos} />
      </TabPanel>
      {Theme.hide?.WorkerData?.Professional?.Hide ? (
        <></>
      ) : (
        <TabPanel value={value} index={1}>
          <Profesionales />
        </TabPanel>
      )}
      {Theme.hide?.WorkerData?.Bank?.Hide ? (
        <></>
      ) : (
        <TabPanel value={value} index={2}>
          <Bancarios datosTrabajador={datos} />
        </TabPanel>
      )}
      {Theme.hide?.WorkerData?.Curriculum?.Hide ? (
        <></>
      ) : (
        <TabPanel value={value} index={3}>
          <Curriculum />
        </TabPanel>
      )}
      {Theme.hide?.WorkerData?.Organigram?.Hide ? (
        <></>
      ) : (
        <TabPanel value={value} index={4}>
          <Organigrama />
        </TabPanel>
      )}
    </>
  )
}

export default DatosTrabajador
