/**
 * @type {ThemeOptions}
 */
const options = {
  fullHideMenu: false,
  drawerWidth: null,
  pageTitleOptions: {
    excludeElements: ['home'],
    excludeActualMatchElements: ['solicitud'],
  },
}

export default options
