import MenuDrawerCompact from '../features/menu/components/MenuDrawerCompact'
import MenuDrawerDefault from '../features/menu/components/MenuDrawerDefault'

const MenuDrawer = ({
  expanded,
  DRAWER_WIDTH,
  ALLWAYS_EXPANDED,
  COLLAPSED_DRAWER_WIDTH,
  mobileOpen,
  open,
  handleClick,
  mobile = false,
}) => {
  return (
    <>
      {!mobile ? (
        <MenuDrawerDefault
          expanded={expanded}
          DRAWER_WIDTH={DRAWER_WIDTH}
          ALLWAYS_EXPANDED={ALLWAYS_EXPANDED}
          COLLAPSED_DRAWER_WIDTH={COLLAPSED_DRAWER_WIDTH}
          mobileOpen={mobileOpen}
          open={open}
          handleClick={handleClick}
          small={!ALLWAYS_EXPANDED}
        />
      ) : (
        <MenuDrawerCompact
          expanded={expanded}
          DRAWER_WIDTH={DRAWER_WIDTH}
          ALLWAYS_EXPANDED={ALLWAYS_EXPANDED}
          COLLAPSED_DRAWER_WIDTH={COLLAPSED_DRAWER_WIDTH}
          mobileOpen={mobileOpen}
          open={open}
          handleClick={handleClick}
          small={!ALLWAYS_EXPANDED}
        />
      )}
    </>
  )
}

export default MenuDrawer
