import { Download } from '@mui/icons-material'
import { Button, Divider, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import { getLocaleFromFullDate } from 'common/libs/fechas'
import { useContext, useState } from 'react'
import ThemeManager from 'theming/ThemeManager'
import { descargarDocumento } from 'tool/funciones'
import GenericDocumentCard from './GenericDocumentCard'

/**
 *
 *
 * @param {Object} props -
 * @param {LaboralDocDTO} props.document -
 */
const LaboralDocumentCard = ({ document }) => {
  const { handleModal } = useContext(CustomModalContext)
  const [displayDocument, setDisplayDocument] = useState(document)
  const Theme = new ThemeManager()

  const downloadFile = async (e) => {
    handleModal({ type: ModalTypes.Loading, enabled: true })
    descargarDocumento(e.target.dataset.tipo, e.target.dataset.id).finally(() => {
      const date = new Date().toISOString()
      setDisplayDocument({ ...displayDocument, descargado: date })
      handleModal({ type: ModalTypes.Loading, enabled: false })
    })
  }
  const CustomComponent = Theme.components?.LaboralDocumentCard
  return CustomComponent ? (
    <CustomComponent document={document} />
  ) : (
    <GenericDocumentCard
      className="docs"
      document_id={displayDocument.id}
      document_type={displayDocument.tipo_doc_principal}
      title={displayDocument.d_rh_documento}
      MIMEType={'pdf'}
      downloaded={displayDocument.descargado}
      content={
        <Grid spacing={2} container>
          <Grid item xs>
            <Typography variant="caption" display="block" gutterBottom>
              Fecha inicio
            </Typography>
            <Typography variant="body1">
              {document?.nom_inicio ? getLocaleFromFullDate(displayDocument.nom_inicio, false) : ''}
            </Typography>
          </Grid>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Grid item xs>
            <Typography variant="caption" display="block" gutterBottom>
              Fecha fin
            </Typography>
            <Typography variant="body1">
              {document?.nom_fin ? getLocaleFromFullDate(displayDocument.nom_fin, false) : ''}
            </Typography>
          </Grid>
        </Grid>
      }
      actionsPrimary={[
        <Box key={1} sx={{ ml: 'auto' }}>
          {displayDocument.has_xml > 0 ? (
            <Button
              size="small"
              color="warning"
              startIcon={<Download />}
              data-id={displayDocument.id}
              data-tipo={displayDocument.tipo_doc_secundario}
              onClick={downloadFile}
            >
              XML
            </Button>
          ) : (
            ''
          )}
          <Button
            size="small"
            startIcon={<Download />}
            data-id={displayDocument.id}
            data-tipo={displayDocument.tipo_doc_principal}
            onClick={downloadFile}
          >
            PDF
          </Button>
        </Box>,
      ]}
    />
  )
}

export default LaboralDocumentCard
