import { GridView, TableRows } from '@mui/icons-material'
import {
  Autocomplete,
  Button,
  ButtonGroup,
  LinearProgress,
  TextField,
  autocompleteClasses,
} from '@mui/material'
import { Box } from '@mui/system'
import useCurrentMenu from 'common/hooks/useCurrentMenu'
import useHashedData from 'common/hooks/useHashedData'
import { useEffect, useState } from 'react'
import ThemeManager from 'theming/ThemeManager'
import DocLaboralSkeleton from '../components/DocLaboralSkeleton'
import Laboral from '../components/LaboralGridView'
import LaboralTableView from '../components/LaboralTableView'
import useFetchLaboralMeta from '../hooks/useFetchLaboralMeta'

/**
 * @module Documentacion
 * @module DocumentacionLaboral
 */

/**
 * React Component that works as the default page for the Laboral Docs
 *
 * @param {Object} [props]
 * @param {string} [props.object] - This object comes from LIBRA `objeto-programa` *mmenu* and will be used to determine wich tipe of document will we fetched if it is not passed it will fetch all of the types
 * @example
 * <DocLaboral /> // This will fetch all the document types
 * <DocLaboral object="NOM" /> // This will fetch only the documents where rh_documento = "NOM"
 */
const DocLaboral = ({ object: tipo_documento }) => {
  const { actualMatch, currentMenu } = useCurrentMenu()
  const [currentIteration, setCurrentIteration] = useState(1)
  const Theme = new ThemeManager()
  const [viewType, setViewType] = useState('TABLE')
  const [selectedDocType, setSelectedDocType] = useState(tipo_documento || null)
  const [ejercicioActivo, setEjercicioActivo] = useState(null)
  const {
    response: { ejercicios: ejercicios_disponibles, tipos_documentos },
    loading,
  } = useFetchLaboralMeta(selectedDocType, currentIteration)

  useEffect(() => {
    if (!loading) {
      setSelectedDocType(tipo_documento)

      setCurrentIteration(currentIteration + 1)
      setEjercicioActivo(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualMatch?.camino, currentMenu, tipo_documento])

  useEffect(() => {
    if (!ejercicioActivo && ejercicios_disponibles && ejercicios_disponibles.length > 0)
      // @ts-ignore
      setEjercicioActivo(ejercicios_disponibles[0].ejercicio)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ejercicios_disponibles?.length, tipo_documento])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '10px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '5px' }}>
          {ejercicios_disponibles ? (
            ejercicios_disponibles.map((value, index) => (
              <Button
                key={index}
                color={ejercicioActivo === value.ejercicio ? 'success' : 'primary'}
                // @ts-ignore
                onClick={() => setEjercicioActivo(value.ejercicio)}
                variant={ejercicioActivo === value.ejercicio ? 'contained' : 'text'}
              >
                {value.ejercicio}
              </Button>
            ))
          ) : (
            <></>
          )}
        </Box>

        {!tipo_documento &&
        tipos_documentos &&
        !Theme.hide?.DocLaboral?.DocumentTypeFilter === true ? (
          <Autocomplete
            options={tipos_documentos ?? []}
            getOptionLabel={(option) => option.d_rh_documento}
            onChange={(event, value) => {
              setSelectedDocType(value.rh_documento)
            }}
            sx={{
              minWidth: '200px',
              ['& .MuiOutlinedInput-root']: {
                p: 0,
                width: '100%',
              },
              [`& .${autocompleteClasses.input}`]: {
                borderRadius: '5px',
                p: '0',
                '&::placeholder': {
                  opacity: 1,
                },
              },
              '& .MuiButtonBase-root': {},
              '& fieldset': { border: 'none', color: 'red', padding: '0px' },
              borderRadius: '5px',
              '&::placeholder': {
                color: 'white',
                fontSize: '1em',
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Tipo de documento"
                label=""
                inputProps={{ ...params.inputProps }}
              />
            )}
          />
        ) : (
          <></>
        )}

        <ButtonGroup>
          <Button
            variant={viewType === 'TABLE' ? 'contained' : 'text'}
            color={viewType === 'Table' ? 'primary' : 'success'}
            onClick={() => {
              setViewType('TABLE')
            }}
          >
            <TableRows />
          </Button>
          <Button
            variant={viewType === 'TABLE' ? 'text' : 'contained'}
            color={viewType === 'Table' ? 'primary' : 'success'}
            onClick={() => {
              setViewType('GRID')
            }}
          >
            <GridView />
          </Button>
        </ButtonGroup>
      </Box>
      {ejercicioActivo ? (
        viewType === 'TABLE' ? (
          <LaboralTableView
            ejercicio={ejercicioActivo}
            document_type={tipo_documento ?? selectedDocType}
          />
        ) : (
          <Laboral ejercicio={ejercicioActivo} document_type={tipo_documento ?? selectedDocType} />
        )
      ) : (
        <Box sx={{ mt: 1 }}>
          <DocLaboralSkeleton />
        </Box>
      )}
    </>
  )
}

export default DocLaboral
