import CustomTable from 'common/components/CustomTable'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useFetchSolicitudesPendientes from '../hooks/useFetchSolicitudesPendientes'

import { setSolicitudSeleccionada } from '../solicitudesSlice'

import ThemeManager from 'theming/ThemeManager'

import { getLocaleFromFullDate } from 'common/libs/fechas'
import { getChipSolicitud } from '../libs/SolicitudesHelpers'
import Table from 'common/components/Table'

const SolicitudesPendientes = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { response, loading, error } = useFetchSolicitudesPendientes()
  const [solicitudes, setSolicitudes] = useState([])

  useEffect(() => {
    setSolicitudes(response)
  }, [response])

  const handleSelectSolicitud = (id) => {
    const solicitud = solicitudes.filter((s) => s.id === Number(id))[0]
    solicitud.fecha = null
    solicitud.fecha_validacion = null
    solicitud.estado = null

    dispatch(setSolicitudSeleccionada(solicitud))
    navigate(`/solicitud/`, { state: { solicitud } })
  }

  const themeManager = new ThemeManager()

  const CustomTableComp = themeManager.activeTheme?.components
    ? (themeManager.activeTheme?.components['CustomTable'] ?? CustomTable)
    : CustomTable

  const [rowsPerPage, setRowsPerPage] = useState(12)

  return (
    <>
      <Table
        onRowClick={(event) => handleSelectSolicitud(event.row.id)}
        dataSource={solicitudes ?? []}
        page={1}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChanged={setRowsPerPage}
        count={solicitudes?.length}
        showInternalSearch={true}
        loading={loading}
        ignoreOldTable={false}
        fields={[
          { title: 'ID', field: 'codigo_solicitud', flex: 0, sortable: true },
          { title: 'Tipo', field: 'd_tipo', flex: 1, sortable: true },
          {
            title: 'Fecha',
            field: 'fecha',
            customGetter: (item) =>
              item?.fecha_creacion ? getLocaleFromFullDate(item?.fecha_creacion) : '',
          },
          {
            title: 'Validación',
            field: 'fecha_validacion',
            customGetter: (item) =>
              item?.row?.status_fecha ? getLocaleFromFullDate(item?.status_fecha) : '',
          },
          {
            field: 'estado',
            title: 'Estado',
            width: 200,
            filterable: false,
            sortable: false,
            customGetter: (item) => {
              return getChipSolicitud(item.status, item.d_status)
            },
          },
        ]}
      />
      {
        // <CustomTableComp
        //   dataSource={solicitudes}
        //   showToolbar={true}
        //   initialPageSize={15}
        //   fields={[
        //     { key: 'ID', value: 'codigo_solicitud', flex: 0, sortable: true },
        //     { key: 'Tipo', value: 'd_tipo', flex: 1, sortable: true },
        //     { key: 'Solicitante', value: 'd_dni', flex: 1, sortable: true },
        //     {
        //       key: 'Fecha',
        //       value: 'fecha',
        //       customGetter: (s) => {
        //         return s.row.fecha_creacion ? getLocaleFromFullDate(s.row.fecha_creacion) : ''
        //       },
        //       flex: 1,
        //       sortable: true,
        //     },
        //     {
        //       key: 'Validación',
        //       value: 'fecha_validacion',
        //       flex: 1,
        //       customGetter: (s) => {
        //         return s.row.status_fecha ? getLocaleFromFullDate(s.row.status_fecha) : ''
        //       },
        //     },
        //     {
        //       value: 'estado',
        //       key: 'Estado',
        //       width: 150,
        //       filterable: false,
        //       sortable: false,
        //       renderCell: (s) => {
        //         return getChipSolicitud(s.status, s.d_status)
        //       },
        //     },
        //   ]}
        //   onClickEvent={(event) => handleSelectSolicitud(event.row.id)}
        //   loading={loading}
        // />
      }
    </>
  )
}

export default SolicitudesPendientes
