/**
 * @type {EdisaTheme}
 */
export const Dark = {
  mode: 'dark',
  menu: {
    background: '#1A2A47', // Darker shade for the menu background
    text: '#ffffff',
    hoverBackground: '#354A71', // Slightly lighter for hover
  },
  primary: {
    main: '#94ABD7',
    contrastText: '#ffffff',
  },
  text: {
    primary: '#ffffff', // Light text for dark backgrounds
  },
  secondary: {
    main: '#77B5DE', // Darker secondary main
    contrastText: '#ffffff',
  },
  accent: {
    main: '#FFA620',
    contrastText: '#ffffff',
  },
  error: {
    main: '#F06053',
    light: '#473C3B', // Darker error light
  },
  warning: {
    main: '#FFA620',
    light: '#4A3B27', // Darker warning light
  },
  success: {
    main: '#62A7AF',
    light: '#3A5053', // Darker success light
    contrastText: '#ffffff',
  },
  background: {
    success: '#3A5053',
    error: '#473C3B',
    warning: '#4A3B27',
    menu: '#1A2A47',
    menuContrast: '#ffffff',
    default: '#1A1A1A', // Dark default background
    paper: '#2B2B2B', // Dark paper background
    alternative: '#2E4B84',
    lighter: '#3D3D3D', // Slightly lighter gray
    alternativeContrastText: '#ffffff',
    nav: 'transparent',
    primary80: '#3A4A6B', // Darker toned down primary80
  },
  notifications: {
    background: '#4A3B27',
    text: '#FFA620',
  },
}

/**
 * @type {EdisaTheme}
 */
export const Light = {
  mode: 'light',
  menu: {
    background: '#426BBA',
    text: '#fff',
    hoverBackground: '#2E4B84',
  },
  primary: {
    main: '#2E4B84',
    // contrastText: '#2E4B84',
  },
  text: {
    primary: '#2E4B84',
  },
  secondary: {
    main: '#426BBA',
    contrastText: '#fff',
  },
  accent: { main: '#FFA620', contrastText: '#ffffff' },
  error: {
    main: '#F06053',
    light: '#FEF0EF',
  },
  warning: {
    main: '#FFA620',
    light: '#F9E8CB',
  },
  success: {
    main: '#62A7AF',
    light: '#F1F7F7',
    contrastText: '#ffffff',
  },
  background: {
    success: '#F1F7F7',
    error: '#FEF0EF',
    warning: '#F9E8CB',
    menu: '#426bba',
    menuContrast: '#fff',
    default: '#fff',
    paper: '#ECF0F8',
    alternative: '#426bba',
    lighter: '#dcdde0',
    alternativeContrastText: '#fff',
    nav: 'transparent',
    primary80: '#7B97CF',
  },
  notifications: {
    background: '#F9E8CA',
    text: '#426bba',
  },
}

export const selectTheme = (mode = 'light') => {
  return mode === 'dark' ? Dark : Light
}
