/**
 * @module CommonComponents
 */

import { LinearProgress } from '@mui/material'
import { Box } from '@mui/system'
import ThemeManager from 'theming/ThemeManager'

/**
 * A generic table based on MUI DataGrid but for simple use
 * @param {SimpleTableProps} props
 */
const SimpleTable = ({
  dataSource,
  fields,
  onRowClick,
  header_style = {},
  header_cell_style = {},
  generic_cell_style = {},
  table_style = {},
  container_style = {},
  body_style = {},
  hideHeader = false,
  loading = false,
  debug = false,
  stripped = false,
  emptyText = 'No hay datos',
}) => {
  const Theme = new ThemeManager()

  const theme_container_style = Theme?.options?.SimpleTable?.container_style ?? {}
  const theme_table_style = Theme?.options?.SimpleTable?.table_style ?? {}
  const theme_header_style = Theme?.options?.SimpleTable?.header_style ?? {}
  const theme_header_cell_style = Theme?.options?.SimpleTable?.header_cell_style ?? {}
  const theme_body_style = Theme?.options?.SimpleTable?.body_style ?? {}
  const theme_row_style = Theme?.options?.SimpleTable?.row_style ?? {}
  const theme_generic_cell_style = Theme?.options?.SimpleTable?.generic_cell_style ?? {}

  if (debug) console.table(dataSource)
  return (
    <div
      className="simple-table"
      style={{ overflowX: 'auto', ...container_style, ...theme_container_style }}
    >
      <table
        style={{
          tableLayout: 'fixed',
          minWidth: '100%',
          borderCollapse: 'collapse',
          ...table_style,
          ...theme_table_style,
          display: dataSource?.length > 0 ? 'table' : 'grid',
        }}
      >
        {!hideHeader ? (
          <thead
            style={{
              backgroundColor: 'transparent',
              color: Theme.pallete.text.primary,
              borderBottom: '1px solid',
              ...header_style,
              ...theme_header_style,
            }}
          >
            <tr>
              {fields?.map((field, idx) => (
                <th
                  style={{
                    textAlign: 'left',
                    padding: '8px',
                    fontWeight: '500',
                    ...header_cell_style,
                    ...field.headerStyle,
                    ...theme_header_cell_style,
                  }}
                  key={idx}
                >
                  {field.title}
                </th>
              ))}
            </tr>
          </thead>
        ) : (
          <></>
        )}
        <tbody
          style={{
            ...body_style,
            ...theme_body_style,
            position: dataSource?.length > 0 ? 'initial' : 'relative',
            display: dataSource?.length > 0 ? 'table-row-group' : 'block',
            width: dataSource?.length > 0 ? 'initial' : '100%',
          }}
        >
          {dataSource?.length > 0 ? (
            dataSource.map((row, idx) => {
              return (
                <tr
                  style={{
                    borderBottom: '1px solid lightgrey',
                    backgroundColor:
                      idx % 2 !== 0 && stripped ? Theme.pallete.background.paper : 'initial',
                    cursor: onRowClick ? 'pointer' : 'initial',
                    ...theme_row_style,
                  }}
                  key={idx}
                  onClick={() => (onRowClick ? onRowClick({ row }) : null)}
                >
                  {fields.map((field, index) => {
                    return (
                      <td
                        style={{
                          padding: '8px',
                          ...generic_cell_style,
                          ...field.cellStyle,
                          ...theme_generic_cell_style,
                          [':hover']: {
                            bacgroundColor: 'red',
                          },
                        }}
                        key={`field${index}`}
                      >
                        {field.customGetter ? field.customGetter(row) : row[field.field]}
                      </td>
                    )
                  })}
                </tr>
              )
            })
          ) : (
            <tr>
              <td>
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    top: '0',
                    left: '0',
                    textAlign: 'center',
                    padding: '10px',
                    fontSize: '1.5em',
                    opacity: '.4',
                    fontWeight: 'bold',
                  }}
                >
                  {loading || !dataSource ? (
                    <Box sx={{ p: 5 }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <>{emptyText}</>
                  )}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default SimpleTable

/**
 * @typedef SimpleTableField
 * @prop {string} title
 * @prop {string} field
 * @prop {function(item):any} [customGetter]
 * @prop {React.CSSProperties} [cellStyle]
 * @prop {React.CSSProperties} [headerStyle]
 */

/**
 * @typedef SimpleTableProps
 * @prop {Array<Object>} dataSource
 * @prop {Array<SimpleTableField>} fields
 * @prop {React.CSSProperties} [header_style]
 * @prop {React.CSSProperties} [row_style]
 * @prop {React.CSSProperties} [body_style]
 * @prop {React.CSSProperties} [generic_cell_style]
 * @prop {React.CSSProperties} [header_cell_style]
 * @prop {React.CSSProperties} [table_style]
 * @prop {React.CSSProperties} [container_style]
 * @prop {boolean} [stripped]
 * @prop {boolean} [hideHeader=false]
 * @prop {string} [emptyText="No hay datos"]
 * @prop {(row:Object) => void} [onRowClick]
 */
