import { Typography } from '@mui/material'

const LabelComponent = ({ text }) => {
  return (
    <Typography
      id="solicitud-label-component"
      variant="h3"
      sx={{ mb: 1, fontSize: '1rem', ml: 0, fontWeight: '500' }}
    >
      {text}
    </Typography>
  )
}

export default LabelComponent
