import { Logout, MedicalServices, Restaurant, Stop } from '@mui/icons-material'

/**
 * @type { import('@mui/material/OverridableComponent').OverridableComponent<import('@mui/material').SvgIconTypeMap<{}, "svg">>}
 */
export const tiposSalidaMap = {
  '00SA': Logout,
  '03': MedicalServices,
  '05': Restaurant,
  DEFAULT: Stop,
}

export const colorSalidaMap = {
  '00SA': 'error',
  '03': 'warning',
  '05': 'primary',
  DEFAULT: 'error',
}
