import { Box, Button } from '@mui/material'
import Table from 'common/components/Table'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import DataTableSkeleton from 'features/solicitudes/components/skeletons/DataTableSkeleton'
import { useContext, useEffect, useMemo, useState } from 'react'
import useFetchInformeData from '../hooks/useFetchInformeData'
import { InformesContext } from '../providers/InformesProvider'
import InformePluginButton from './InformePluginButton'

const InformeDataTable = () => {
  const { handleModal } = useContext(CustomModalContext)
  const {
    active: {
      current,
      structure,
      activeFilter,
      setActiveFilter,
      iteration,
      page,
      forceRefresh,
      setPage,
      hasEmptyForced,
    },
  } = useContext(InformesContext)
  const [tableStructure, setTableStructure] = useState()
  const [pageSize, setPageSize] = useState(12)

  const { response, loading, error } = useFetchInformeData({
    nombre_informe: current?.nombre_informe ?? null,
    filtro: activeFilter,
    limit: pageSize,
    page: page,
    iteration: iteration,
  })

  const [hashedResponse, setHashedResponse] = useState({})

  useEffect(() => {
    if (!error) return
    setDataSource([])
  }, [error])

  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    const newHash = { ...hashedResponse }
    if (hashedResponse[page]?.length > 0) return

    console.log({ response, page, newHash })

    newHash[page] = response?.registros ?? []
    setHashedResponse(newHash)
  }, [response])

  useEffect(() => {
    console.log({ activeFilter })
    setHashedResponse({})
    setPage(1)
  }, [activeFilter])

  const data = useMemo(() => {
    const newVal = Object.values(hashedResponse)?.flat() || []
    return newVal?.[0] ? newVal : []
  }, [hashedResponse])

  useEffect(() => {
    handleModal({ enabled: loading, type: ModalTypes.Loading })
  }, [loading])

  useEffect(() => {
    setTableStructure(null)
    if (!structure?.response?.columnas) return

    /** @type {Array<import('common/components/SimpleTable').SimpleTableField>} **/
    const newFields = structure.response.columnas.filter((item) => {
      return item.ancho_web > 0
    })

    const maxCount = structure?.response?.plugins?.length ?? 0

    let fields = newFields.map((item, index) => {
      const width =
        maxCount !== 0
          ? `calc(${item.ancho_web}% - ${Math.ceil(35 / (newFields.length + 1))}px)`
          : `${item.ancho_web}`

      return {
        title: item.titulo_columna,
        field: item.alias_columna,
        cellStyle: {
          padding: '5px',
          fontSize: '.9em',
          width,
          maxWidth: width,
        },
        headerStyle: {
          textAlign: 'left',
          // whiteSpace: 'nowrap',
          padding: '5px',
          fontSize: '.9em',
          width,
          maxWidth: width,
        },
      }
    })

    if (maxCount !== 0) {
      fields = [
        {
          cellStyle: {
            padding: '10px 5px',
            display: 'block',
            fontSize: '.9em',
            width: `35px`,
          },
          headerStyle: {
            textAlign: 'left',
            // whiteSpace: 'nowrap',
            padding: '5px',
            fontSize: '.9em',
            width: `35px`,
            display: 'block',
          },
          customGetter: (row) => (
            <>
              <InformePluginButton plugins={structure?.response?.plugins ?? []} element={row} />
            </>
          ),
          title: ' ',
        },
        ...fields,
      ]
    }

    setTableStructure(fields)
  }, [structure.response])

  return (
    <>
      {tableStructure && !structure.loading ? (
        <Box sx={{ display: tableStructure && !structure?.loading ? 'initial' : 'none' }}>
          <Table
            fields={tableStructure}
            dataSource={data ?? []}
            stripped
            count={response?.numero_registros}
            page={page}
            loading={loading || structure.loading}
            onPageChanged={(page) => {
              if (hashedResponse[page]) return
              setPage(page)
            }}
            rowsPerPage={pageSize}
            onRowsPerPageChanged={(value) => {
              setPageSize(value)
              setPage(1)
              setHashedResponse({})
              forceRefresh()
            }}
            header_cell_style={{ p: 2 }}
            emptyText={
              error && hasEmptyForced
                ? 'Por favor rellena los filtros obligatorios (*)'
                : error
                  ? 'Ha ocurrido un error'
                  : loading
                    ? 'Cargando'
                    : 'No hay datos'
            }
          />
        </Box>
      ) : current?.nombre_informe ? (
        <DataTableSkeleton />
      ) : (
        <></>
      )}
    </>
  )
}

export default InformeDataTable
