import { Button, Divider, Drawer, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import CustomGrid from 'common/components/CustomGrid'
import CorporateDocsCard from 'features/documentos/components/CorporateDocsCard'
import PersonalDocCard from 'features/documentos/components/PersonalDocCard'
import useFetchArbol from 'features/documentos/hooks/useFetchArbol'
import useFetchLevelDocs from 'features/documentos/hooks/useFetchLevelDocs'
import Icon from 'icons/Icons'
import { useState } from 'react'
import CorporateTreeSkeleton from '../components/CorporateTreeSkeleton'
import PersonalDocTree from '../components/PersonalDocTree'

const DocPersonal = ({ forcedLevel }) => {
  const [selectedNode, setSelectedNode] = useState(forcedLevel ?? null)
  const [open, setOpen] = useState(false)
  const { response: tree, loading: loadingTree, error } = useFetchArbol({})

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }

  const { response: responseDocs, loading } = useFetchLevelDocs(selectedNode)
  return (
    <Box sx={{ height: '100%' }}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: {
            xs: 'initial',
            md: 'none',
          },
        }}
      >
        <IconButton onClick={toggleDrawer(true)} aria-label="Expander arbol">
          <Icon icon="fi-rr-menu-burger" />
        </IconButton>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          <Box sx={{ minWidth: '300px', maxWidth: '400px' }}>
            <PersonalDocTree
              tree={tree}
              onNodeSelected={(nodeLevel) => {
                setSelectedNode(nodeLevel)
              }}
            />
          </Box>
        </Drawer>
      </Box>
      <Box sx={{ display: 'flex', gap: 2, height: '100%' }}>
        {loadingTree ? (
          <CorporateTreeSkeleton />
        ) : (
          <Box sx={{ display: { xs: 'none', md: 'block' }, minWidth: '350px' }}>
            <PersonalDocTree
              tree={tree}
              onNodeSelected={(nodeLevel) => setSelectedNode(nodeLevel)}
            />
          </Box>
        )}
        <Divider
          sx={{ display: { xs: 'none', md: 'initial' } }}
          orientation="vertical"
          variant="middle"
          flexItem
        />
        <Box sx={{ width: '100%' }}>
          <CustomGrid
            showInternalSearch={false}
            gridProps={{ spacing: 2 }}
            gridItemsProps={{ size: { xs: 12, md: 4, lg: 3 } }}
            dataSource={responseDocs}
            loading={loading}
            CardLayout={({ data }) => {
              return <PersonalDocCard document={data} />
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default DocPersonal
