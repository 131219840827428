import CustomTable from './modifications/components/CustomTable'
import LoginBackground from './modifications/components/LoginBackground'
import MenuDrawer from './modifications/components/MenuDrawer'
import News from './modifications/components/News'
import CustomMap from './modifications/features/control_presencia/components/CustomMap'
import LastFichajesTable from './modifications/features/control_presencia/components/LastFichajesTable'
import PresenceActions from './modifications/features/control_presencia/components/PresenceActions'
import WorkerData from './modifications/features/datos_trabajador/components/WorkerData'
import DocumentCard from './modifications/features/documentacion/components/DocumentCard'
import LaboralDocumentCard from './modifications/features/documentacion/components/LaboralDocumentCard'
import CorporateDocumentCard from './modifications/features/documentacion/components/CorporateDocumentCard.jsx'
import CamposSolicitud from './modifications/features/solicitudes/components/CamposSolicitud'
import PersonalDocumentCard from './modifications/features/documentacion/components/PersonalDocumentCard'

/** @type {ThemeComponents}*/
const components = {
  LoginBackground: LoginBackground,
  MenuDrawer: MenuDrawer,
  News: News,
  CamposSolicitud: CamposSolicitud,
  CustomTable: CustomTable,
  LastFichajesTable: LastFichajesTable,
  PresenceActions: PresenceActions,
  CustomMap: CustomMap,
  WorkerData: WorkerData,
  DocumentacionCard: DocumentCard,
  LaboralDocumentCard: LaboralDocumentCard,
  CorporateDocsCard: CorporateDocumentCard,
  PersonalDocumentCard: PersonalDocumentCard,
}

export default components
