import { Paper, Skeleton } from '@mui/material'
import React from 'react'

export default function TableSkeleton() {
  return (
    <Paper sx={{ p: 3 }}>
      <Skeleton sx={{ height: '80px' }} />
      <Skeleton sx={{ height: '80px' }} />
      <Skeleton sx={{ height: '80px' }} />
      <Skeleton sx={{ height: '80px' }} />
      <Skeleton sx={{ height: '80px' }} />
      <Skeleton sx={{ height: '80px' }} />
      <Skeleton sx={{ height: '80px' }} />
      <Skeleton sx={{ height: '80px' }} />
    </Paper>
  )
}
