import { Alert, Button, Skeleton } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { Box } from '@mui/system'
import { useIntersectionObserver } from 'common/hooks/useIntercectionObserver'
import useFetchNews from 'features/noticias/hooks/useFetchNews'
import { useEffect, useRef, useState } from 'react'
import ThemeManager from 'theming/ThemeManager'
import NewsFilter from './NewsFilter'
import News from './News'
import { useNavigate } from 'react-router-dom'

/**
 * A component to show a list of News
 *
 * @param {Object} props
 * @param {number} [props.rowCount = 2] - The ammount of news per row
 * @param {number} [props.spacing = 2] - The ammount of space between news
 * @param {boolean} [props.showFilter = true] - Show the filter bar
 * @param {boolean} [props.noPaginate = false] - Show the filter bar
 * @param {boolean} [props.showPaginateButton = false] - Show the filter bar
 * @param {boolean} [props.allBanner = false] - If set to true all elements will be drown as the banner - Prefered rowcount 1
 * @param {number} [props.pageSize = false] - Show the filter bar
 * @returns {import('react').ReactElement} the react element
 * @example
 *  const newsPerRow = 2
 *  return (
 *    <NewsList rowCount={newsPerRow} spacing={2}/>
 *  )
 */
const NewsList = ({
  rowCount = 3,
  spacing = 2,
  showFilter = true,
  noPaginate = false,
  showPaginateButton = false,
  pageSize = 12,
  allBanner = false,
}) => {
  const Theme = new ThemeManager()
  const NewsTheme = Theme?.components?.NewsList
  /**  @type {import('react').MutableRefObject<HTMLDivElement |undefined>  }*/
  const scrollBottom = useRef()
  // @ts-ignore
  const { isVisible } = useIntersectionObserver(scrollBottom)
  const [selectedCategory, setSelectedCategory] = useState()
  // @ts-ignore
  const [selectedTags, setSelectedTags] = useState([])
  const navigate = useNavigate()

  const [lastPage, setLastPage] = useState(0)
  const [page, setPage] = useState(1)
  const { response, loading, error } = useFetchNews(page, selectedCategory, selectedTags, pageSize)

  const theme_count = Theme.options?.newsOptions?.rowCount ?? rowCount

  const [newsList, setNewsList] = useState(response?.results ?? [])

  useEffect(() => {
    if (isVisible && response?.next && newsList && !noPaginate) {
      setPage(page + 1)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  useEffect(() => {
    if (response?.results && !loading) {
      setLastPage(page)
      // if (!response.prev) setNewsList([...response?.results])
      setNewsList(response.previous ? [...newsList, ...response.results] : [...response.results])
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, loading])

  const ROW_COUNT = Math.ceil(12 / theme_count)
  const SMALL_ROW_COUNT = Math.ceil(12 / (theme_count - 1))
  const FIRST_NEWS_BANNER = Theme?.options?.newsOptions?.firstNewsBanner ?? true

  const apply = Theme.options.apply ?? false

  const NewsComponent = Theme?.components ? (Theme?.components['News'] ?? News) : News

  return (
    NewsTheme ?? (
      <>
        {showFilter && newsList && !error ? (
          <Box sx={{ pointerEvents: loading ? 'none' : 'all' }}>
            <NewsFilter
              selectedTags={selectedTags}
              onTagSelected={(tag) => {
                if (tag) {
                  setPage(1)
                  setLastPage(0)
                  setSelectedTags(
                    selectedTags.includes(tag)
                      ? selectedTags.filter((i) => i !== tag)
                      : [...selectedTags, tag],
                  )
                }
              }}
              onCategorySelected={(category) => {
                setSelectedCategory(category ?? undefined)
              }}
            />
          </Box>
        ) : (
          <></>
        )}
        {showFilter && loading && !newsList ? (
          <Skeleton
            variant="rounded"
            sx={{ display: 'flex', width: '35ch', height: '3.5em', mb: 7, ml: 'auto' }}
          />
        ) : (
          <></>
        )}
        {error ? (
          <Alert variant="outlined" severity="error">
            Ha ocurrido un error y no se han podido cargar las noticias
          </Alert>
        ) : (
          <>
            <Grid container spacing={spacing}>
              {newsList.map((news, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    size={{
                      xs: 12,
                      sm:
                        FIRST_NEWS_BANNER && index === 0
                          ? 12
                          : SMALL_ROW_COUNT < 6
                            ? 6
                            : SMALL_ROW_COUNT,
                      lg: FIRST_NEWS_BANNER && index === 0 ? 12 : ROW_COUNT,
                    }}
                  >
                    <NewsComponent
                      makeBanner={(index === 0 && FIRST_NEWS_BANNER) || allBanner}
                      news={news}
                    />
                  </Grid>
                )
              })}

              {loading ? (
                [...Array(10).keys()].map((i) => {
                  return (
                    <Grid
                      key={i}
                      item
                      size={{
                        xs: 12,
                        sm: Math.ceil(SMALL_ROW_COUNT / 12 >= 2 ? SMALL_ROW_COUNT : 2),
                        lg: ROW_COUNT,
                      }}
                    >
                      <Skeleton
                        variant="rounded"
                        sx={{
                          aspectRatio: '16 / 9',
                          display: 'flex',
                          width: '100%',
                          height: 'auto',
                          mb: 0,
                        }}
                      />
                      <Skeleton sx={{ mt: 1 }} variant="rounded" width="13em" height="1em" />
                      <Skeleton sx={{ mt: 1 }} variant="rounded" width="18em" height="1.0em" />
                      <Skeleton sx={{ mt: 1.4 }} variant="rounded" width="100%" height="1.0em" />
                      <Skeleton sx={{ mt: 1 }} variant="rounded" width="80%" height="1.0em" />
                      <Skeleton sx={{ mt: 1 }} variant="rounded" width="50%" height="1.0em" />
                      <Skeleton
                        sx={{ mt: 1, display: 'block', ml: 'auto' }}
                        variant="rounded"
                        width="6em"
                        height="2.0em"
                      />
                    </Grid>
                  )
                })
              ) : (
                <></>
              )}
            </Grid>

            <div
              // @ts-ignore
              ref={scrollBottom}
              style={{ marginTop: `${spacing * 8}px` }}
            ></div>
            {loading ? (
              <Grid container spacing={spacing}>
                {[...Array(10).keys()].map((i) => {
                  return (
                    <Grid
                      key={i}
                      item
                      size={{
                        xs: 12,
                        sm: Math.ceil(SMALL_ROW_COUNT / 12 >= 2 ? SMALL_ROW_COUNT : 2),
                        lg: ROW_COUNT,
                      }}
                    >
                      <Skeleton
                        variant="rounded"
                        sx={{
                          aspectRatio: '16 / 9',
                          display: 'flex',
                          width: '100%',
                          height: 'auto',
                          mb: 0,
                        }}
                      />
                      <Skeleton sx={{ mt: 1 }} variant="rounded" width="13em" height="1em" />
                      <Skeleton sx={{ mt: 1 }} variant="rounded" width="18em" height="1.0em" />
                      <Skeleton sx={{ mt: 1.4 }} variant="rounded" width="100%" height="1.0em" />
                      <Skeleton sx={{ mt: 1 }} variant="rounded" width="80%" height="1.0em" />
                      <Skeleton sx={{ mt: 1 }} variant="rounded" width="50%" height="1.0em" />
                      <Skeleton
                        sx={{ mt: 1, display: 'block', ml: 'auto' }}
                        variant="rounded"
                        width="6em"
                        height="2.0em"
                      />
                    </Grid>
                  )
                })}
              </Grid>
            ) : (
              <>
                {showPaginateButton ? (
                  <Button
                    onClick={() => {
                      navigate('/noticias')
                    }}
                    sx={{ display: 'block', background: 'transparent', width: '300px', m: 'auto' }}
                  >
                    Ver todas las noticias
                  </Button>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </>
    )
  )
}

export default NewsList
