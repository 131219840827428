/* eslint-disable react-hooks/exhaustive-deps */
import { Map, PlayArrow, Stop } from '@mui/icons-material'
import AlarmIcon from '@mui/icons-material/Alarm'
import {
  Alert,
  AlertTitle,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  CircularProgress,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Typography,
} from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import useFetchTipoSalida from 'features/control_presencia/hooks/useFetchTipoSalida'
import { enqueueSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import ThemeManager from 'theming/ThemeManager'
import CustomMap from '../components/CustomMap'
import LastFichajesTable from '../components/LastFichajesTable'
import PresenceActions from '../components/PresenceActions'
import useFetchLastFichajes from '../hooks/useFetchLastFichajes'
import FichajesController, { PresenceControlActions } from '../libs/FichajesController'
import { tiposSalidaMap } from '../libs/tiposSalidaMap'

/**
 * @module ControlPresencia
 */

/**
 * This component works as a page for the Presence Control
 * to mark enters and leave
 * @example
 *
 * <Fichajes />
 *
 * @returns {JSX.Element}
 */
const Fichajes = () => {
  /**
   * The tipes of modal
   * @readonly
   * @enum {string}
   */
  const MovilView = {
    Map: 'Map',
    Records: 'Records',
  }
  const { response: tiposSalida } = useFetchTipoSalida()
  const [lastIteration, setLastIteration] = useState(0)
  const { response, loading, error } = useFetchLastFichajes(lastIteration)
  const { handleModal, modal } = useContext(CustomModalContext)
  const [mobileSection, setMobileSection] = useState(MovilView.Map)
  const [openBackdrop, setOpenBackdrop] = useState(false)
  /**
   * Mark a presence control registry
   * @async
   * @param {PresenceControlActions} type - The tipe of action to perform
   * @param {string} [exitCode] - If is a exit type this param must be provided
   * @returns {Promise<void>}
   * */
  async function markAction(type, exitCode) {
    try {
      setOpenBackdrop(true)
      await FichajesController.create(type, exitCode)
      setLastIteration(lastIteration + 1)
      enqueueSnackbar('Guardado correctamente', {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error', {
        variant: 'error',
      })
      console.error(error)
    } finally {
      setOpenBackdrop(false)
    }
  }

  const themeManager = new ThemeManager()

  const PresenceActionsComp =
    themeManager.activeTheme?.components?.PresenceActions ?? PresenceActions

  const LastFichajesTableComp =
    themeManager.activeTheme?.components?.LastFichajesTable ?? LastFichajesTable

  const CustomMapComp = themeManager.activeTheme?.components?.CustomMap ?? CustomMap

  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: 'column',
          gap: '20px',
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
        <Typography>Guardando Fichaje</Typography>
      </Backdrop>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%' }}>
        <Box sx={{ flexGrow: 0, display: { xs: 'none', sm: 'flex' } }}>
          <PresenceActionsComp
            loading={loading}
            setLastIteration={setLastIteration}
            lastIteration={lastIteration}
            handleModal={handleModal}
            modal={modal}
          />
        </Box>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}>
          {error ? (
            <Alert severity="error">
              <AlertTitle>Error de conexión</AlertTitle>
              No se han podido obtener los fichajes anteriores
            </Alert>
          ) : (
            <></>
          )}
          <LastFichajesTableComp
            response={response}
            sx={{
              display: {
                sm: 'initial',
                xs: mobileSection === MovilView.Records ? 'initial' : 'none',
              },
              flexGrow: '1 1 0',
              width: { sm: '50%', md: '30%' },
            }}
            loading={loading}
          />
          <Box
            sx={{
              display: {
                sm: 'initial',
                xs: mobileSection === MovilView.Map ? 'initial' : 'none',
              },
              flex: '1 1 0',
              width: { sm: '50%', md: '30%' },
              '&  .map': {
                // width: "100%",
                height: '100%',
                zIndex: 2,
              },
            }}
          >
            <CustomMapComp />
          </Box>
        </Box>
      </Box>
      <BottomNavigation
        showLabels
        onChange={(event, value) => {
          console.log(value)
          setMobileSection(value)
        }}
        value={mobileSection}
        sx={{
          display: {
            xs: 'flex',
            sm: 'none',
          },
          width: '100%',
          position: 'fixed',
          justifyContent: 'space-evenly',
          bottom: 0,
          left: 0,
          zIndex: 3,
        }}
      >
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: 'absolute', bottom: 25, left: '50%', transform: 'translate(-50%, 0)' }}
          icon={<SpeedDialIcon />}
        >
          <SpeedDialAction
            icon={<PlayArrow />}
            tooltipOpen
            onClick={async () => {
              markAction(PresenceControlActions.Entrada)
            }}
            tooltipTitle={'Entrada'}
          />
          {tiposSalida ? (
            tiposSalida.map((tipo, idx) => {
              const Icon = tiposSalidaMap[tipo.codigo] || tiposSalidaMap.DEFAULT
              return (
                <SpeedDialAction
                  key={idx}
                  icon={<Icon />}
                  sx={{
                    '& .MuiSpeedDialAction-staticTooltipLabel': {
                      whiteSpace: 'nowrap',
                    },
                  }}
                  tooltipOpen
                  onClick={async () => {
                    markAction(PresenceControlActions.Salida, tipo.codigo)
                  }}
                  tooltipTitle={tipo.descripcion}
                />
              )
            })
          ) : (
            <SpeedDialAction
              icon={<Stop />}
              tooltipOpen
              onClick={async () => {
                markAction(PresenceControlActions.Salida)
              }}
              tooltipTitle={'Salida'}
            />
          )}
        </SpeedDial>
        <BottomNavigationAction
          sx={{ width: '100%' }}
          label="Mapa"
          value={MovilView.Map}
          icon={<Map />}
        />
        <BottomNavigationAction
          sx={{ width: '100%' }}
          label="Fichajes"
          value={MovilView.Records}
          icon={<AlarmIcon />}
        />
      </BottomNavigation>
    </>
  )
}

export default Fichajes
