/**
 * @module Notifications
 */

import { Drafts, Mail } from '@mui/icons-material'
import Check from '@mui/icons-material/Check'
import { Box, Button, ButtonGroup, Grid, Skeleton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import ScrollDetector from 'common/components/ScrollDetector'
import { CustomModalContext } from 'common/contexts/CustomModalContext'
import { ModalTypes } from 'common/hooks/useCustomModal'
import { numericElementMap } from 'common/libs/numericMap'
import { useContext, useEffect, useState } from 'react'
import NotificationCard from '../components/NotificationsCard'
import useFetchNotifications from '../hooks/useFetchNotifications'
import NotificationsController from '../lib/NotificationsController'
import { NotificationsContext } from '../providers/NotificationsProvider'

/**
 * This is the page to view all the notifications
 * @component
 *
 * @returns {JSX.Element}
 */
const NotificationsPage = () => {
  const [iteration, setIteration] = useState(0)
  const { handleModal } = useContext(CustomModalContext)
  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(0)
  const [showUnread, setShowUnread] = useState(false)
  const { response, loading } = useFetchNotifications({
    page,
    all: !showUnread,
    page_size: 20,
    iteration,
  })
  const [notifications, setNotifications] = useState(response?.results ?? [])
  const { forceRefresh } = useContext(NotificationsContext)

  useEffect(() => {
    if (!loading && response?.results && page !== lastPage) {
      if (response.next) setLastPage(page)
      forceRefresh()

      setNotifications(
        response.previous ? [...notifications, ...response.results] : [...response.results],
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    if (page !== 1) return
    setLastPage(0)
    setNotifications([])
  }, [page])

  /**
   * Change if you want to show only the
   * unread notifications
   *
   * @param {boolean} unread - The future state of the view
   */
  const changeView = (unread) => {
    setPage(1)
    setShowUnread(unread)
    setNotifications([])
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            mb: 1,
            display: 'flex',
            // flexDirection: {
            //   xs: 'row',
            //   sm: 'column',
            // },
            flexDirection: 'column',
            justifyContent: {
              xs: 'space-between',
              sm: 'initial',
            },
            alignItems: {
              xs: 'center',
              sm: 'flex-start',
            },
          }}
        >
          <ButtonGroup sx={{ width: '100%' }}>
            <Button
              sx={{ width: '100%' }}
              disableElevation
              startIcon={<Mail />}
              variant={showUnread ? 'contained' : 'outlined'}
              onClick={() => {
                changeView(true)
              }}
            >
              No leidas
            </Button>
            <Button
              sx={{ width: '100%' }}
              disableElevation
              startIcon={<Drafts />}
              variant={!showUnread ? 'contained' : 'outlined'}
              onClick={() => {
                changeView(false)
              }}
            >
              Todas
            </Button>
          </ButtonGroup>
        </Box>
        <Button
          sx={{ width: '100%' }}
          startIcon={<Check />}
          onClick={async () => {
            handleModal({ type: ModalTypes.Loading, enabled: true })
            await NotificationsController.readInBulk().then(() => {
              console.log('THEN')
              if (forceRefresh) forceRefresh()
            })
            setIteration(iteration + 1)
            setNotifications([])
            setLastPage(0)
            setPage(1)
            handleModal({ type: ModalTypes.Loading, enabled: false })
          }}
          variant="outlined"
        >
          Marcar todas como leido
        </Button>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Box>
          <Stack direction="column" spacing={1} sx={{ mt: 1 }}>
            {notifications.map((notification, idx) => {
              return <NotificationCard key={idx} notification={notification} reverse={true} />
            })}
            {!loading && notifications.length === 0 ? (
              <Box sx={{ p: 3, pt: 3 }}>
                <Typography
                  variant="h1"
                  sx={{ opacity: 0.3, fontSize: '1.5em', fontWeight: 'bold', textAlign: 'left' }}
                >
                  No hay notificaciones pendiente
                </Typography>
              </Box>
            ) : (
              <></>
            )}
            {loading ? (
              <>
                {numericElementMap(5, NotificationsSkeleton, (idx) => {
                  return {
                    width: idx % 2 === 0 ? 47 : 62,
                  }
                })}
              </>
            ) : (
              <></>
            )}
          </Stack>
          <ScrollDetector
            show={!loading && response?.next && page === lastPage}
            onElementIsVisible={() => {
              if (response?.next) setPage(page + 1)
            }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

/**
 * A simple Skeleton for the notifications
 * @component
 *
 * @param {Object} props - The props of the element
 * @param {number} [props.width] - The with of the content skeleton
 * @param {boolean} [props.reverse] - Reverse the order of the icons
 * @returns {JSX.Element}
 */
export const NotificationsSkeleton = ({ width = 47, reverse = false }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        gap: '5px',
        alignItems: 'center',
        flexDirection: reverse ? 'row-reverse' : 'row',
      }}
    >
      <Box sx={{ p: '5px' }}>
        <Skeleton variant="circular" width="30px" height="30px" />
      </Box>
      <Box sx={{ width: '100%' }}>
        <Skeleton width="20%" height="15px" variant="text" />
        <Skeleton width={`${width}%`} variant="text" />
      </Box>
    </Box>
  )
}

export default NotificationsPage
