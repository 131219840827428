import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid2'
import List from '@mui/material/List'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import LaboralDocumentCard from 'features/documentos/components/LaboralDocumentCard'
import ThemeManager from 'theming/ThemeManager'

const BuscadorDocLaboral = ({ cargando, resultados }) => {
  const Theme = new ThemeManager()

  const compact = false
  const FULL_WIDTH_ITEM = Theme.options?.documentacionOptions?.fullWidthItem
  const GRID_SPACING = Theme.options?.documentacionOptions?.spacing ?? 2
  const CustomCard = Theme.components?.LaboralDocumentCard

  return (
    <Box sx={{ bgcolor: 'background.paper', padding: 1 }}>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {cargando ? (
          <Box key="documentos" sx={{ flexGrow: compact ? 0 : 1 }}>
            <Grid container spacing={compact ? 0.1 : GRID_SPACING}>
              {[...Array(8).keys()].map((index) => (
                <Grid
                  key={index}
                  item
                  size={{
                    xs: 12,
                    sm: FULL_WIDTH_ITEM ? 12 : 6,
                    md: FULL_WIDTH_ITEM ? 12 : 3,
                  }}
                >
                  <Skeleton variant="rounded" height={100} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : resultados ? (
          resultados.length === 0 ? (
            <Typography variant="body1">No se han encontrado resultados</Typography>
          ) : (
            <Box key="documentos" sx={{ flexGrow: compact ? 0 : 1 }}>
              <Grid container spacing={compact ? 0.1 : GRID_SPACING}>
                {resultados.length > 0 ? (
                  resultados?.map((document, idx) => (
                    <Grid
                      key={idx}
                      item
                      size={{
                        xs: 12,
                        sm: FULL_WIDTH_ITEM ? 12 : 6,
                        md: FULL_WIDTH_ITEM ? 12 : 4,
                      }}
                    >
                      {CustomCard ? (
                        <CustomCard document={document} compact={compact} />
                      ) : (
                        <LaboralDocumentCard document={document} compact={compact} />
                      )}
                    </Grid>
                  ))
                ) : (
                  <></>
                )}
              </Grid>
            </Box>
          )
        ) : (
          <></>
        )}
      </List>
    </Box>
  )
}

export default BuscadorDocLaboral
