import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'
/**
 * @module CommonComponents
 */

/**
 * Callback executed when the page change
 * @callback CustomTableOnPageChange
 * @param {import('@mui/x-data-grid').GridPaginationModel} model
 * @param {import('@mui/x-data-grid').GridCallbackDetails<any>} model
 * @returns {void}
 */

/**
 * A reusable table component using MUI DataGrid
 * @param {Object} config The react props
 * @param {Array<Object>} config.dataSource,
 * @param {string} [config.rowid] the field name to get the rowid
 * @param {boolean} [config.checkbox] the table will have a checkbox
 * @param {boolean} [config.selectOnClick] The user can select on click
 * @param {number} [config.initialPageSize] Initial Page Size
 * @param {boolean} [config.hideFooterPagination] Hide the footer pagination
 * @param {boolean}  [config.hideFooter] Hide the footer
 * @param {onClickEvent} [config.onClickEvent] On Click Callback Send (params, event, details)
 * @param {Object} [config.headerChilds]
 * @param {Array<CustomTableGridOptions>} config.fields - The options for the columns
 * @param {boolean} [config.showToolbar] Show the table toolbar
 * @param {import('@mui/x-data-grid').GridDensity} [config.density='compact'] - The default grid density
 * @param {boolean} [config.loading=undefined] - Is loading the table
 * @param {boolean} [config.hideHeader=false] - Hide the table header
 * @param {Function} [config.handleContextMenu] - Callback to execute on right click
 * @param {string} [config.height='100%'] - The heignt of the table default (100%)
 * @param {import('@mui/material').SxProps} [config.sx] - Extra sx props
 * @param {CustomTableOnPageChange} [config.onPageChange] - Callback triggered when the page is changed
 * @param {boolean} [config.autoHeight=false]
 * @return {ReactJSXElement}
 * */
const CustomTable = ({
  dataSource,
  autoHeight = true,
  fields,
  rowid = 'id',
  checkbox = false,
  selectOnClick = false,
  initialPageSize = 10,
  hideFooterPagination = false,
  loading = undefined,
  hideFooter = false,
  headerChilds = null,
  showToolbar = false,
  hideHeader = false,
  density = 'standard',
  handleContextMenu = null,
  height,
  sx = {},
  onClickEvent = (_, __, ___) => {
    return
  },
  onPageChange = (a) => {},
  customToolbar = null,
}) => {
  /** @type {Array<import('@mui/x-data-grid').GridColDef<MyUserDto>>} */
  const columns = fields.map(
    ({
      value,
      key,
      width,
      sortable,
      flex,
      filterable,
      renderCell,
      type,
      customGetter,
      disableColumnMenu,
    }) => {
      /** @type {import('@mui/x-data-grid').GridColDef} */
      const column = {
        field: value,
        headerName: key,
        sortable: sortable ?? false,
        filterable: filterable ?? false,
        type: type ?? 'string',
        disableColumnMenu: disableColumnMenu ?? false,
      }

      if (width) {
        column.width = width
        column.minWidth = width
      }
      if (flex) column.flex = flex
      if (customGetter) column.valueGetter = customGetter

      if (renderCell)
        column.renderCell = (params) => {
          return renderCell(params.row)
        }

      return column
    },
  )

  const rowSlotProp = handleContextMenu
    ? {
        row: {
          onContextMenu: handleContextMenu,
          style: { cursor: 'context-menu' },
        },
      }
    : {}

  return (
    <div style={{ height }}>
      {headerChilds}
      <DataGrid
        autoHeight={autoHeight}
        loading={loading}
        columnHeaderHeight={hideHeader ? 0 : 56}
        sx={{
          border: 'none',
          '& .MuiDataGrid-toolbarContainer': {
            padding: '5px 0px',
          },
          '& .MuiDataGrid-columnHeaders': {
            path: {
              color: 'text.primary',
              opacity: '.7',
            },
            backgroundColor: 'transparent',
            color: 'text.primary',
            borderBottom: 1,
            padding: '10px',
          },
          '& .MuiDataGrid-main': {
            backgroundColor: 'background.default',
          },
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
            position: 'relative',
            height: 'fit-content',
            transition: '.3s !importan',
          },
          '& .MuiDataGrid-overlayWrapper': {
            minHeight: 'fit-content',
            maxHeight: '300px',
            height: '100px',
            transition: '.3s !importan',

            '& .MuiDataGrid-overlay': {
              transition: '.3s !importan',
              padding: '20px',
              userSelect: 'none',
              fontSize: '1.5em',
              color: 'text.disabled',
            },
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-toolbarContainer': {
            '& .MuiButtonBase-root': {
              color: 'text.primary',
            },
          },
          ...sx,
        }}
        onRowClick={(params, event, details) => {
          onClickEvent(params, event, details)
        }}
        slots={{ toolbar: showToolbar ? (customToolbar ? customToolbar : GridToolbar) : null }}
        slotProps={{
          toolbar: { showQuickFilter: true, quickFilterProps: { debounceMs: 500 } },
          ...rowSlotProp,
        }}
        rows={dataSource}
        columns={columns}
        density={density}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        getRowId={(row) => row[rowid]}
        hideFooter={hideFooter}
        initialState={{
          filter: {
            filterModel: {
              items: [],
            },
          },
          pagination: {
            paginationModel: { page: 0, pageSize: initialPageSize },
          },
        }}
        hideFooterPagination={hideFooterPagination}
        pageSizeOptions={[
          Math.ceil(initialPageSize / 2),
          initialPageSize,
          Math.ceil(initialPageSize * 1.5),
          Math.ceil(initialPageSize * 2),
        ]}
        checkboxSelection={checkbox}
        disableRowSelectionOnClick={!selectOnClick}
        onPaginationModelChange={onPageChange}
      />
    </div>
  )
}

export default CustomTable
