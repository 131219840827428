import { Search } from '@mui/icons-material'
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material'
import { Box } from '@mui/system'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { esES } from '@mui/x-date-pickers/locales'
import ScrollDetector from 'common/components/ScrollDetector'
import ScrollHelper from 'common/libs/ScrollHelper'
import useFetchComunicados from 'features/comunicados/hooks/useFetchComunicados'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'
import ComunicadoCard from '../components/ComunicadoCard'
import useFetchNewsMeta from '../hooks/useFetchNewsMeta'

const Comunicados = ({ compact = false }) => {
  const params = useParams()
  const [id, setId] = useState(params?.id || null)
  const { categories, tags } = useFetchNewsMeta()
  const [categoria, setCategoria] = useState('')
  const [dateFrom, setDateFrom] = useState()
  const [dateTo, setDateTo] = useState()
  const [etiquetas, setEtiquetas] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [lastPage, setLastPage] = useState(0)
  const [page, setPage] = useState(1)
  const { response, loading } = useFetchComunicados(
    page,
    categoria,
    etiquetas,
    searchQuery,
    null,
    dateFrom,
    dateTo,
    id,
  )
  const [documents, setDocuments] = useState([])
  const location = useLocation()
  const theme = new ThemeManager()
  const GRID_SPACING = theme.options?.documentacionOptions?.spacing ?? 2

  useEffect(() => {
    if (!loading && response?.results && page !== lastPage) {
      if (response.next) setLastPage(lastPage + 1)

      setDocuments(response.previous ? [...documents, ...response.results] : [...response.results])
    }
    if (location?.state?.requested) {
      ScrollHelper.scrollTo({ id: location?.state?.requested, duration: 600 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <Box sx={{ mt: { xs: 4, md: 'initial' } }}>
      <Box sx={{ mb: compact ? 0.1 : GRID_SPACING }}>
        <FormControl sx={{ mb: compact ? 0.1 : GRID_SPACING, width: '100%' }} variant="outlined">
          <OutlinedInput
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setPage(1)
                setSearchQuery(e.target.value)
              }
            }}
            sx={{
              width: '100%',
              // px: 1,
              // '& .MuiInputBase-input': {
              //   p: '5px 10px',
              // },
            }}
            placeholder="Introduce las palabras clave que quieres buscar entre los comunicados"
            id="input-with-icon-adornment"
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
          />
        </FormControl>

        <Grid container spacing={compact ? 0.1 : GRID_SPACING}>
          <Grid key="categoria" item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="select-categoria-label">Categoría</InputLabel>
              <Select
                value={categoria}
                onChange={(e) => {
                  console.log({ EVENT: e })
                  setDocuments([])
                  setPage(1)
                  setCategoria(e.target.value)
                }}
                labelId="select-categoria-label"
                id="select-categoria"
                label="Categoría"
                fullWidth
              >
                <MenuItem value="">
                  <em>Sin selección</em>
                </MenuItem>
                {categories?.map((c, idx) => (
                  <MenuItem key={idx} value={c}>
                    {c.descripcion}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid key="etiqueta" item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="select-etiqueta-label">Etiqueta</InputLabel>
              <Select
                labelId="select-etiqueta-label"
                id="select-etiqueta"
                value={etiquetas}
                onChange={(e) => {
                  setDocuments([])
                  setPage(1)
                  setEtiquetas([e.target.value])
                }}
                label="Etiqueta"
                fullWidth
              >
                <MenuItem value="">
                  <em>Sin selección</em>
                </MenuItem>
                {tags?.map((t, idx) => (
                  <MenuItem value={t} key={idx}>
                    {t.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <LocalizationProvider
          localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
          dateAdapter={AdapterDayjs}
          adapterLocale="es"
        >
          <Grid container spacing={compact ? 0.1 : GRID_SPACING} sx={{ mt: 0 }}>
            <Grid item key={`g-desde`} xs={12} md={6}>
              <DatePicker
                key={`desde`}
                value={dateFrom}
                onChange={(value) => {
                  setDocuments([])
                  setPage(1)
                  setDateFrom(value)
                }}
                label="Fecha desde"
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item key={`g-hasta`} xs={12} md={6}>
              <DatePicker
                onChange={(value) => {
                  setDocuments([])
                  setPage(1)
                  setDateTo(value)
                }}
                value={dateTo}
                key={`hasta`}
                label="Fecha hasta"
                sx={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {documents ? (
          documents.map((data, idx) => {
            return (
              <div key={idx} id={data.id}>
                <ComunicadoCard data={data} requested={data.id === location?.state?.requested} />
              </div>
            )
          })
        ) : (
          <></>
        )}
      </Box>
      {loading ? <LinearProgress sx={{ mt: 1 }} /> : <></>}
      <ScrollDetector
        show={!loading && response?.next && page === lastPage}
        onElementIsVisible={() => {
          if (response?.next && response?.next !== null) setPage(page + 1)
        }}
      />
    </Box>
  )
}

export default Comunicados
