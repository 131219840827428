import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid2'
import IconButton from '@mui/material/IconButton'
import axios from 'api/axios'
import DateComponent from 'features/solicitudes/components/fields/DateComponent'
import DateRangeComponent from 'features/solicitudes/components/fields/DateRangeComponent'
import FileComponent from 'features/solicitudes/components/fields/FileComponent'
import ListComponent from 'features/solicitudes/components/fields/ListComponent'
import ListOfValuesComponent from 'features/solicitudes/components/fields/ListOfValuesComponent'
import RadioComponent from 'features/solicitudes/components/fields/RadioComponent'
import TextComponent from 'features/solicitudes/components/fields/TextComponent'
import { validate } from 'features/solicitudes/libs/FieldResquestValidator'
import {
  fetchCamposSolicitudes,
  selectCamposSolicitud,
  selectDatosSolicitud,
  selectDescripcionesSolicitud,
  selectEstructuraSolicitud,
  setCampoError,
  setSolicitudSeleccionada,
} from 'features/solicitudes/solicitudesSlice'
import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button, CardHeader, Container } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Calendario from 'features/calendario/pages/Calendario'
import CabeceraSolicitud from 'features/solicitudes/components/CabeceraSolicitud'
import CamposSolicitudSkeleton from '../skeletons/CamposSolicitudSkeleton'
import CustomStepper from './CustomStepper'

function CamposSolicitud({ solicitud }) {
  const dispatch = useDispatch()

  const MAX_NOTIF = 5

  //TODO: Esto se debería de llevar a un parámetro
  const ENVIAR_Y_NUEVO = false

  const estructura = useSelector(selectEstructuraSolicitud)
  const campos = useSelector(selectCamposSolicitud)
  const valores = useSelector(selectDatosSolicitud)
  const descripciones = useSelector(selectDescripcionesSolicitud)

  const [openBackdrop, setOpenBackdrop] = useState(false)

  useEffect(() => {
    dispatch(fetchCamposSolicitudes({ tipo: solicitud.tipo, subtipo: solicitud.subtipo }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigate = useNavigate()

  const handleSave = (nuevo) => {
    setOpenBackdrop(true)
    let encontrados = 0
    let errores = false
    campos.forEach((/**@type {CampoSolicitud} */ campo) => {
      if (campo.tipo_dato !== 'S' && campo.obligatorio === 'S' && !valores[campo.idpk]) {
        encontrados++
        if (encontrados < MAX_NOTIF) {
          enqueueSnackbar(`El campo ${campo.etiqueta} es obligatorio`)
        } else if (encontrados === MAX_NOTIF) {
          enqueueSnackbar('Hay campos obligatorios sin cubrir')
        }
        dispatch(setCampoError({ idpk: campo.idpk, error: true }))
        errores = true
      } else if (campo.id_validacion) {
        try {
          const valor = valores[campo.idpk]
          validate(campo, valor)
          dispatch(setCampoError({ idpk: campo.idpk, error: false }))
        } catch (e) {
          dispatch(setCampoError({ idpk: campo.idpk, error: true }))
          encontrados++
          if (encontrados < MAX_NOTIF) {
            enqueueSnackbar(`El campo ${campo.etiqueta} no es válido`)
          } else if (encontrados === MAX_NOTIF) {
            enqueueSnackbar('Hay campos obligatorios sin cubrir')
          }
          errores = true
        }
      }
    })
    if (!errores) {
      const registros = []
      for (const idpk in valores) {
        if (Object.hasOwnProperty.call(valores, idpk)) {
          const campo = campos.filter((c) => c.idpk === Number(idpk))[0]
          registros.push({
            codigo_plantilla: campo.codigo_plantilla,
            codigo_campo: campo.codigo_campo,
            orden: campo.orden,
            valor: valores[idpk],
            d_valor: descripciones[idpk],
          })
        }
      }
      const datos = {
        tipo: solicitud.tipo,
        subtipo: solicitud.subtipo,
        registros: registros,
      }
      axios
        .post('/solicitudes/', datos)
        .then((response) => {
          enqueueSnackbar('Solicitud creada con éxito')
          if (nuevo) {
            navigate('/solicitud/')
          }
          dispatch(setSolicitudSeleccionada(response.data))
          navigate('/solicitud/', { state: { solicitud: response.data } })
        })
        .catch((e) => enqueueSnackbar('Se ha producido un error al guardar la solicitud'))
        .finally(() => setOpenBackdrop(false))
    } else {
      setOpenBackdrop(false)
    }
  }

  /*For edisa*/
  const [activeStep, setActiveStep] = useState(0)
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return solicitud && estructura && estructura.length > 0 ? (
    <Box component="form" autoComplete="off">
      <SnackbarProvider
        maxSnack={MAX_NOTIF + 1}
        autoHideDuration={6000}
        action={(snackbarId) => (
          <IconButton onClick={() => closeSnackbar(snackbarId)} aria-label="cerrar alerta">
            <CloseRoundedIcon />
          </IconButton>
        )}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        variant={'error'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CabeceraSolicitud
        descripcion={solicitud.descripcion}
        ayuda={solicitud.ayuda}
        solicitud={solicitud}
      />
      {solicitud.id_solicitud_pe === 'VAC01' ? <Calendario vistaAmpliada={false} /> : null}
      <CustomStepper activeStep={activeStep} estructura={estructura} sx={{ m: 6 }} />

      <Container maxWidth="xl">
        <Card
          sx={{
            width: '100%',
          }}
        >
          {' '}
          <CardHeader
            title={campos.filter((c) => c.idpk === estructura[activeStep]?.idpk)[0]?.etiqueta ?? ''}
            sx={{ p: 5 }}
          />
          <CardContent sx={{ p: 5 }}>
            <Grid key="grid1" container spacing={2}>
              {estructura[activeStep].campos.map((c) => {
                const numFields = estructura[activeStep].campos.length
                return (
                  <Grid
                    item
                    key={`g-${c.idpk}`}
                    size={{
                      xs: 12,
                      sm: numFields > 4 ? 4 : 12,
                      md: numFields > 1 && numFields <= 4 ? 6 : numFields > 4 ? 3 : 12,
                    }}
                    sx={{ display: c.visible !== 'S' ? 'none' : 'inherit' }}
                  >
                    {c.tipo_dato === 'V' ? (
                      c.lv_codigo !== '' && c.lv_tipo === 'LV' ? (
                        <ListOfValuesComponent idpk={c.idpk} />
                      ) : c.lv_codigo !== '' && c.lv_tipo === 'LI' ? (
                        <ListComponent idpk={c.idpk} />
                      ) : c.lv_codigo !== '' && c.lv_tipo === 'RI' ? (
                        <RadioComponent idpk={c.idpk} />
                      ) : (
                        <TextComponent idpk={c.idpk} />
                      )
                    ) : c.tipo_dato === 'N' ? (
                      <TextComponent idpk={c.idpk} />
                    ) : c.tipo_dato === 'D' ? (
                      <DateComponent idpk={c.idpk} />
                    ) : c.tipo_dato === 'DH1' ? (
                      <DateRangeComponent idpk={c.idpk} />
                    ) : c.tipo_dato === 'B' ? (
                      <FileComponent idpk={c.idpk} />
                    ) : (
                      <></>
                    )}
                  </Grid>
                )
              })}
            </Grid>
          </CardContent>
        </Card>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            pt: 2,
            width: '100%',
            margin: 'auto',
            marginBottom: '25px',
          }}
        >
          {activeStep > 0 ? (
            <Button color="inherit" variant="contained" size="large" onClick={handleBack}>
              Anterior
            </Button>
          ) : (
            <Box></Box>
          )}
          {activeStep < estructura.length - 1 ? (
            <Button onClick={handleNext} variant="contained" color="success" size="large">
              Siguiente
            </Button>
          ) : (
            <>
              <Button
                onClick={() => {
                  handleSave(false)
                }}
                variant="contained"
                color="error"
                size="large"
              >
                Enviar Solicitud
              </Button>
              {ENVIAR_Y_NUEVO ? (
                <Button
                  onClick={() => {
                    handleSave(true)
                  }}
                  color="warning"
                  variant="contained"
                  size="large"
                >
                  Enviar y Nuevo
                </Button>
              ) : (
                <></>
              )}
            </>
          )}
        </Box>
      </Container>
    </Box>
  ) : (
    <CamposSolicitudSkeleton />
  )
}

export default CamposSolicitud
