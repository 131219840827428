import SearchIcon from '@mui/icons-material/Search'
import { Button, Divider, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import useFetchComunicados from 'features/comunicados/hooks/useFetchComunicados'
import useFetchDocumentacion from 'features/documentos/hooks/useFetchDocumentacion'
import useFetchLaboral from 'features/documentos/hooks/useFetchLaboral'
import useFetchNews from 'features/noticias/hooks/useFetchNews'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager.js'
import BuscadorComunicados from '../components/BuscadorComunicados'
import BuscadorDocLaboral from '../components/BuscadorDocLaboral'
import BuscadorDocumentacion from '../components/BuscadorDocumentacion'
import BuscadorNoticias from '../components/BuscadorNoticias'

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const Buscador = ({ busqueda }) => {
  const Theme = new ThemeManager()
  const location = useLocation()
  const [value, setValue] = useState(0)
  const [search, setSearch] = useState(location?.state?.busqueda ?? busqueda ?? '')
  const [valor, setValor] = useState(search ?? '')

  const [pageNoticias, setPageNoticias] = useState(1)
  const {
    response: res_noticias,
    loading: loading_noticias,
    error: error_noticias,
  } = useFetchNews(pageNoticias, null, null, 999, search)

  const [pageComunicados, setPageComunicados] = useState(1)
  const {
    response: res_comunicados,
    loading: loading_comunicados,
    error: error_comunicados,
  } = useFetchComunicados(pageComunicados, '', '', search, 999)

  const [pageDocumentacion, setPageDocumentacion] = useState(1)
  const {
    response: res_documentacion,
    loading: loading_documentacion,
    error: error_documentacion,
  } = useFetchDocumentacion(pageDocumentacion, null, 999, search)

  const {
    response: res_laboral,
    loading: loading_laboral,
    error: error_laboral,
  } = useFetchLaboral(
    {
      user: null,
      ejercicio: search,
      tipo_documento: null,
      iteration: null,
      only_unread: false,
      unforce_year: true,
      page_size: 999,
      paginate: false,
    },
    search,
  )

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const keyPressed = (e) => {
    if (e.keyCode === 13 && valor.trim()) {
      setSearch(valor)
    }
  }

  const handleClickBuscar = (e) => {
    if (valor.trim()) setSearch(valor)
  }

  const loading_todo =
    loading_noticias || loading_comunicados || loading_documentacion || loading_laboral
  const res_todo =
    (res_noticias ? res_noticias?.count : 0) +
    (res_comunicados ? res_comunicados?.count : 0) +
    (res_documentacion ? res_documentacion?.count : 0) +
    (res_laboral ? res_laboral.length : 0)
  const NUM_RESULTADOS_RESUMEN = 3

  return (
    <>
      <Typography variant="h5">Resultados</Typography>
      <FormControl sx={{ my: 2, width: '100%' }} variant="standard">
        <InputLabel htmlFor="input-buscador">Buscar</InputLabel>
        <Input
          id="input-buscador"
          value={valor}
          onChange={(e) => setValor(e.target.value)}
          onKeyDown={keyPressed}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="botón buscar" onClick={handleClickBuscar}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Divider sx={{ mt: 1, mb: 4 }} />
      <Box sx={{ bgcolor: 'background.paper' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scroll tabs de búsqueda"
        >
          {Theme.hide?.Buscador?.Todo?.Hide ? (
            <></>
          ) : (
            <Tab
              label="Resultados"
              icon={
                loading_todo ? (
                  <CircularProgress size={15} />
                ) : (
                  <Chip label={res_todo} size="small" />
                )
              }
              iconPosition="end"
              {...a11yProps(0)}
            />
          )}
          {Theme.hide?.Buscador?.Noticias?.Hide ? (
            <></>
          ) : (
            <Tab
              label="Noticias"
              icon={
                loading_noticias ? (
                  <CircularProgress size={15} />
                ) : (
                  <Chip label={res_noticias?.count} size="small" />
                )
              }
              iconPosition="end"
              {...a11yProps(1)}
            />
          )}
          {Theme.hide?.Buscador?.Comunicados?.Hide ? (
            <></>
          ) : (
            <Tab
              label="Comunicados"
              icon={
                loading_comunicados ? (
                  <CircularProgress size={15} />
                ) : (
                  <Chip label={res_comunicados?.count} size="small" />
                )
              }
              iconPosition="end"
              {...a11yProps(2)}
            />
          )}
          {Theme.hide?.Buscador?.Documentacion?.Hide ? (
            <></>
          ) : (
            <Tab
              label="Documentación"
              icon={
                loading_documentacion ? (
                  <CircularProgress size={15} />
                ) : (
                  <Chip label={res_documentacion?.count} size="small" />
                )
              }
              iconPosition="end"
              {...a11yProps(3)}
            />
          )}
          {Theme.hide?.Buscador?.Laboral?.Hide ? (
            <></>
          ) : (
            <Tab
              label="Laboral"
              icon={
                loading_laboral ? (
                  <CircularProgress size={15} />
                ) : (
                  <Chip label={res_laboral?.length} size="small" />
                )
              }
              iconPosition="end"
              {...a11yProps(4)}
            />
          )}
        </Tabs>
      </Box>
      {search || res_todo ? (
        <>
          {Theme.hide?.Buscador?.Noticias?.Hide ? (
            <></>
          ) : (
            <CustomTabPanel value={value} index={0}>
              <Divider textAlign="left" sx={{ mt: 4, mb: 2 }}>
                <Typography variant="h6">Noticias</Typography>
              </Divider>
              <BuscadorNoticias
                cargando={loading_noticias}
                resultados={res_noticias?.results?.slice(0, NUM_RESULTADOS_RESUMEN)}
              />
              <Button onClick={() => setValue(1)} variant="text" sx={{ mt: 1 }}>
                Ver más
              </Button>
              <Divider textAlign="left" sx={{ mt: 4, mb: 2 }}>
                <Typography variant="h6">Comunicados</Typography>
              </Divider>
              <BuscadorComunicados
                cargando={loading_comunicados}
                resultados={res_comunicados?.results?.slice(0, NUM_RESULTADOS_RESUMEN)}
              />
              <Divider textAlign="left" sx={{ mt: 4, mb: 2 }}>
                <Typography variant="h6">Documentación</Typography>
              </Divider>
              <BuscadorDocumentacion
                cargando={loading_documentacion}
                resultados={res_documentacion?.results?.slice(0, NUM_RESULTADOS_RESUMEN)}
              />
              <Divider textAlign="left" sx={{ mt: 4, mb: 2 }}>
                <Typography variant="h6">Laboral</Typography>
              </Divider>
              <BuscadorDocLaboral
                cargando={loading_laboral}
                resultados={res_laboral?.slice(0, NUM_RESULTADOS_RESUMEN) ?? []}
              />
            </CustomTabPanel>
          )}
          {Theme.hide?.Buscador?.Noticias?.Hide ? (
            <></>
          ) : (
            <CustomTabPanel value={value} index={1}>
              <BuscadorNoticias cargando={loading_noticias} resultados={res_noticias?.results} />
            </CustomTabPanel>
          )}
          {Theme.hide?.Buscador?.Comunicados?.Hide ? (
            <></>
          ) : (
            <CustomTabPanel value={value} index={2}>
              <BuscadorComunicados
                cargando={loading_comunicados}
                resultados={res_comunicados?.results}
              />
            </CustomTabPanel>
          )}
          {Theme.hide?.Buscador?.Documentacion?.Hide ? (
            <></>
          ) : (
            <CustomTabPanel value={value} index={3}>
              <BuscadorDocumentacion
                cargando={loading_documentacion}
                resultados={res_documentacion?.results}
              />
            </CustomTabPanel>
          )}
          {Theme.hide?.Buscador?.Laboral?.Hide ? (
            <></>
          ) : (
            <CustomTabPanel value={value} index={4}>
              <BuscadorDocLaboral cargando={loading_laboral} resultados={res_laboral ?? []} />
            </CustomTabPanel>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default Buscador
