import { Box } from '@mui/material'
import { Suspense, useMemo } from 'react'
import ThemeManager from 'theming/ThemeManager'
import fonticons from './fonticon.js'
import muiicons from './muiicons.js'
import svgicons from './svgicon.js'

/**
 * @param {CustomIconProps} props
 */
export const Icon = ({
  icon,
  className,
  rotate,
  color,
  size = '24px',
  sx = {},
  onClick = null,
}) => {
  const theme = new ThemeManager()

  if (theme?.activeTheme?.icons) {
    Object.keys(theme.activeTheme.icons).forEach((key) => {
      if (theme.activeTheme.icons[key]) fonticons[key] = theme.activeTheme.icons[key]
    })
  }

  const fontIcon = useMemo(() => fonticons[icon], [icon])

  const SvgIcon = useMemo(() => svgicons[icon], [icon])

  const MuiIcon = useMemo(() => muiicons[icon], [icon])

  const colorFormatted = useMemo(() => {
    if (color?.split('.').length <= 1) return color
    const newColor =
      color?.split('.').reduce((acc, part) => {
        const res = acc?.[part]
        return res
      }, theme.activePallete()) ?? color
    return newColor
  }, [color])

  if (!fontIcon && !SvgIcon && !MuiIcon) return null

  if (icon === 'ChevronLeft' || icon === 'Settings') {
    console.log(fonticons)
    console.log(fontIcon)
    console.log(muiicons)
    console.log(MuiIcon)
  }

  return (
    <Box
      className={`library-icon ${className}`}
      aria-label={icon}
      onClick={() => (onClick ? onClick() : '')}
      role="img"
      sx={{
        display: 'flex',
        cursor: onClick ? 'pointer' : 'inital',
        justifyContent: 'center',
        alignItems: 'center',
        transform: rotate ? `rotate(${rotate}deg)` : undefined,
        color: color,
        width: size,
        height: 'auto',
        fontSize: size,
        aspectRatio: 1,
        '& path': {
          fill: colorFormatted ?? color,
        },
        ...sx,
      }}
    >
      {fontIcon ? (
        <i className={fontIcon} style={{ display: 'grid', placeItems: 'center' }}></i>
      ) : SvgIcon ? (
        <Suspense fallback={null}>
          <SvgIcon />
        </Suspense>
      ) : (
        <MuiIcon />
      )}
    </Box>
  )
}

export default Icon
