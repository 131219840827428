import axios from 'api/axios'
import FilesHelper from 'common/libs/Files'
export const postNewHojaGasto = async ({
  ejercicio,
  periodo,
  tipoHoja,
  subempresa,
  tipo,
  fechaInicio,
  fechaFin,
}) => {
  try {
    let body = {
      ejercicio: ejercicio,
      periodo: periodo,
      tipo: tipoHoja,
      subempresa: subempresa,
      tipo_grupo: tipo,
    }
    if (tipoHoja === 'P') {
      body.fecha_inicio = new Date(fechaInicio).toISOString().split('T')[0]
      body.fecha_fin = new Date(fechaFin).toISOString().split('T')[0]
    }
    const response = await axios.post('/hojasgasto/', body)
    if (response.status !== 201) throw new Error('No se pudo crear la hoja de gasto')
    return response.data
  } catch (error) {
    throw error?.message?.detail ?? 'No se ha podido insertar la hoja de gasto'
  }
}

export const closeHojaGasto = async ({ id }) => {
  try {
    const response = await axios.post(`/hojasgasto/${id}/cerrarHoja`)
    return response
  } catch (e) {
    throw new Error(e.message)
  }
}
export const openHojaGasto = async ({ id }) => {
  try {
    const response = await axios.post(`/hojasgasto/${id}/abrir`)
    return response
  } catch (e) {
    throw new Error(e.message)
  }
}

export const deleteHojaGasto = async ({ id }) => {
  try {
    const body = { id: id }
    const res = await axios.delete('/hojasgasto/', { data: body })
    return res
  } catch (e) {
    throw new Error(e.message)
  }
}

export const getDetalladaHoja = async ({ id }) => {
  try {
    const res = await axios.get(`/hojasgasto/${id}/detallada`)
    FilesHelper.downloadBase64String(window.btoa(res.data.text), res.data.name)
  } catch (e) {
    throw new Error(e.message)
  }
}
export const getFirmasHoja = async ({ id }) => {
  try {
    const res = await axios.get(`/hojasgasto/${id}/firmas`)
    FilesHelper.downloadBase64String(window.btoa(res.data.text), res.data.name)
  } catch (e) {
    throw new Error(e.message)
  }
}
