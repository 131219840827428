import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import InfoIcon from '@mui/icons-material/Info'
import { CardActionArea, CardActions, Dialog, DialogTitle } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid2'
import IconButton from '@mui/material/IconButton'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager.js'
import {
  fetchListaSolicitudes,
  resetSolicitud,
  selectListaSolicitudesDisponibles,
  selectListaSolicitudesStatus,
  setSolicitudSeleccionada,
} from '../solicitudesSlice'

const ListaSolicitudes = ({ tipo = null, subtipo = null }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [openBackdrop, setOpenBackdrop] = useState(true)
  const [infoOpen, setInfoOpen] = useState(false)
  const [requestInformation, setRequestInformation] = useState('')

  const solicitudes = useSelector(selectListaSolicitudesDisponibles)
  const loading = useSelector(selectListaSolicitudesStatus)

  const Theme = new ThemeManager()

  useEffect(() => {
    dispatch(fetchListaSolicitudes())
    dispatch(setSolicitudSeleccionada({}))
  }, [dispatch])

  useEffect(() => {
    if (tipo && subtipo && solicitudes) {
      try {
        const solicitud = solicitudes.filter((s) => s.tipo === tipo && s.subtipo === subtipo)[0]
        if (solicitud) {
          dispatch(resetSolicitud())
          navigate('/solicitud', { state: { solicitud } })
        } else {
          setOpenBackdrop(false)
          enqueueSnackbar('No se ha podido abrir la solicitud')
        }
      } catch (error) {
        console.error(error)
        setOpenBackdrop(false)
        enqueueSnackbar('No se ha podido abrir la solicitud')
      }
    }
  }, [tipo, subtipo, solicitudes, dispatch, navigate])

  const handleClick = (e) => {
    const solicitud = solicitudes.filter(
      (s, i) => Number(i) === Number(e.currentTarget.dataset.solicitud),
    )[0]
    dispatch(resetSolicitud())
    navigate('/solicitud', { state: { solicitud } })
  }

  const handleOpenInfo = (e) => {
    const requestIndex = e.currentTarget.dataset.solicitud
    const selectedRequest = solicitudes[requestIndex]
    setRequestInformation(selectedRequest.ayuda)
    setInfoOpen(true)
  }

  const handleCloseInfo = () => {
    setRequestInformation('')
    setInfoOpen(false)
  }

  return (
    <Box key="solicitudes" sx={{ flexGrow: 1 }}>
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={6000}
        action={(snackbarId) => (
          <IconButton onClick={() => closeSnackbar(snackbarId)} aria-label="cerrar alerta">
            <CloseRoundedIcon />
          </IconButton>
        )}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        variant={'error'}
        preventDuplicate={true}
      />
      {!loading ? (
        Theme?.options?.ListaSolicitudes?.variant === 'stack' ? (
          <Stack className="solicitudes" spacing={2}>
            {solicitudes.map((solicitud, id) => {
              return (
                <Card key={id}>
                  <CardActionArea data-solicitud={id} onClick={handleClick}>
                    <CardContent>
                      <Typography variant="h5" component="div">
                        {solicitud.descripcion}
                      </Typography>
                      {solicitud.ayuda ? (
                        <Typography variant="body2" color="text.secondary">
                          <div dangerouslySetInnerHTML={{ __html: solicitud.ayuda }}></div>
                        </Typography>
                      ) : null}
                    </CardContent>
                  </CardActionArea>
                </Card>
              )
            })}
          </Stack>
        ) : (
          <Grid container spacing={2}>
            <Dialog onClose={handleCloseInfo} open={infoOpen}>
              <DialogTitle>Ayuda</DialogTitle>
              <Box sx={{ px: 3, pb: 3 }}>
                <Typography variant="body2" color="text.secondary">
                  <div dangerouslySetInnerHTML={{ __html: requestInformation }}></div>
                </Typography>
              </Box>
            </Dialog>
            {solicitudes.map((solicitud, id) => {
              return (
                <Grid key={id} item size={{ xs: 12, sm: 6, md: 3 }}>
                  <Card sx={{ height: '100%' }}>
                    <CardActionArea data-solicitud={id} onClick={handleClick}>
                      <CardContent>
                        <Box>
                          <Typography variant="h6" sx={{ fontWeight: 'normal' }}>
                            {solicitud.descripcion}
                          </Typography>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                    <CardActions sx={{ justifyContent: 'flex-end' }}>
                      {solicitud.ayuda ? (
                        <IconButton aria-label="information">
                          <InfoIcon color="warning" data-solicitud={id} onClick={handleOpenInfo} />
                        </IconButton>
                      ) : (
                        <></>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
        )
      ) : tipo && subtipo ? (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            flexDirection: 'column',
            gap: '20px',
          }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
          <Typography>Cargando solicitud</Typography>
        </Backdrop>
      ) : Theme?.options?.ListaSolicitudes?.variant === 'stack' ? (
        <Grid container spacing={2}>
          {[...Array(12).keys()].map((i) => {
            return (
              <Grid key={i} size={{ xs: 12, sm: 12, md: 12 }}>
                <Card>
                  <CardContent>
                    <Skeleton key="s-titulo" animation="wave" height={30} width="100%" />
                    <Skeleton key="s-descri" animation="wave" height={10} width="100%" />
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {[...Array(12).keys()].map((id, index) => {
            return (
              <Grid key={index} item size={{ xs: 12, sm: 6, md: 3 }}>
                <Card key={id}>
                  <CardActionArea data-solicitud={id} onClick={handleClick}>
                    <CardContent>
                      <Box>
                        <Skeleton
                          variant="rounded"
                          animation="wave"
                          height={24}
                          sx={{ width: `calc(100% * ${Math.random(1)})` }}
                        />
                      </Box>
                    </CardContent>
                  </CardActionArea>
                  <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Skeleton variant="circular" height={24} width={24} />
                  </CardActions>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      )}
    </Box>
  )
}

export default ListaSolicitudes
