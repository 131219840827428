import { AccountCircleOutlined } from '@mui/icons-material'
import {
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Typography,
} from '@mui/material'
import logo from 'assets/images/logo.svg'
import { selectPortal } from 'features/portal/portalSlice'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import PasswordController from '../lib/PasswordController'

const RecoveryPasswordForm = () => {
  const [loading, setLoading] = useState(false)
  const [captcha, setCaptcha] = useState(false)
  const [username, setUsername] = useState('')
  const userRef = useRef()
  const [responseStatus, setResponseStatus] = useState(null)
  const portal = useSelector(selectPortal)

  const onReCaptchaChange = (value) => {
    setCaptcha(true)
  }

  useEffect(() => {
    userRef.current.focus()
  }, [])
  return (
    <div className="recover-password-form">
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={6000}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        variant={'error'}
      />
      <Paper sx={{ backgroundColor: 'background.paper', p: 3 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={logo} alt="Logo" style={{ maxHeight: '40px' }} />
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h6" sx={{ m: 2, width: '100%' }}>
            REESTABLECER CONTRASEÑA
          </Typography>
        </div>
        <div>
          <FormControl
            sx={{ minWidth: '25ch', width: '100%', minWidth: { xs: 'initial', md: '600px' } }}
            variant="standard"
          >
            <Input
              error={responseStatus === 404}
              id="standard-adornment-password"
              type="text"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="icono usuario">
                    <AccountCircleOutlined
                      sx={{ opacity: !username ? 0.7 : 1, transition: '.3s' }}
                      color={responseStatus === 404 ? 'error' : 'primary.text'}
                    />
                  </IconButton>
                </InputAdornment>
              }
              autoComplete="false"
              inputProps={{
                placeholder: 'Usuario',
              }}
              ref={userRef}
              value={username}
              onChange={(e) => {
                if (responseStatus === 404) setResponseStatus(null)
                setUsername(e.target.value)
              }}
            />
            {portal?.portal?.parametros_seo?.recaptcha_key ? (
              <ReCAPTCHA
                style={{ marginTop: '8px' }}
                sitekey={portal.portal.parametros_seo.recaptcha_key}
                onChange={onReCaptchaChange}
              />
            ) : (
              <></>
            )}
          </FormControl>
        </div>
        <div>
          <Button
            onClick={async () => {
              try {
                if (portal?.portal?.parametros_seo?.recaptcha_key && !captcha) {
                  enqueueSnackbar('Tienes que aceptar no ser un robot. Gracias', {
                    variant: 'error',
                  })
                  return false
                }
                setLoading(true)
                const response = await PasswordController.requestPasswordReset(username)
                enqueueSnackbar(response?.data?.mensaje ?? response?.data, {
                  variant: response?.data?.resultado === 'ERROR' ? 'error' : 'success',
                  autoHideDuration: 10000,
                })
              } catch (error) {
                setResponseStatus(error.response.status)
                enqueueSnackbar(error.message, { variant: 'error' })
              } finally {
                setLoading(false)
              }
            }}
            disabled={!username || loading}
            variant="contained"
            sx={{ width: '100%', mt: 2 }}
          >
            Enviar
          </Button>
        </div>
        <div>
          <Button sx={{ width: '100%', mt: 2 }} LinkComponent={Link} to="/login">
            Regresar
          </Button>
        </div>
      </Paper>
    </div>
  )
}

export default RecoveryPasswordForm
