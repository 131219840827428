import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import SpeedDial from '@mui/material/SpeedDial';
import { DatePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetchOptionsHojasGasto from '../hooks/useFetchOptionsHojasGasto';
import { postNewHojaGasto } from '../lib/HojasGastoController';

export const HojasGastoAddModal = () => {
  const navigate = useNavigate()

  const [openAddModal, setOpenAddModal] = useState(false)

  const handleClose = () => {
    setOpenAddModal(!openAddModal)
    navigate('/hojasgasto')
  }

  const [ejercicio, setEjercicio] = useState(new Date().getFullYear())
  const [periodo, setPeriodo] = useState(new Date().getMonth() + 1)
  const [tipoHoja, setTipoHoja] = useState('M')
  const [fechaInicio, setFechaInicio] = useState({})
  const [fechaFin, setFechaFin] = useState({})
  const [subempresa, setSubempresa] = useState('')
  const [tipo, setTipo] = useState('')
  const [ejercicioError, setEjercicioError] = useState('')
  const [periodoError, setPeriodoError] = useState('')
  const [fechaInicioError, setFechaInicioError] = useState(false)
  const [fechaFinError, setFechaFinError] = useState(false)
  const [subempresaError, setSubempresaError] = useState(false)
  const [tipoError, setTipoError] = useState(false)
  const [postError, setPostError] = useState('')

  const { response, error, loading } = useFetchOptionsHojasGasto({ ejercicio })

  useEffect(() => {
    if (response?.subempresas?.length === 1) {
      setSubempresa(response?.subempresas[0].codigo_subempresa)
    }
  }, [response])

  const handleClick = async () => {
    try {
      let error = false
      if (!subempresa || response.subempresas?.length !== 1) {
        setSubempresaError('Campo obligatorio')
        error = true
      } else {
        setSubempresaError('')
      }
      if (!tipo) {
        setTipoError('Campo obligatorio')
        error = true
      } else {
        setTipoError('')
      }
      if (!ejercicio) {
        setEjercicioError('Campo obligatorio')
        error = true
      }
      if (!response.allow_future_periodo && ejercicio > new Date().getFullYear() && !error) {
        error = true
        setEjercicioError('No tienes permitido introducir un ejercicio superior al actual')
      } else {
        if (!error) setEjercicioError('')
      }

      if (error) return

      const res = await postNewHojaGasto({
        ejercicio: parseInt(ejercicio),
        periodo,
        tipoHoja,
        subempresa,
        tipo: parseInt(tipo),
        fechaInicio,
        fechaFin,
      })
      if (res) {
        navigate(`/hojasgasto/${res.id}`)
      }
    } catch (e) {
      setPostError(e)
    }
  }

  return (
    <>
      <Modal
        open={openAddModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '95%',
            bgcolor: 'background.paper',
            border: '1px',
            borderRadius: '0.5rem',
            boxShadow: 24,
            p: 4,
          }}
        >
          {!loading ? (
            <Box
              sx={{
                gap: 2,
                display: 'flex',
                flexWrap: 'wrap',
                placeItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FormGroup required sx={{ width: '100%' }}>
                <TextField
                  label="Ejercicio"
                  variant="outlined"
                  defaultValue={ejercicio}
                  error={ejercicioError ? true : false}
                  type="number"
                  required
                  onBlur={(e) => setEjercicio(e.target.value)}
                />
              </FormGroup>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel width="100%" required >Tipo Hoja</InputLabel>
                <Select
                  sx={{ width: '100%' }}
                  value={tipoHoja}
                  label="Tipo Hoja"
                  onChange={(e) => setTipoHoja(e.target.value)}
                >
                  <MenuItem value={'M'}>Mensual</MenuItem>
                  <MenuItem value={'P'}>Periodo</MenuItem>
                </Select>
              </FormControl>
              {ejercicioError ? (
                <Typography sx={{ width: '100%', marginLeft: '40px' }} color='error'>
                  {ejercicioError}
                </Typography>
              ) : null}
              <FormControl sx={{ width: '100%' }} error={periodoError ? true : false}>
                <InputLabel required>Periodo</InputLabel>
                <Select
                  value={periodo}
                  label="Periodo"
                  onChange={(e) => setPeriodo(parseInt(e.target.value))}
                >
                  {response?.periodos?.map((month) => (
                    <MenuItem value={parseInt(Object.keys(month)[0])} key={Object.keys(month)[0]}>
                      {month[Object.keys(month)[0]]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {tipoHoja === 'P' ? (
                <>
                  <DatePicker
                    required
                    format="DD/MM/YYYY"
                    label="Fecha Inicio *"
                    value={fechaInicio}
                    onChange={(e) => setFechaInicio(e)}
                    sx={{ width: '100%' }}
                  />
                  <DatePicker
                    required
                    label="Fecha Fin *"
                    format="DD/MM/YYYY"
                    value={fechaFin}
                    onChange={(e) => setFechaFin(e)}
                    sx={{ width: '100%' }}
                  />
                </>
              ) : null}
              <FormControl sx={{ width: '100%', display: response.subempresas?.length !== 1 ? 'initial' : 'none' }} required >
                <InputLabel>Subempresa</InputLabel>
                <Select
                  value={subempresa}
                  label="Subempresa"
                  error={subempresaError ? true : false}
                  onChange={(e) => setSubempresa(e.target.value)}
                  sx={{ width: '100%' }}
                >
                  {response.subempresas?.map((subempresa) => (
                    <MenuItem value={subempresa.codigo_subempresa} key={subempresa.id}>
                      {subempresa.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {subempresaError ? (
                <Typography sx={{ width: '100%', textAlign: 'center' }} color='error'>
                  {subempresaError}
                </Typography>
              ) : null}
              <FormControl sx={{ width: '100%' }} required>
                <InputLabel>Tipos</InputLabel>
                <Select
                  value={tipo}
                  label="Tipos"
                  onChange={(e) => setTipo(e.target.value)}
                  error={tipoError ? true : false}
                >
                  {response.tipos?.map((tipo) => (
                    <MenuItem value={tipo.id} key={tipo.id}>
                      {tipo.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {tipoError ? (
                <Typography sx={{ width: '100%', textAlign: 'center' }} color='error'>
                  {tipoError}
                </Typography>
              ) : null}
              <Button
                variant="contained"
                onClick={handleClick}
                color='success'
                sx={{
                  width: '100%',
                }}
              >
                Guardar
              </Button>
              {postError ? (
                <Typography sx={{ width: '100%' }} color='error'>{postError}</Typography>
              ) : null}
              <Button
                variant="contained"
                onClick={handleClose}
                color='error'
                sx={{
                  width: '100%',
                }}
              >
                Cancelar
              </Button>
            </Box>
          ) : (
            <Box sx={{ mt: 1, width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
        </Box>
      </Modal >
      <Button
        startIcon={<AddCircleIcon />}
        onClick={() => setOpenAddModal(!openAddModal)}
        color='primary'
        variant='contained'
        sx={{ ml: 1, display: { xs: 'none', md: 'flex' } }}
      >
        Nueva Hoja
      </Button>
      <SpeedDial
        ariaLabel="Modal close button"
        sx={{ position: 'fixed', bottom: 5, left: 16, zIndex: 500, display: { xs: 'initial', md: 'none' } }}
        icon={<AddIcon />}
        onClick={() => setOpenAddModal(!openAddModal)}
      />
    </>
  )
}
