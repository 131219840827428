import { Button, TextField, Typography } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { Box } from '@mui/system'
import { selectPortal } from 'features/portal/portalSlice'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import PasswordController from '../lib/PasswordController'
import PasswordRequirements from './PasswordRequirements'

/**
 * A Form to change the user password
 * @param {Object} props The react object props
 * @param {string} [props.username=null] The username to send on the request, only uset to restablish the password
 * @param {'filled' | 'outlined' | 'standard'} [props.variant='outlined']
 * @param {string} [props.buttonWidth}
 * @param {boolean} [props.showRequirements=true]}
 */
const PasswordForm = ({
  token = null,
  buttonWidth = 'initial',
  variant = 'outlined',
  showRequirements = true,
}) => {
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('')
  const [currentPassword, setCurrentPassword] = useState(token ? null : '')
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const portal = useSelector(selectPortal)
  const changePasswordRegex = useCallback(() => {
    return PasswordController.createQuerySet(
      portal?.portal?.longitud_password || 8,
      portal?.portal?.obligar_mayusculas === 'S',
      portal?.portal?.obligar_numero === 'S',
      portal?.portal?.obligar_simbolo === 'S',
    )
  }, [portal])
  const [PASSWORD_REGEX, setPasswordRegex] = useState(changePasswordRegex())

  useEffect(() => {
    setPasswordRegex(changePasswordRegex())
  }, [portal, changePasswordRegex])
  const navigate = useNavigate()

  const changePassword = async () => {
    try {
      setOpenBackdrop(true)
      await PasswordController.changePassword(
        newPassword,
        newPasswordRepeat,
        currentPassword,
        token,
      )
      setOpenBackdrop(false)
      if (token) navigate('/login')
      enqueueSnackbar('Contraseña cambiada correctamente', { variant: 'success' })
    } catch (error) {
      setOpenBackdrop(false)
      console.log({ error })
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  return (
    <>
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={6000}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        variant={'error'}
      />
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: 'column',
          gap: '20px',
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
        <Typography>Cambiando contraseña</Typography>
      </Backdrop>
      <Box
        component={'form'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1em',
        }}
      >
        {showRequirements ? <PasswordRequirements /> : <></>}
        {token ? (
          <></>
        ) : (
          <TextField
            id="curront-password"
            value={currentPassword}
            type="password"
            onChange={(e) => setCurrentPassword(e.target.value)}
            label="Contraseña Actual"
            variant={variant}
          />
        )}
        <TextField
          id="new-password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          label="Nueva Contraseña"
          type="password"
          error={newPassword && !PASSWORD_REGEX.test(newPassword)}
          variant={variant}
        />
        <TextField
          id="new-password-repeat"
          value={newPasswordRepeat}
          onChange={(e) => setNewPasswordRepeat(e.target.value)}
          type="password"
          label="Repetir Nueva Contraseña"
          error={newPasswordRepeat !== newPassword}
          variant={variant}
        />
      </Box>

      <Button
        sx={{ mt: '2em', width: buttonWidth }}
        variant="contained"
        disabled={
          (!token && !currentPassword) ||
          newPassword !== newPasswordRepeat ||
          !newPassword ||
          !PASSWORD_REGEX.test(newPassword)
        }
        onClick={changePassword}
        disableElevation
      >
        Cambiar Contraseña
      </Button>
      {token ? (
        <Button sx={{ width: '100%', mt: 2 }} LinkComponent={Link} to="/login">
          Iniciar Sesión
        </Button>
      ) : (
        <></>
      )}
    </>
  )
}

export default PasswordForm
