import * as React from 'react'
import clsx from 'clsx'
import { styled, useTheme, alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import {
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Root,
  TreeItem2GroupTransition,
} from '@mui/x-tree-view/TreeItem2'
import { useTreeItem2 } from '@mui/x-tree-view/useTreeItem2'
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider'
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon'
import Icon from 'icons/Icons'
import { useTreeViewApiRef } from '@mui/x-tree-view'

const CustomTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  marginBottom: theme.spacing(0.3),
  color: theme.palette.text.secondary,
  borderRadius: theme.spacing(2),
  paddingRight: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMedium,
  '&.expanded': {
    fontWeight: theme.typography.fontWeightRegular,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.focused, &.selected, &.selected.focused': {
    backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
    color: 'var(--tree-view-color)',
  },
}))

const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(({ theme }) => ({
  marginLeft: 0,
  ['& .content']: {
    paddingLeft: theme.spacing(2),
  },
}))

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  const theme = useTheme()
  const {
    id,
    itemId,
    label,
    disabled,
    children,
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    colorForDarkMode,
    bgColorForDarkMode,
    ...other
  } = props

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref })

  const style = {
    '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
    '--tree-view-bg-color': theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
  }

  return (
    <TreeItem2Provider itemId={itemId}>
      <CustomTreeItemRoot sx={{ my: 1 }} {...getRootProps({ ...other, style })}>
        <CustomTreeItemContent
          {...getContentProps({
            className: clsx('content', {
              expanded: status.expanded,
              selected: status.selected,
              focused: status.focused,
            }),
          })}
        >
          <CustomTreeItemIconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </CustomTreeItemIconContainer>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              alignItems: 'center',
              p: 1,
              pr: 0,
            }}
          >
            <Icon
              size="1.3rem"
              icon={status.expanded || status.selected ? 'fi-rr-folder-open' : 'fi-rr-folder'}
              sx={{ mr: 1 }}
            />
            <Typography
              {...getLabelProps({
                variant: 'body2',
                sx: { display: 'flex', fontWeight: 'inherit', flexGrow: 1 },
              })}
            />
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          </Box>
        </CustomTreeItemContent>
        {children && <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />}
      </CustomTreeItemRoot>
    </TreeItem2Provider>
  )
})

function EndIcon() {
  return <div style={{ width: 24 }} />
}
/**
 * Recursive component to render a PersonalTreeDTO node and its children.
 * @param {PersonalTreeDTO} node - The current node to render.
 * @returns {JSX.Element} - The rendered JSX element.
 */
function PersonalDocTreeNode({ node }) {
  return (
    <CustomTreeItem itemId={node.codigo_nivel} label={node.descripcion} labelInfo={node.num_doc}>
      {node?.hijos?.map((child, index) => <PersonalDocTreeNode key={index} node={child} />) ?? ''}
    </CustomTreeItem>
  )
}

/**
 * @param {(nodeLevel: string) => void} onNodeSelected
 * @returns {JSX.Element}
 */
export default function PersonalDocTree({ onNodeSelected, defaultLevel, tree = null }) {
  const apiRef = useTreeViewApiRef()

  return tree?.[0] ? (
    <SimpleTreeView
      aria-label="gmail"
      // defaultExpandedItems={['3']}
      defaultSelectedItems={tree?.[0]?.codigo_nivel}
      apiRef={apiRef}
      onItemClick={(_event, itemId) => {
        onNodeSelected(itemId)
      }}
      slots={{
        expandIcon: ArrowRightIcon,
        collapseIcon: ArrowDropDownIcon,
        endIcon: EndIcon,
      }}
    >
      {tree?.map((data, idx) => {
        return <PersonalDocTreeNode node={data} key={idx} />
      }) ?? ''}
    </SimpleTreeView>
  ) : (
    ''
  )
}
