import { Lock, LockOpen, LockPerson } from '@mui/icons-material'
import { ButtonBase, Card, CardContent, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { getLocaleFromFullDate } from 'common/libs/fechas'
import { useNavigate } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'

const HojasGastosCard = ({ data: hojaGasto }) => {
  const Theme = new ThemeManager()
  const navigate = useNavigate()

  return (
    <ButtonBase sx={{ width: '100%' }}>
      <Card
        sx={{ ':hover': { cursor: 'pointer' }, height: '100%', width: '100%' }}
        onClick={() => navigate(`/hojasgasto/${hojaGasto.id}`)}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography>Id: {hojaGasto.id}</Typography>

              {hojaGasto?.status === '0500' ? (
                <Tooltip title="Abierta">
                  <LockOpen color="success" />
                </Tooltip>
              ) : hojaGasto?.status === '1500' ? (
                <Tooltip title="En validación" color="warning">
                  <LockPerson />
                </Tooltip>
              ) : (
                <Tooltip title="Cerrada">
                  <Lock color="error" />
                </Tooltip>
              )}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography>Ejercicio: {hojaGasto.ejercicio}</Typography>
              <Typography>Periodo: {hojaGasto.d_periodo}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="caption" display="block" gutterBottom>
                  Fecha inicio
                </Typography>
                <Typography variant="body1">
                  {getLocaleFromFullDate(hojaGasto.fecha_inicio, false)}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="caption" display="block" gutterBottom>
                  Fecha fin
                </Typography>
                <Typography variant="body1">
                  {getLocaleFromFullDate(hojaGasto.fecha_fin, false)}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography>Tipo: {hojaGasto.d_tipo}</Typography>
              <Typography>Grupo: {hojaGasto.d_tipo_grupo}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              textAlign: 'center',
              justifyContent: 'center',
              background: Theme.pallete.background.alternative,
              border: 1,
              borderColor: 'transparent',
              borderRadius: 1,
            }}
          >
            <Typography sx={{ color: 'white' }}>Total: {hojaGasto.importe}</Typography>
          </Box>
        </CardContent>
      </Card>
    </ButtonBase>
  )
}

export default HojasGastosCard
