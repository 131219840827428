import { Logout as LogoutIcon, Settings } from '@mui/icons-material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import { Box } from '@mui/system'
import IconsList from 'common/constants/icons_map'
import { selectMenus } from 'features/portal/menusSlice'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ThemeManager from 'theming/ThemeManager'
import UserImage from '../../../components/UserImage'
import DropDownButton from './DropDownButton'
import IconMenuButton from './IconMenuButton'

const MenuDrawerDefault = ({
  expanded,
  DRAWER_WIDTH,
  ALLWAYS_EXPANDED,
  mobileOpen,
  open,
  handleClick,
}) => {
  const menus = useSelector(selectMenus)

  const themeManager = new ThemeManager()

  const apply = themeManager.options.apply ?? false

  //{//**in={open || mobileOpen || ALLWAYS_EXPANDED || (!ALLWAYS_EXPANDED && expanded)}**/}
  return (
    <Box
      sx={{
        height: '95vh',
        height: '100dvh',
        // width: DRAWER_WIDTH,
        backgroundColor: {
          xs: 'menu.background',
          md: 'transparent',
        },
        p: {
          xs: 0,
          md: !ALLWAYS_EXPANDED || mobileOpen ? 0 : apply ? 0 : 1,
        },
        position: 'initial',
      }}
    >
      <UserImage
        expanded={ALLWAYS_EXPANDED || expanded}
        boxSx={{
          transition: '.3s ease-in',
          // width: '100px',
          marginX: 'initial',
          marginBottom: 2,
          display: 'block',
          width: '100px',
          mx: 'auto',
          position: 'unset',
          top: '1.4%',
          zIndex: 2,
        }}
      />
      <Paper
        className="menu"
        sx={{
          // height: '100%',
          transition: '.3s',
          // width: '100%',
          // borderRadius: ALLWAYS_EXPANDED && !apply ? 1 : '',
          borderRadius: 0,
          backgroundColor: 'menu.background',
          color: 'background.menuContrast',
          // position: 'relative',
          display: 'block',
          overflowX: 'hidden',
        }}
      >
        <List
          sx={{
            transition: '.3s',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            '& > *': {
              flexGrow: '0 !important',
              '&:last-child': {
                marginTop: 'auto',
              },
            },
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <div>
            {menus.map((current_menu) => {
              if (current_menu.visible !== 'S') return null
              const Icono = current_menu?.icono ? IconsList[current_menu.icono] : null
              const to =
                current_menu.programa === 'url'
                  ? current_menu.camino
                  : `/${current_menu.camino ? current_menu.camino : current_menu.programa}`
              const target = current_menu.programa === 'url' ? '_blank' : null
              const rel = current_menu.programa === 'url' ? 'noopener' : null

              if (current_menu.programa) {
                return (
                  <>
                    <IconMenuButton
                      current_menu={current_menu}
                      to={to}
                      Icono={Icono}
                      target={target}
                      rel={rel}
                      small={false}
                    />
                  </>
                )
              } else if (current_menu.hijos && current_menu.hijos.length > 0) {
                return (
                  <React.Fragment key={current_menu.id}>
                    <DropDownButton
                      current_menu={current_menu}
                      handleClick={handleClick}
                      Icono={Icono}
                      open={open}
                      small={false}
                    />
                  </React.Fragment>
                )
              }
              return null
            })}
          </div>
          <div>
            <ListItemButton
              sx={{ p: '4px', pl: 2, pr: 1 }}
              id={99999}
              component={Link}
              className="menu-list-item"
              to="/ajustes"
            >
              <ListItemIcon>
                <Settings sx={{ color: 'background.menuContrast' }} />
              </ListItemIcon>
              <ListItemText primary={'Ajustes'} />
            </ListItemButton>
            <ListItemButton
              sx={{ p: '4px', pl: 2, pr: 1 }}
              id={99999}
              className="menu-list-item"
              to="/logout"
            >
              <ListItemIcon>
                <LogoutIcon sx={{ color: 'background.menuContrast' }} />
              </ListItemIcon>
              <ListItemText primary={'Logout'} />
            </ListItemButton>
          </div>
        </List>
      </Paper>
    </Box>
  )
}

export default MenuDrawerDefault
