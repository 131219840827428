import EditIcon from '@mui/icons-material/Edit'
import { Avatar, Box, Skeleton, Typography } from '@mui/material'

import BotonSolicitudInterna from 'features/solicitudes/components/BotonSolicitudInterna'
import { selectUsuario } from 'features/users/stores/userSlice'
import { useSelector } from 'react-redux'

const WorkerData = ({ datos = null, loading = true }) => {
  const user = useSelector(selectUsuario)

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ position: 'relative', ml: 7 }}>
          <Avatar
            src={`/assets/images/${user.imagen}`}
            sx={{
              aspectRatio: '4/4',
              width: '128px',
              height: '128px',
              position: 'absolute',
              top: '-96px',
              border: '3px solid',
              borderColor: 'background.primary80',
            }}
          />
          <BotonSolicitudInterna
            id_solicitud_pe="CIMGT"
            sx={{
              position: 'absolute',
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 95,
              color: 'primary',
            }}
            type="fab"
            fabIcon={<EditIcon />}
            fabSize="small"
          />
        </Box>
        {loading ? (
          <Skeleton variant="rectangular" width={272} height={32} sx={{ ml: 18, mt: 2 }} />
        ) : (
          <Typography
            variant="h5"
            sx={{ ml: 18, mt: 2, textTransform: 'capitalize' }}
          >{`${datos?.primer_apellido?.toLowerCase()} ${datos?.segundo_apellido?.toLowerCase()}, ${datos?.nombre?.toLowerCase()}`}</Typography>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 4, gap: 3 }}>
        <Box
          sx={{
            backgroundColor: 'background.default',
            borderRadius: '10px',
            p: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
            flex: '1 1 0',
          }}
        >
          <Typography sx={{ color: 'text.disabled', fontSize: '1rem !important' }}>NIF</Typography>
          {loading ? (
            <Skeleton variant="rectangular" width={90} height={24} />
          ) : (
            <Typography
              sx={{ color: 'text.primary', fontWeight: 'bold', fontSize: '1rem !important' }}
            >
              {datos?.dni}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            backgroundColor: 'background.default',
            borderRadius: '10px',
            p: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
            flex: '1 1 0',
          }}
        >
          <Typography sx={{ color: 'text.disabled', fontSize: '1rem !important' }}>
            Código trabajador
          </Typography>
          {loading ? (
            <Skeleton variant="rectangular" width={90} height={24} />
          ) : (
            <Typography
              sx={{ color: 'text.primary', fontWeight: 'bold', fontSize: '1rem !important' }}
            >
              {datos?.codigo_trabajador}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            backgroundColor: 'background.default',
            borderRadius: '10px',
            p: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
            flex: '1 1 0',
          }}
        >
          <Typography sx={{ color: 'text.disabled', fontSize: '1rem !important' }}>
            Número SS
          </Typography>
          {loading ? (
            <Skeleton variant="rectangular" width={90} height={24} />
          ) : (
            <Typography
              sx={{ color: 'text.primary', fontWeight: 'bold', fontSize: '1rem !important' }}
            >
              {datos?.numero_ss ?? '--'}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default WorkerData
